import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import ICure from '@models/cure.history.model';
import { IsConnectedService } from '@services/is-connected.service';
import AnimationsMethod from '@animations';

@Component({
  selector: 'app-details-cure',
  templateUrl: './details-cure.component.html',
  styleUrls: ['./details-cure.component.scss'],
})
export class DetailsCureComponent implements OnInit {
  public animationsMain: any = AnimationsMethod;
  constructor(
    @Inject(MAT_DIALOG_DATA) public valuesInject: ICure,
    public dialogRef: MatDialogRef<DetailsCureComponent>,
    public isConnectedService: IsConnectedService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.animationsMain();
    }, 50);
  }
}
