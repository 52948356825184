<google-map width="100%" [zoom]="zoom" [center]="center" [options]="options">
  <map-marker [position]="optionsMaker.position" [label]="optionsMaker.label" [title]="optionsMaker.title"
    [options]="optionsMaker.options">
  </map-marker>

</google-map>


<div id="contact" class="pb-90">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 mb-30"> <span class="heading-meta">L'institut Zalona</span>
        <h2 class="pwe-heading animate-box" data-animate-effect="fadeInLeft">Contactez-nous</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-30 animate-box" data-animate-effect="fadeInLeft">
        <p style="margin-bottom: 0px">Votre centre de bien-être Zalôna vous accueille :</p>
        <ul>
          <li>le mardi-mercredi-jeudi-vendredi de <strong>10h à
              19h</strong></li>
          <li>le samedi de <strong>10h à 17h</strong></li>
          <li><i>Fermé le Dimanche et le Lundi</i></li>
        </ul>
        <p><b>Téléphone</b> — <a href="tel: 0033985140056">09.85.14.00.56</a></p>
        <p><b>Adresse</b> — 39 sente des Châtelets, 78510 Triel sur Seine, France</p>
        <p><b>Email</b> — <a href="mailto: contact@zalona.fr">contact@zalona.fr</a></p>
        <p><b>Visite</b> — Sur rendez-vous uniquement</p>
      </div>
      <div class="col-md-6 animate-box" data-animate-effect="fadeInLeft">
        <p>Ne soyez pas timide, n'hésitez pas à nous contacter !</p>
        <form method="post" class="row" [formGroup]="form" (ngSubmit)="onClickSubmitContact()">
          <div class="col-md-6">
            <div class="form-group">
              <input type="text" class="form-control" placeholder="Votre nom" name="" required formControlName="name">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <input type="text" class="form-control" placeholder="Votre email" formControlName="email"> </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <input type="text" class="form-control" placeholder="L'objet de votre message" formControlName="objet">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <input type="text" class="form-control" placeholder="Votre téléphone" formControlName="phone"> </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <textarea name="message" id="message" cols="30" rows="7" class="form-control" placeholder="Votre message"
                formControlName="message"></textarea>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <input type="submit" class="btn btn-contact" value="Envoyez à Zalôna"> </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
