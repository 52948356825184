import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { IsConnectedService } from '@services/is-connected.service';
import AnimationsMethod from '@animations';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import ICure from '@models/cure.history.model';
import { HotToastService } from '@ngneat/hot-toast';
import { DetailsCureComponent } from './details-cure/details-cure.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-cures',
  templateUrl: './cures.component.html',
  styleUrls: ['./cures.component.scss'],
})
export class CuresComponent implements OnInit {
  public isConnected: boolean = false;
  public animationsMain: any = AnimationsMethod;
  public cures: ICure[] = [];

  constructor(
    private titleService: Title,
    public isConnectedService: IsConnectedService,
    private router: Router,
    public spinner: NgxSpinnerService,
    private httpClient: HttpClient,
    private toast: HotToastService,
    public dialog: MatDialog
  ) {
    this.titleService.setTitle('Liste de vos cures');
    this.isConnected = this.isConnectedService.isConnected;
  }

  ngOnInit(): void {
    if (this.isConnected) {
      this.isConnectedService.updateInformation();
      this.getAllCure();
    } else {
      this.router.navigate(['/redirect']);
    }
  }

  ngOnDestroy() {
    this.animationsMain = null;
  }

  getAllCure() {
    this.spinner.show();
    this.httpClient
      .get(
        'api/v1/software/package/all?customerId=' +
          this.isConnectedService.getDetails().user.id
      )
      .subscribe({
        next: (res: any) => {
          this.cures = res.results;
          setTimeout(() => {
            this.animationsMain();
          }, 50);
        },
        error: (err) => {},
        complete: () => {
          this.spinner.hide();
        },
      });
  }

  getType(type: 'usable' | 'used' | 'closed' | 'expired'): ICure[] {
    return this.cures
      .filter((e) => e.state === type)
      .sort((a, b) => {
        return (
          Math.round(new Date(b.last_edit).getTime() / 1000) -
          Math.round(new Date(a.last_edit).getTime() / 1000)
        );
      });
  }

  downloadPdf(base64String, fileName) {
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement('a');
    link.href = source;
    link.id = 'zoneDownloadPdf';
    link.download = `${fileName}`;
    link.click();
    document.getElementById('zoneDownloadPdf').remove();
    this.spinner.hide();
  }

  getPdf(ticketId: string) {
    this.spinner.show();
    this.httpClient.get('api/v1/software/ticket/pdf/' + ticketId).subscribe({
      next: (res: any) => {
        if (res.content && res.content !== null) {
          this.downloadPdf(res.content, res.name);
        } else {
          this.toast.error(
            'Une erreur est survenue lors du téléchargement de votre facture. Veuillez recommencer dans quelques instant',
            { position: 'bottom-center' }
          );
          this.spinner.hide();
        }
      },
      error: (err) => {
        this.spinner.hide();
        this.toast.error(
          'Une erreur est survenue lors du téléchargement de votre facture. Veuillez recommencer dans quelques instant',
          { position: 'bottom-center' }
        );
      },
      complete: () => {
        this.spinner.hide();
      },
    });
  }

  detailsCure(cure: ICure) {
    this.dialog.open(DetailsCureComponent, {
      data: { ...cure },
      panelClass: 'zalona-modalbox',
      width: '450px',
    });
  }
}
