import { Component, OnInit } from '@angular/core';
import AnimationsMethod from '@animations';

@Component({
  selector: 'app-unsecured',
  templateUrl: './unsecured.component.html',
  styleUrls: ['./unsecured.component.scss'],
})
export class UnsecuredComponent implements OnInit {
  public animationsMain: any = AnimationsMethod;

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      this.animationsMain();
    }, 50);
  }
}
