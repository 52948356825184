<div class="cont">
  <div class="type">
    <h6 class="titleProduct">{{prestation.name}}</h6>
  </div>
  <div class="valueAmount">
    <h4>{{prestation.sell_price}}<span>€</span></h4>

    <span style="position: relative">
      <mat-icon aria-hidden="false" style="position: absolute; top: -2px; left: -30px">access_time</mat-icon>
      {{prestation.online_duration === "0" ? prestation.duration : prestation.online_duration }} min
    </span>

  </div>
  <p class="subTitlePresta"><u>Description</u></p>
  <div class="feat" (click)="openDetails(prestation)" *ngIf="prestation.description != ''">
    <div class="container" [innerHTML]="description"></div>
  </div>
  <div class="feat" *ngIf="prestation.description == ''">
    <div class="container without-link" style="font-style: italic; font-size: 11px;">Aucune
      description
    </div>
  </div>
</div>

<div style="height: 140px" class="zalona-flex-center">
  <div>
    <div class="btn-contact" style="margin-bottom: 5px;" *ngIf="prestation.online == 1">
      <a href="" [routerLink]="" class="btnReservation" (click)="reservation($event, prestation)">
        <mat-icon class="iconRef">calendar_today</mat-icon> <span>Réserver</span>
      </a>
    </div>
    <!--
    <div class="btn-contact" style="margin-bottom: 5px;" *ngIf="prestation.online == 0">
      <a href="" [routerLink]="" class="btnContactUs" (click)="contactUs(prestation)">
        <mat-icon>spa</mat-icon> <span>Nous contacter</span>
      </a>
    </div>
-->


    <div class="btn-contact" style="margin-bottom: 5px;" *ngIf="prestation.online == 0">
      <a href="" [routerLink]="" class="btnReservation" (click)="contactUs(prestation)">
        <mat-icon class="iconRef">calendar_today</mat-icon> <span>Réserver</span>
      </a>
    </div>


    <div class="btn-contact" *ngIf="prestation.description != ''"> <a href="" [routerLink]="" class="btnOffrir"
        (click)="offer(prestation)">
        <mat-icon class="iconRef">card_giftcard</mat-icon> <span>Offrir</span>
      </a> </div>
  </div>
</div>
