import { Component, OnInit, Inject, Renderer2, ViewChild } from '@angular/core';
import {
  NavigationEnd,
  Router,
  RouterOutlet,
  ActivatedRoute,
} from '@angular/router';
import { slideInAnimation2 } from '@animationsAngular';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { filter, map, mergeMap } from 'rxjs/operators';
import { SeoService } from '@services/seo.service';
// import { ScriptService } from '@services/extern-script.service';

@Component({
  selector: 'app-zalona',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation2],
})
export class AppComponent implements OnInit {
  // @ViewChild('myPlanity') myPlanity: any;
  title: string = 'chalet-zalona';
  initialLoader = 'initialLoader';
  constructor(
    private titleService: Title,
    private renderer: Renderer2,
    private spinner: NgxSpinnerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _seoService: SeoService
  ) // private scriptService: ScriptService
  {
    this.spinner.show(this.initialLoader);
    this.titleService.setTitle(
      'Zalôna - Institut de beauté & Centre de bien-être à Triel sur Seine'
    );
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        // console.log('event', event);
        this._seoService.updateTitle(event['title']);
        this._seoService.updateOgUrl(event['ogUrl']);
        this._seoService.updateDescription(
          event['title'] + event['description']
        );
      });

    // (window as any).planity = {
    //   key: '-NFOym6dPfxJXMywK7qG',
    //   primaryColor: '#a2783a',
    //   accountContainer: document.getElementById('#myId'),
    // };

    // this.scriptService
    //   .load('planity_core', 'planity_app')
    //   .then((data) => {
    //     console.log('script loaded ', (window as any).planity);
    //   })
    //   .catch((error) => console.log(error));
  }

  ngAfterViewInit() {
    setTimeout(() => {
      let loader = this.renderer.selectRootElement('#initLoaderContainer');
      this.renderer.setStyle(loader, 'visibility', 'hidden');
      this.renderer.setStyle(loader, 'opacity', 0);
      this.spinner.show(this.initialLoader);
    }, 2000);
  }

  getAnimationData(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animation']
    );
  }

  getState(outlet: any) {
    // const animation = outlet.activatedRouteData['animation'] || {};
    // return animation['value'] || null;

    return outlet.activatedRouteData.state;

    // const state = outlet.activatedRouteData.state;
    // return state === undefined ? 'home' : state;
  }
}
