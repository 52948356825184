import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { share } from 'rxjs/operators';
import IPrestationCart from '@models/prestation-cart.model';
import IPrestation from '@models/prestation.model';
import ICart from '@models/cart.model';
import IGift from '@models/gift.model';
import IProduct from '@models/product.model';
import { HotToastService } from '@ngneat/hot-toast';

const template: ICart = {
  services: [],
  gifts: [],
  produits: [],
  packages: [],
};

type IType = 'service' | 'gift' | 'produit' | 'cure';
interface IStore {
  type: IType;
  values: IGift | IPrestationCart | IProduct | IPrestation;
}
@Injectable({
  providedIn: 'root',
})
export class StoreCartService {
  private key: string = 'cartZalona';
  private onSubject = new Subject<ICart>();
  public changes = this.onSubject.asObservable().pipe(share());
  constructor(private toast: HotToastService) {
    this.start();
  }

  ngOnDestroy() {
    this.stop();
  }

  public getStorage() {
    return JSON.parse(localStorage.getItem(this.key));
  }

  public getCurrentCart(): ICart {
    const currentCart: ICart = JSON.parse(localStorage.getItem(this.key));
    return currentCart === null ? template : currentCart;
  }

  public store(data: IStore): void {
    const currentCart = this.getCurrentCart();
    if (data.type === 'service') {
      currentCart.services = [
        ...currentCart.services,
        data.values,
      ] as IPrestationCart[];
      currentCart.gifts = [...currentCart.gifts];
      currentCart.produits = [...currentCart.produits];
      currentCart.packages = [...currentCart.packages];
    }

    if (data.type === 'gift') {
      currentCart.services = [...currentCart.services];
      currentCart.gifts = [...currentCart.gifts, data.values] as IGift[];
      currentCart.produits = [...currentCart.produits];
      currentCart.packages = [...currentCart.packages];
    }

    if (data.type === 'produit') {
      const values: IProduct = data.values as any;
      const index = currentCart.produits.findIndex((e) => e.id === values.id);
      let newArray = [];
      if (index === -1) {
        newArray = [...currentCart.produits, { ...data.values, basketQty: 1 }];
      } else {
        currentCart.produits[index] = {
          ...currentCart.produits[index],
          basketQty: currentCart.produits[index].basketQty + 1,
        };
        newArray = currentCart.produits;
      }

      currentCart.services = [...currentCart.services];
      currentCart.gifts = [...currentCart.gifts];
      currentCart.produits = newArray as IProduct[];
      currentCart.packages = [...currentCart.packages];
    }

    if (data.type === 'cure') {
      currentCart.packages = [
        ...currentCart.packages,
        data.values,
      ] as IPrestation[];
      currentCart.gifts = [...currentCart.gifts];
      currentCart.produits = [...currentCart.produits];
      currentCart.services = [...currentCart.services];
    }

    localStorage.setItem(this.key, JSON.stringify(currentCart));
    this.onSubject.next(currentCart);
  }

  public call(): void {
    this.onSubject.next(this.getCurrentCart());
  }

  public clear(): void {
    localStorage.removeItem(this.key);
    this.onSubject.next({
      services: [],
      gifts: [],
      produits: [],
      packages: [],
    });
  }

  public remove(type: IType, index: number): void {
    let el;
    if (type === 'service') {
      el = 'services';
    }
    if (type === 'gift') {
      el = 'gifts';
    }

    if (type === 'produit') {
      el = 'produits';
    }

    if (type === 'cure') {
      el = 'packages';
    }

    const newValues = this.getCurrentCart()[el].filter((e, i) => {
      return i !== index;
    });
    localStorage.setItem(
      this.key,
      JSON.stringify({ ...this.getCurrentCart(), [el]: newValues })
    );
    this.onSubject.next({ ...this.getCurrentCart(), [el]: newValues });
  }

  public checkValidity() {
    const check = this.getCurrentCart().services.filter(
      (e) => e.expire < Math.round(new Date().getTime() / 1000)
    );

    const listIndex = check.map((e) => {
      const index = this.getCurrentCart().services.findIndex(
        (f) => f.token === e.token
      );
      return index;
    });

    if (listIndex.length > 0) {
      listIndex.forEach((i) => {
        this.remove('service', i);
      });

      this.toast.info(
        "Désolé, une des réservations de votre panier a dépassé les 5 minutes d'inactivité et n'est plus disponible"
      );
    }
  }

  private start(): void {
    window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  private storageEventListener(event: StorageEvent) {
    if (event.storageArea == localStorage) {
      let v;
      try {
        v = JSON.parse(event.newValue);
      } catch (e) {
        v = event.newValue;
      }
      this.onSubject.next(v);
    }
  }

  private stop(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
    this.onSubject.complete();
  }
}
