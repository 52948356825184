<p mat-dialog-title
  style="font-family: 'Nunito Sans', sans-serif !important; text-transform:uppercase; text-align: center; font-size: 14px; color: #a2783a"
  class="bold">
  Détail du ticket n°{{valuesInject.reference}}
</p>


<div mat-dialog-content>

  <div class="item" style="text-align: center; width: 100%">
    <div class="row animate-box"
      style="margin-left: 5px; margin-right: 5px; margin-bottom:10px; border: 1px solid rgba(0,0,0,0.1); border-radius: 3px; position: relative"
      data-animate-effect="fadeInUp" *ngFor="let content of valuesInject.content">
      <div class="col-md-12" style="text-align: center; margin-top: 10px
      ">
        <span
          style="border-radius: 50px; background-color: #3272c0; color: white; font-size: 10px; padding-left: 6px; padding-right: 6px"
          *ngIf="content.type === 'gift'">Carte
          cadeau</span>

        <span
          style="border-radius: 50px; background-color: #16ad6e; color: white; font-size: 10px; padding-left: 6px; padding-right: 6px"
          *ngIf="content.type === 'service'">Prestation / soin</span>

        <span
          style="border-radius: 50px; background-color: #9416ad; color: white; font-size: 10px; padding-left: 6px; padding-right: 6px"
          *ngIf="content.type === 'product'">Produit</span>

        <span
          style="border-radius: 50px; background-color: #ad6b16; color: white; font-size: 10px; padding-left: 6px; padding-right: 6px"
          *ngIf="content.type === 'package'">Cure</span>

        <span
          style="border-radius: 50px; background-color: #5716ad; color: white; font-size: 10px; padding-left: 6px; padding-right: 6px"
          *ngIf="content.type === 'subscription'">Abonnement</span>
      </div>

      <div class="col-md-12">
        <div class="row">
          <div class="col-md-10" style="display: flex; align-items: center;
          justify-content:start;">
            <div>
              <strong>{{content.product ? content.product.name : "Valeur de carte cadeau " + (content.total_ttc | currency:'EUR':'symbol':'1.2-2':'fr') }}</strong>
            </div>
          </div>

          <div class="col-md-2"><i
              style="font-size: 9px">Qté(s)</i><br /><strong>{{content.quantity | number: '1.0-0'}}</strong></div>
        </div>

      </div>

      <div class="col-md-12" style="margin-top: 20px; margin-bottom: 10px">
        <div class="row">
          <div class="col-md-4"><strong>HT
              {{content.total_ht | currency:'EUR':'symbol':'1.2-2':'fr'}}</strong>
          </div>
          <div class="col-md-4"><strong>TVA
              {{content.total_tva | currency:'EUR':'symbol':'1.2-2':'fr'}}</strong>
          </div>
          <div class="col-md-4"><strong>TTC
              {{content.total_ttc | currency:'EUR':'symbol':'1.2-2':'fr'}}</strong>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
