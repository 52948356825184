import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HotToastService } from '@ngneat/hot-toast';
import { StoreCartService } from '@services/store-cart.service';
import { IsConnectedService } from '@services/is-connected.service';
import { NgxSpinnerService } from 'ngx-spinner';
import ICart from '@models/cart.model';
import AnimationsMethod from '@animations';
import { environment } from '@env/environment';
import IEcommerce from '@models/ecommerce.model';
import ICookie from '@models/cookie.model';
import { UsermenuService } from '@services/usermenu.service';
import { ScriptService } from '@services/extern-script.service';
import { ConnexionComponent } from '@components/zalona-user/connexion/connexion.component';

declare let Stripe: any;
declare let $: any;

type IType = 'service' | 'gift' | 'produit' | 'cure';
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  public listOfCart: ICart = {
    packages: [],
    produits: [],
    gifts: [],
    services: [],
  };
  public isAnimated: boolean = true;
  public animationsMain: any = AnimationsMethod;
  public currentTime: number = Math.round(new Date().getTime() / 1000);
  public detailsEcommerce: IEcommerce = null;
  public initialLoading: boolean = false;
  public realPayment: number = 0;
  public totalPayment: number = 0;
  public acompteReservation: number = 0;
  public displayType: 'BASKET' | 'STRIPE' | 'STATUTSTRIPE' | 'STATUTBOOKING' =
    'BASKET';
  public detailUser: ICookie = null;
  public stripe: any = null;
  public paymentElement: any = null;
  public elements: any = null;
  public tempModel: ICart = {
    packages: [],
    produits: [],
    gifts: [],
    services: [],
  };

  public stripePaymentIdent: string = null;

  constructor(
    public dialogRef: MatDialogRef<CartComponent>,
    private httpClient: HttpClient,
    private spinner: NgxSpinnerService,
    private storeManagement: StoreCartService,
    private toast: HotToastService,
    public isConnectedService: IsConnectedService,
    private usermenuService: UsermenuService,
    private router: Router,
    private scriptService: ScriptService,
    private renderer: Renderer2,
    public dialog: MatDialog
  ) {
    this.spinner.show();
    if (this.isConnectedService.isConnected) {
      this.detailUser = this.isConnectedService.getDetails();
    }

    this.httpClient.get('api/v1/software/account/ecommerce').subscribe({
      next: (res: any) => {
        this.detailsEcommerce = res;
        this.initialLoading = true;
        this.spinner.hide();

        this.storeManagement.changes.subscribe((e: ICart) => {
          this.listOfCart = e;

          console.log(this.listOfCart);

          this.calculOfBasketAmount();
        });

        this.storeManagement.checkValidity();

        this.storeManagement.call();

        setTimeout(() => {
          this.animationsMain();
          setTimeout(() => {
            this.isAnimated = false;
          }, 1000);
        }, 50);
      },
      error: (err) => {},
      complete: () => {},
    });
  }

  ngOnInit(): void {}

  calculOfBasketAmount() {
    let totalPrestations = 0;
    let amountPrestations = 0;

    if (
      this.detailsEcommerce.deposit_amount_type === '1' &&
      this.detailsEcommerce.deposit_amount !== '0.00'
    ) {
      amountPrestations += this.listOfCart.services.reduce((sum, current) => {
        return (
          sum +
          current.details.reduce((subSum, e) => {
            return (
              subSum +
              Number(e.sell_price) *
                (Number(this.detailsEcommerce.deposit_amount) / 100)
            );
          }, 0)
        );
      }, amountPrestations);
    }
    if (
      this.detailsEcommerce.deposit_amount_type === '2' &&
      this.detailsEcommerce.deposit_amount !== '0.00'
    ) {
      amountPrestations += this.listOfCart.services.reduce((sum, current) => {
        return (
          sum +
          current.details.reduce((subSum, e) => {
            return (
              subSum +
              Number(e.sell_price) *
                (Number(this.detailsEcommerce.deposit_amount) / 100)
            );
          }, 0)
        );
      }, amountPrestations);
    }

    this.acompteReservation = amountPrestations;

    totalPrestations += this.listOfCart.services.reduce((sum, current) => {
      return (
        sum +
        current.details.reduce((subSum, e) => {
          return subSum + Number(e.sell_price);
        }, 0)
      );
    }, totalPrestations);

    let amountPackages = this.listOfCart.packages.reduce((sum, current) => {
      return sum + Number(current.sell_price);
    }, 0);

    let amountGifts = this.listOfCart.gifts.reduce((sum, current) => {
      return (
        sum +
        Number(
          current.type === 'AMOUNT'
            ? current.amount
            : current.service.sell_price
        )
      );
    }, 0);

    let amountProduits = this.listOfCart.produits.reduce((sum, current) => {
      return sum + Number(current.sell_price);
    }, 0);

    this.realPayment =
      amountPrestations + amountPackages + amountGifts + amountProduits;

    this.totalPayment =
      totalPrestations + amountPackages + amountGifts + amountProduits;
  }

  removePrestation(event: MouseEvent, type: IType, index: number) {
    const el = this.renderer.parentNode(event.target);
    $(el).fadeOut(400, () => {
      this.storeManagement.remove(type, index);

      setTimeout(() => {
        this.animationsMain();
      }, 50);
    });
  }

  calculationPrice(sellPrice: string, basketQty: number) {
    return Number(sellPrice) * basketQty;
  }

  confirmBasket() {
    this.spinner.show();
    this.tempModel = this.listOfCart;
    this.httpClient
      .post('api/v1/software/command', {
        token: this.detailUser.token,
        bookings: this.listOfCart.services.map((e) => {
          return {
            date: e.datetime,
            block_id: e.token,
            content: e.details.map((f) => {
              return {
                id: f.id,
              };
            }),
          };
        }),
        gifts: this.listOfCart.gifts.map((e) => {
          let obj: any = {};

          if (e.type === 'AMOUNT') {
            obj.type = 'amount';
            obj.total_ttc = e.amount;
          } else {
            obj.type = 'product';
            obj.product_id = e.service.id;
            obj.product_type = 'service';
          }
          if (e.email && e.email !== null) {
            obj.email = e.email;
          }
          obj.firstname = e.firstname;
          obj.lastname = e.lastname;
          obj.title = e.title;
          obj.message = e.message;
          obj.image = e.pictGift;
          return obj;
        }),
        contents: [
          ...this.listOfCart.produits.map((e) => {
            return {
              id: e.id,
              type: 'product',
              quantity: e.basketQty,
            };
          }),
          ...this.listOfCart.packages.map((e) => {
            return {
              id: e.id,
              type: 'package',
              quantity: 1,
            };
          }),
        ],
      })
      .subscribe({
        next: (res: any) => {
          if (res.result && res.result === 'ERROR') {
            this.toast.error(res.message, {
              position: 'bottom-center',
            });
          } else {
            if (res.stripe_payment_ident === null) {
              this.displayType = 'STATUTBOOKING';
              this.storeManagement.clear();
              this.spinner.hide();
            } else {
              this.displayType = 'STRIPE';
              this.stripePaymentIdent = res.stripe_client_secret;

              const stripeSCRIPTAUTO =
                document.getElementById('stripeSCRIPTAUTO');

              setTimeout(() => {
                if (stripeSCRIPTAUTO === null) {
                  this.scriptService
                    .load('stripe')
                    .then((data) => {
                      this.stripeConfig(res);
                    })
                    .catch((e) => console.error(e));
                } else {
                  this.stripeConfig(res);
                }
              }, 200);
            }
          }
        },
        error: (err) => {},
        complete: () => {},
      });
  }

  stripeConfig(res) {
    this.stripe = Stripe(environment.stripeKey);

    this.elements = this.stripe.elements({
      clientSecret: res.stripe_client_secret,
      loader: 'always',
      locale: 'fr',
      appearance: {
        theme: 'flat',
        variables: {
          colorPrimary: '#000',
          colorText: '#000',
          colorDanger: '#df1b41',
          fontFamily: '"Nunito Sans", sans-serif',
          borderRadius: '3px',
        },
      },
    });
    this.paymentElement = this.elements.create('payment', {
      business: {
        name: 'Zalona',
      },
      defaultValues: {
        billingDetails: {
          name: this.detailUser.user.prenom + ' ' + this.detailUser.user.nom,
          email: this.detailUser.user.email,
        },
      },
      // fields: {
      //   billingDetails: {
      //     name: 'auto',
      //     email: 'auto',
      //     phone: 'never',
      //     address: 'never',
      //   },
      // },
      wallets: {
        applePay: 'auto',
        googlePay: 'auto',
      },
    });
    this.paymentElement.mount('#payment-element');
    this.spinner.hide();
  }

  returnBasket() {
    this.displayType = 'BASKET';
  }

  validatedPayment(data) {
    this.spinner.show();
    const elements = this.elements;

    this.stripe
      .confirmPayment({
        elements,
        confirmParams: {
          return_url: environment.urlStripe + 'checkout',
          payment_method_data: {
            billing_details: {
              name:
                this.detailUser.user.prenom + ' ' + this.detailUser.user.nom,
              email: this.detailUser.user.email,
            },
          },
        },
      })
      .then((e) => {
        this.spinner.hide();
        if (e.error) {
          this.toast.error(e.error.message, {
            position: 'bottom-center',
          });
        }
      });
  }

  openUserLogin() {
    this.dialog.open(ConnexionComponent, {
      data: {},
      panelClass: 'zalona-modalbox',
      width: '600px',
    });

    // this.usermenuService.update('isConnect', true);
    // this.usermenuService.update('sendToCloseMenu', true);
    this.dialogRef.close();
  }

  openInscription() {
    this.router.navigate(['/inscription']);
    this.dialogRef.close();
  }

  ngOnDestroy() {
    // console.log('ca detruit');
    // if (this.paymentElement !== null) {
    //   this.paymentElement.clear();
    //   this.paymentElement.destroy();
    //   this.paymentElement = null;
    //   this.stripe = null;
    //   this.elements = null;
    //   // this.paymentElement.unmount();
    // }
  }
}
