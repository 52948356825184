<p mat-dialog-title
  style="font-family: 'Nunito Sans', sans-serif !important; text-transform:uppercase; text-align: center; font-size: 12px; color: red"
  class="bold">
  Souhaitez-vous réellement annuler cette réservation ?
</p>


<div mat-dialog-content>

  <div class="row"
    style="margin-bottom: 40px; width: 100%; margin-left: 0px; margin-right: 0px; margin-left: 0px; margin-right: 0px">
    <div class="item animate-box" data-animate-effect="fadeInUp" style="text-align: center; width: 100%">
      Le {{valuesInject.begin | date:'mediumDate'}} de {{valuesInject.begin | date:'HH:mm'}} à
      {{valuesInject.end | date:'HH:mm'}}
    </div>
  </div>

  <div class="row"
    style="margin-bottom: 10px; width: 100%; margin-left: 0px; margin-right: 0px; margin-left: 0px; margin-right: 0px">
    <div class="item animate-box" data-animate-effect="fadeInLeft" style="width: 100%">
      <div class="row" style="margin-left: 0px; margin-right: 0px; margin-left: 0px; margin-right: 0px">
        <div class="col-sm-6">

          <div class="btn-validation-group"> <a href="" [routerLink]="" class="btnClosed" (click)="closeModal()">
              <span>NON</span>
            </a> </div>

        </div>
        <div class="col-sm-6">

          <div class="btn-validation-group"> <a href="" [routerLink]="" class="btnValidation" (click)="cancelation()">
              <span>OUI</span>
            </a> </div>

        </div>
      </div>
    </div>
  </div>
</div>
