import { HttpClient } from '@angular/common/http';
import {
  Component,
  Inject,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  Optional,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import IPicture from '@models/picture.gift.model';
import IPrestation from '@models/prestation.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { StoreCartService } from '@services/store-cart.service';
import { createChip } from '@shared/functions';

interface IValuesInject {
  prestation: IPrestation;
  type: 'SERVICE' | 'AMOUNT';
}

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss'],
})
export class OfferComponent implements OnInit {
  public pictures: IPicture[] = [];
  public type: 'SERVICE' | 'AMOUNT';
  @ViewChild('selectForm') public selector: HTMLSelectElement;
  @ViewChildren('radioReference') public essai: QueryList<HTMLInputElement>;
  public pictureSelected: string = null;
  public title: string = null;
  public message: string = null;
  public form: UntypedFormGroup = this.getUntype();
  public submitted: boolean = false;
  public isLoading: boolean = false;

  constructor(
    private httpClient: HttpClient,
    @Optional() public dialogRef: MatDialogRef<OfferComponent>,
    // @Inject(MAT_DIALOG_DATA) public valuesInject: IValuesInject,
    @Optional() @Inject(MAT_DIALOG_DATA) public valuesInject: IValuesInject,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private storeCart: StoreCartService,
    private renderer: Renderer2
  ) {
    this.spinner.show();

    this.httpClient.get('api/v1/software/catalog/pictures/gifts').subscribe({
      next: (res: any) => {
        this.pictures = res;

        this.type =
          this.valuesInject &&
          this.valuesInject !== undefined &&
          this.valuesInject !== null &&
          this.valuesInject.type === 'SERVICE'
            ? 'SERVICE'
            : 'AMOUNT';

        this.setForm();

        this.spinner.hide();
      },
      error: (err) => {
        this.spinner.hide();
      },
      complete: () => {},
    });
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group(this.getValidators());
  }

  ngAfterViewInit(): void {}

  addToCart(event): void {
    this.submitted = true;
    if (!this.form.invalid) {
      createChip(event, '.containerBaskBuying', this.renderer).then((e) => {
        this.storeCart.store({
          type: 'gift',
          values: this.form.value,
        });
        this.submitted = false;
        this.form.reset();
        this.setForm();
        // this.dialogRef.close();
      });
    }
  }

  getValidators(): any {
    return {
      type: [this.form.value.type, Validators.required],
      pictGift: [this.form.value.pictGift, Validators.required],
      lastname: [this.form.value.lastname, Validators.required],
      firstname: [this.form.value.firstname, Validators.required],
      title: [this.form.value.title, Validators.required],
      message: [this.form.value.message, Validators.required],
      sendEmail: [this.form.value.sendEmail],
      amount: [
        this.form.value.amount,
        this.type === 'SERVICE'
          ? null
          : [Validators.required, Validators.min(20)],
      ],
      email: [this.form.value.email],
      service: [this.form.value.service],
      // email: ['', [Validators.required Validators.email]]
    };
  }

  getUntype(): any {
    return new UntypedFormGroup({
      type: new UntypedFormControl(''),
      pictGift: new UntypedFormControl(),
      lastname: new UntypedFormControl(''),
      firstname: new UntypedFormControl(''),
      title: new UntypedFormControl(''),
      message: new UntypedFormControl(''),
      amount: new UntypedFormControl(50),
      sendEmail: new UntypedFormControl(false),
      email: new UntypedFormControl(''),
      service: new UntypedFormControl(null),
    });
  }

  setForm(): void {
    if (
      this.valuesInject &&
      this.valuesInject !== undefined &&
      this.valuesInject !== null &&
      this.valuesInject.type === 'SERVICE'
    ) {
      this.form.setValue({
        ...this.form.value,
        type: this.valuesInject.prestation.id,
        pictGift: this.pictures[0].id,
        service: this.valuesInject.prestation,
      });
    } else {
      this.form.setValue({
        ...this.form.value,
        type: 'AMOUNT',
        pictGift: this.pictures[0].id,
        amount: 50,
      });
    }
    this.pictureSelected = this.pictures[0].link;
  }

  changeType(event: Event): void {
    this.form.setValue({
      ...this.form.value,
      service:
        this.form.value.type !== 'AMOUNT' ? this.valuesInject.prestation : null,
    });

    if (this.form.value.type === 'SERVICE') {
      this.form.get('amount').setValidators(Validators.required);
    } else {
      this.form.get('amount').clearValidators();
    }
  }

  changeEmailOption(event: Event): void {
    if (this.form.value.sendEmail) {
      this.form
        .get('email')
        .setValidators([Validators.required, Validators.email]);
    } else {
      this.form.get('email').clearValidators();
    }
  }

  changePicture(event, reference): void {
    this.form.setValue({
      ...this.form.value,
      pictGift: parseInt(reference.value),
    });
    reference.checked = true;
    const find = this.pictures.find((e) => e.id === parseInt(reference.value));

    if (find !== undefined) {
      this.pictureSelected = find.link;
    }
  }

  changeTitle(event): void {
    this.title = event.target.value;
  }

  changeMessage(event): void {
    this.message = event.target.value;
  }
}
