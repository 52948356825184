import { Component, OnInit } from '@angular/core';
import AnimationsMethod from '@animations';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HotToastService } from '@ngneat/hot-toast';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-zalona-contact',
  templateUrl: './zalona-contact.component.html',
  styleUrls: ['./zalona-contact.component.scss'],
})
export class ZalonaContactComponent implements OnInit {
  public animationsMain: any = AnimationsMethod;
  public submitted: boolean = false;
  public isLoading: boolean = false;
  public zoom = 15;
  public center: google.maps.LatLngLiteral = {
    lat: 48.97171,
    lng: 2.01325,
  };
  public options: google.maps.MapOptions = {
    backgroundColor: '#a2783a',
    // mapTypeId: 'hybrid',
    zoomControl: false,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    maxZoom: 20,
    minZoom: 15,
    disableDefaultUI: true,
    fullscreenControl: false,
    styles: [
      {
        stylers: [
          {
            visibility: 'on',
          },
          {
            saturation: -100,
          },
          {
            gamma: 0.54,
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'water',
        stylers: [
          {
            color: '#4d4946',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'labels.text',
        stylers: [
          {
            visibility: 'simplified',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '#ffffff',
          },
        ],
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text',
        stylers: [
          {
            visibility: 'simplified',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#ffffff',
          },
        ],
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [
          {
            gamma: 0.48,
          },
        ],
      },
      {
        featureType: 'transit.station',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'geometry.stroke',
        stylers: [
          {
            gamma: 7.18,
          },
        ],
      },
    ],
  };

  public optionsMaker: any = {
    position: {
      lat: 48.97171,
      lng: 2.01325,
    },
    // label: {
    //   color: '#a2783a',
    //   text: 'zalona',
    // },

    // title: 'ZALONA',
    options: {
      animation: google.maps.Animation.BOUNCE,
      icon: {
        url: 'https://zalona.fr/assets/images/marker.png',
        // size: {
        //   width: 128,
        //   height: 128,
        // },
      },
    },
  };
  public form: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl(''),
    email: new UntypedFormControl(''),
    objet: new UntypedFormControl(''),
    message: new UntypedFormControl(''),
    phone: new UntypedFormControl(''),
  });

  constructor(
    private httpClient: HttpClient,
    private toast: HotToastService,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      objet: ['', [Validators.required]],
      message: ['', [Validators.required]],
      phone: [''],
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.animationsMain();
    }, 500);
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onClickSubmitContact() {
    this.submitted = true;
    if (!this.form.invalid) {
      this.isLoading = true;
      this.spinner.show();

      this.httpClient
        .post(
          'api/v1/contact/send',
          {
            ...this.form.value,
          },
          { observe: 'response' }
        )
        .subscribe({
          next: (res: any) => {
            this.toast.success(
              'Votre message a bien été envoyé à votre centre de bien-être Zalôna',
              {
                position: 'top-center',
              }
            );
            this.form.reset();
          },
          error: (err) => {
            this.isLoading = false;
            this.spinner.hide();
          },
          complete: () => {
            this.isLoading = false;
            this.spinner.hide();
          },
        });
    } else {
      let message = '';
      if (this.f['name'].errors && this.f['name'].errors['required']) {
        message =
          'Votre nom ou prénom est obligatoire pour envoyer votre demande';
      } else if (this.f['email'].errors && this.f['email'].errors['required']) {
        message = 'Votre mail est obligatoire pour envoyer votre demande';
      } else if (this.f['email'].errors && this.f['email'].errors['email']) {
        message = "Votre mail n'est pas correcte";
      } else if (this.f['objet'].errors && this.f['objet'].errors['required']) {
        message = "L'objet de votre message est obligatoire";
      } else if (
        this.f['message'].errors &&
        this.f['message'].errors['required']
      ) {
        message = 'Un message est obligatoire pour envoyer votre demande';
      } else {
        message = "Une erreur est survenue avant l'envoi de votre message";
      }

      this.toast.error(message, {
        position: 'top-center',
      });
    }
  }
}
