import { Component, OnInit } from '@angular/core';
// import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { IsConnectedService } from '@services/is-connected.service';
import AnimationsMethod from '@animations';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { HotToastService } from '@ngneat/hot-toast';
import { MatDialog } from '@angular/material/dialog';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import Validation from './validation';
import * as moment from 'moment';

@Component({
  selector: 'app-compte',
  templateUrl: './compte.component.html',
  styleUrls: ['./compte.component.scss'],
})
export class CompteComponent implements OnInit {
  public isConnected: boolean = false;
  public animationsMain: any = AnimationsMethod;
  public account: any = null;
  public submitted = false;
  public isLoading: boolean = false;
  public form: UntypedFormGroup = new UntypedFormGroup({
    civility: new UntypedFormControl('Mme'),
    lastname: new UntypedFormControl(''),
    firstname: new UntypedFormControl(''),
    phone: new UntypedFormControl(''),
    email: new UntypedFormControl(''),
    password: new UntypedFormControl(''),
    confirmPassword: new UntypedFormControl(''),
    address: new UntypedFormControl(''),
    zipcode: new UntypedFormControl(''),
    city: new UntypedFormControl(''),
    birthday: new UntypedFormControl(''),
    acceptTerms: new UntypedFormControl(false),
    marketing: new UntypedFormControl(false),
  });

  constructor(
    // private titleService: Title,
    public isConnectedService: IsConnectedService,
    private router: Router,
    public spinner: NgxSpinnerService,
    private httpClient: HttpClient,
    private toast: HotToastService,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder
  ) {
    // this.titleService.setTitle('Détail et modification de votre compte Zalôna');
    this.isConnected = this.isConnectedService.isConnected;
  }

  ngOnInit(): void {
    if (this.isConnected) {
      this.getAccount();
    } else {
      this.router.navigate(['/redirect']);
    }
  }

  ngOnDestroy() {
    this.animationsMain = null;
  }

  getAccount() {
    this.spinner.show();
    this.httpClient
      .get(
        'api/v1/software/user/informations?token=' +
          this.isConnectedService.getDetails().token
      )
      .subscribe({
        next: (res: any) => {
          this.account = res;

          const birthday =
            this.account.birthday && this.account.birthday !== null
              ? moment(this.account.birthday).format('DD/MM/YYYY')
              : null;
          this.form = this.formBuilder.group(
            {
              civility: [this.account.civility, Validators.required],
              lastname: [this.account.lastname, Validators.required],
              firstname: [this.account.firstname, Validators.required],
              phone: [this.account.mobile, Validators.required],
              email: [
                this.account.email,
                [Validators.required, Validators.email],
              ],
              password: [
                '',
                [
                  Validators.required,
                  Validators.minLength(6),
                  Validators.maxLength(13),
                ],
              ],
              confirmPassword: ['', Validators.required],
              address: [this.account.address_road],
              zipcode: [this.account.address_zip_code],
              city: [this.account.address_city],
              birthday: [birthday],
              acceptTerms: [true, Validators.requiredTrue],
              marketing: [this.account.accept_campaign === '1' ? true : false],
            },
            {
              validators: [Validation.match('password', 'confirmPassword')],
            }
          );

          setTimeout(() => {
            this.animationsMain();
          }, 50);
        },
        error: (err) => {},
        complete: () => {
          this.spinner.hide();
        },
      });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onClickSubmitUpdate() {
    this.submitted = true;
    if (!this.form.invalid) {
      this.isLoading = true;
      this.spinner.show();

      this.httpClient
        .put(
          'api/v1/software/user/modification',
          {
            ...this.form.value,
            password: encodeURIComponent(
              (<any>window).btoa(this.form.value.password)
            ),
            confirmPassword: encodeURIComponent(
              (<any>window).btoa(this.form.value.password)
            ),
            user_token: this.isConnectedService.getDetails().token,
          },
          { observe: 'response' }
        )
        .subscribe({
          next: (res: any) => {
            if (res.body.result === 'ERROR') {
              // this.errMessageInscription = res.body.message;
              this.toast.error(res.body.message, { position: 'bottom-center' });
            } else {
              this.toast.success(
                'La modification de votre compte a bien été prise en compte',
                { position: 'top-center' }
              );
              this.isConnectedService.updateInformation();
              setTimeout(() => {
                this.animationsMain();
              }, 500);
            }
          },
          error: (err) => {
            if (err.status === 403) {
              this.toast.error('Vos identifiants ne semblent pas correctes', {
                position: 'bottom-center',
              });
            } else {
              this.toast.error(
                "'Un problème est survenu - Réassayez dans quelques instant",
                { position: 'bottom-center' }
              );
            }
            this.isLoading = false;
            this.spinner.hide();
          },
          complete: () => {
            this.isLoading = false;
            this.spinner.hide();
          },
        });
    }
  }

  openPopup() {
    (<any>window).open(
      'https://www.rdv360.com/cgu/',
      'popup',
      'width=600,height=600'
    );
  }
}
