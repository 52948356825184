import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { share } from 'rxjs/operators';

interface IUserMenu {
  isForget: boolean;
  isConnect: boolean;
  sendToCloseMenu: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UsermenuService {
  private onSubject = new Subject<IUserMenu>();
  public changes = this.onSubject.asObservable().pipe(share());
  public isForget: boolean = false;
  public isConnect: boolean = false;
  public sendToCloseMenu: boolean = false;

  constructor() {
    this.init();
  }

  init() {
    this.onSubject.next({
      isForget: this.isForget,
      isConnect: this.isConnect,
      sendToCloseMenu: this.sendToCloseMenu,
    });
  }

  update(key, value) {
    const current = {
      isForget: this.isForget,
      isConnect: this.isConnect,
      sendToCloseMenu: this.sendToCloseMenu,
    };
    this.onSubject.next({
      ...current,
      [key]: value,
    });
    this[key] = value;
  }
}
