import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-zalona-onglerie',
  templateUrl: './zalona-onglerie.component.html',
  styleUrls: ['./zalona-onglerie.component.scss']
})
export class ZalonaOnglerieComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
