import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
  ExtraOptions,
  PreloadAllModules,
} from '@angular/router';
import { ZalonaHomeComponent } from '@components/zalona-home/zalona-home.component';
import { UnsecuredComponent } from '@components/unsecured/unsecured.component';
import { ZalonaCguComponent } from '@components/zalona-cgu/zalona-cgu.component';
import { ZalonaCheckoutComponent } from '@components/zalona-checkout/zalona-checkout.component';
import { ZalonaPrestationsComponent } from '@components/zalona-prestations/zalona-prestations.component';
import { ZalonaCuresComponent } from '@components/zalona-cures/zalona-cures.component';
import { ZalonaProductsComponent } from '@components/zalona-products/zalona-products.component';
import { ZalonaInstitutComponent } from '@components/zalona-institut/zalona-institut.component';
import { ZalonaInscriptionComponent } from '@components/zalona-inscription/zalona-inscription.component';
import { HistoriqueComponent } from '@components/zalona-user/historique/historique.component';
import { AchatsComponent } from '@components/zalona-user/achats/achats.component';
import { CompteComponent } from '@components/zalona-user/compte/compte.component';
import { CuresComponent } from '@components/zalona-user/cures/cures.component';
import { ZalonaContactComponent } from '@components/zalona-contact/zalona-contact.component';
import { ZalonaOffresComponent } from '@components/zalona-offres/zalona-offres.component';
import { ZalonaBlankComponent } from '@components/zalona-blank/zalona-blank.component';
import { ZalonaOnglerieComponent } from '@components/zalona-onglerie/zalona-onglerie.component';
import { ZalonaLpgComponent } from '@components/zalona-lpg/zalona-lpg.component';
import { ZalonaSportComponent } from '@components/zalona-sport/zalona-sport.component';
import { ZalonaOfferComponent } from '@components/zalona-offer/zalona-offer.component';

const routerOptions: ExtraOptions = {
  // scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  preloadingStrategy: PreloadAllModules,
  paramsInheritanceStrategy: 'always',
  // useHash: true,
  // scrollOffset: [0, 64],
};

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/home',
    data: {
      title:
        'Zalôna - Institut de beauté & Centre de bien-être à Triel sur Seine',
      description:
        'Bienvenue chez Zalôna, votre centre de bien-être à Triel sur seine. Nous vous accueillons à domicile dans un lieu dédié à la relaxation et la sérénité',
      ogUrl: 'https://zalona.fr/home',
    },
  },
  {
    path: 'home',
    component: ZalonaHomeComponent,
    data: {
      state: 'home',
      title:
        'Zalôna - Institut de beauté & Centre de bien-être à Triel sur Seine',
      description:
        'Bienvenue chez Zalôna, votre centre de bien-être à Triel sur seine. Nous vous accueillons à domicile dans un lieu dédié à la relaxation et la sérénité',
      ogUrl: 'https://zalona.fr/home',
    },
  },
  {
    path: 'redirect',
    component: UnsecuredComponent,
    data: {
      state: 'redirect',
      title: 'Page inaccessible',
      description: 'La page que vous avez demandé est inaccessible',
      ogUrl: 'https://zalona.fr/redirect',
    },
  },
  {
    path: 'cgu',
    component: ZalonaCguComponent,
    data: {
      state: 'cgu',
      title: 'CGU',
      description: "Les conditions générales de ventes et d'utilisation",
      ogUrl: 'https://zalona.fr/cgu',
    },
  },
  {
    path: 'checkout',
    component: ZalonaCheckoutComponent,
    data: { state: 'checkout' },
  },
  {
    path: 'prestations',
    component: ZalonaPrestationsComponent,
    data: {
      state: 'prestations',
      title: 'Toutes nos prestations',
      description:
        'Massages en Solo ou Duo, Escapades, Epilations, Balnéothérapie, soin du visage, LPG Alliance',
      ogUrl: 'https://zalona.fr/prestations',
    },
  },
  {
    path: 'prestations/:idCat',
    component: ZalonaPrestationsComponent,
    data: {
      state: 'prestations',
      title: 'Toutes nos prestations',
      description:
        'Massages en Solo ou Duo, Escapades, Epilations, Balnéothérapie, soin du visage, LPG Alliance',
      ogUrl: 'https://zalona.fr/prestations/categorie',
    },
  },
  {
    path: 'prestations/:idCat/:idDetails',
    component: ZalonaPrestationsComponent,
    data: {
      state: 'prestations',
      title: 'Toutes nos prestations',
      description:
        'Massages en Solo ou Duo, Escapades, Epilations, Balnéothérapie, soin du visage, LPG Alliance',
      ogUrl: 'https://zalona.fr/prestations/categorie/details',
    },
  },
  {
    path: 'cures',
    component: ZalonaCuresComponent,
    data: {
      state: 'cures',
      title: 'Toutes nos cures',
      description: 'LPG dernière génération et soins de visage en cure',
      ogUrl: 'https://zalona.fr/cures',
    },
  },
  {
    path: 'cures/:idCat',
    component: ZalonaCuresComponent,
    data: {
      state: 'cures',
      title: 'Toutes nos cures',
      description: 'LPG dernière génération et soins de visage en cure',
      ogUrl: 'https://zalona.fr/cures/categorie',
    },
  },
  {
    path: 'cures/:idCat/:idDetails',
    component: ZalonaCuresComponent,
    data: {
      state: 'cures',
      title: 'Toutes nos cures',
      description: 'LPG dernière génération et soins de visage en cure',
      ogUrl: 'https://zalona.fr/cures/categorie/details',
    },
  },
  {
    path: 'produits',
    component: ZalonaProductsComponent,
    data: {
      state: 'produits',
      title: 'Nos produits Cosmetiques',
      description:
        'Produits corps, anti-age, nettoyants, contours des yeux, masques, lotions, gommages',
      ogUrl: 'https://zalona.fr/produits',
    },
  },
  {
    path: 'onglerie',
    component: ZalonaOnglerieComponent,
    data: {
      state: 'onglerie',
      title: 'Nos prestations Ongle',
      description:
        "La pose d'ongles et de la french manucure, Nail Art... Nous saurons prendre soin de vos mains et vos pieds.",
      ogUrl: 'https://zalona.fr/onglerie',
    },
  },
  {
    path: 'lpg-minceur',
    component: ZalonaLpgComponent,
    data: {
      state: 'lpg-minceur',
      title: 'Notre LPG Alliance Cellu M6',
      description:
        'Découvrez notre nouveau CELLU M6 Alliance, la nouvelle technologie brevetée par LPG.',
      ogUrl: 'https://zalona.fr/lpg-minceur',
    },
  },
  {
    path: 'salle-de-remise-en-forme',
    component: ZalonaSportComponent,
    data: {
      state: 'salle-de-remise-en-forme',
      title: 'Notre Salle de remise en forme privatisée',
      description:
        'Venez découvrir notre salle de remise en forme unique et privatisée pour vos séances de sport en toute tranquilité',
      ogUrl: 'https://zalona.fr/salle-de-remise-en-forme',
    },
  },
  {
    path: 'offres-du-moment',
    component: ZalonaOffresComponent,
    data: {
      state: 'offres-du-moment',
      title: 'Nos offres du moment',
      description: 'Chaque mois Zalôna vous propose des offres à thème',
      ogUrl: 'https://zalona.fr/offres-du-moment',
    },
  },
  {
    path: 'cartes-cadeau',
    component: ZalonaOfferComponent,
    data: {
      state: 'cartes-cadeau',
      title: "Le plaisir d'offir",
      description:
        'Zalôna vous propose toute une game de cartes cadeau à offir pour vos proches, vos amis, vos collègues',
      ogUrl: 'https://zalona.fr/cartes-cadeau',
    },
  },
  {
    path: 'galerie',
    component: ZalonaInstitutComponent,
    data: {
      state: 'galerie',
      title: 'Notre centre de bien-être',
      description: 'Retrouvez nos différentes salles et équipements',
      ogUrl: 'https://zalona.fr/galerie',
    },
  },
  {
    path: 'contactez-nous',
    component: ZalonaContactComponent,
    data: {
      state: 'contact',
      title: 'Contactez-nous',
      description: 'Contactez-nous avec le formulaire ci-dessous',
      ogUrl: 'https://zalona.fr/contactez-nous',
    },
  },

  {
    path: 'inscription',
    component: ZalonaInscriptionComponent,
    data: {
      state: 'inscription',
      title: 'Creez votre compte',
      description:
        'Créez votre compte et réservez vos soins directement en ligne',
      ogUrl: 'https://zalona.fr/inscription',
    },
  },
  {
    path: 'client/historique',
    component: HistoriqueComponent,
    data: {
      state: 'historique',
      title: 'Mes réservations',
      description: 'Retrouvez toutes vos réservations sur votre compte client',
      ogUrl: 'https://zalona.fr/client/historique',
    },
  },
  {
    path: 'client/achats',
    component: AchatsComponent,
    data: {
      state: 'achats',
      title: 'Mes achats',
      description: 'Retrouvez tous vos achats sur votre compte client',
      ogUrl: 'https://zalona.fr/client/achats',
    },
  },
  {
    path: 'client/cures',
    component: CuresComponent,
    data: {
      state: 'curesClient',
      title: 'Mes cures',
      description: 'Retrouvez toutes vos cures sur votre compte client',
      ogUrl: 'https://zalona.fr/client/cures',
    },
  },
  {
    path: 'client/compte',
    component: CompteComponent,
    data: {
      state: 'compte',
      title: 'Mon compte client',
      description: 'Retrouvez tous vos informations sur votre compte client',
      ogUrl: 'https://zalona.fr/client/compte',
    },
  },
  { path: '**', pathMatch: 'full', component: ZalonaHomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
