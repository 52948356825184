import Color from './color.model';
import Image from './image.model';

export default class Familly {
  constructor(
    public id: string,
    public name: string,
    public type: 'services' | 'products' | 'subscriptions' | 'packages',
    public show_order: number,
    public online: boolean,
    public color: Color,
    public image: Image,
    public created: string,
    public last_edit: string,
    public parent?: {
      id: string;
      name: string;
    }
  ) {}
}
