import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import ITicket from '@models/ticket.model';
import { IsConnectedService } from '@services/is-connected.service';
import AnimationsMethod from '@animations';

@Component({
  selector: 'app-details-achat',
  templateUrl: './details-achat.component.html',
  styleUrls: ['./details-achat.component.scss'],
})
export class DetailsAchatComponent implements OnInit {
  public animationsMain: any = AnimationsMethod;
  constructor(
    @Inject(MAT_DIALOG_DATA) public valuesInject: ITicket,
    public dialogRef: MatDialogRef<DetailsAchatComponent>,
    public isConnectedService: IsConnectedService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.animationsMain();
    }, 50);
  }

  getGoodWording(
    type: 'gift' | 'service' | 'product' | 'package' | 'subscription'
  ) {
    switch (type) {
      case 'gift':
        return `<span style="border-radius: 50px; background-color: #3272c0; color: white">Carte cadeausss</span>`;
      case 'service':
        return 'Prestation / soin';
      case 'product':
        return 'Produit';
      case 'package':
        return 'Cure';
      case 'subscription':
        return 'Abonnement';
    }
  }
}
