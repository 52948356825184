import Image from './image.model';

export default class Prestation {
  constructor(
    public id: string,
    public name: string,
    public description: string,
    public sell_online: '0' | '1',
    public sell_price: string,
    public image: Image,
    public vat_rate: {
      id: string;
      vat_rate: string;
    },
    public category: {
      id: string;
      name: string;
    },
    public duration: string,
    public online_use_duration: string,
    public online_duration: string,
    public allowed_calendar: boolean,
    public allowed_equipment: string,
    public allowed_equipments: number,
    public online: number,
    public online_message: string,
    public online_ask: boolean,
    public online_ask_question: string,
    public with_step: boolean,
    public last_edit: string,
    public created: string,
    public month_validity?: string,
    public steps?: {
      id: number;
      name: string;
      duration: number;
      calendars: number[];
      equipments: number[];
    }
  ) {}
}
