<div class="banner-container" #onTop>
  <div class="banner-img"> <img class="banner-img-width" src="../../assets/images/banner-prestation08.jpg"
      alt="LPG System Celu M6">
  </div>
  <div class="banner-head">
    <div class="banner-head-padding banner-head-margin">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12"> <span class="heading-meta">Zalona</span>
            <h2 class="pwe-heading animate-box" data-animate-effect="fadeInLeft">Notre solution LPG</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="services-section services pt-0 pb-90">
  <div class="container-fluid">

    <div class="row big-screen" style="margin-bottom: 20px">
      <div class="col-md-6">
        <div style="width: 100%; display: flex; justify-content: flex-start;">
          <div class="btn-contact">
            <a href="" [routerLink]="" class="btnCorps" [class.activeMovie]="selected === 'corps'"
              (click)="toggleMovie('corps')"><span>Présentation
                Corps</span></a>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div style="width: 100%; display: flex; justify-content: flex-end;">
          <div class="btn-contact">
            <a href="" [routerLink]="" class="btnCorps" [class.activeMovie]="selected === 'visage'"
              (click)="toggleMovie('visage')"><span>Présentation
                Visage</span></a></div>
        </div>

      </div>
    </div>


    <div class="row small-screen" style="margin-bottom: 20px">

      <div class="col-md-12" style="margin-bottom: 12px">
        <div class="btn-contact pAbsolute left">
          <a href="" [routerLink]="" class="btnCorps" [class.activeMovie]="selected === 'corps'"
            (click)="toggleMovie('corps')"><span>Présentation
              Corps</span></a>
        </div>
      </div>
      <div class="col-md-12">
        <div class="btn-contact pAbsolute right">
          <a href="" [routerLink]="" class="btnCorps" [class.activeMovie]="selected === 'visage'"
            (click)="toggleMovie('visage')"><span>Présentation
              Visage</span></a></div>
      </div>
    </div>

    <div class="row outer">
      <div class="btnMuted">
        <img *ngIf="sound" src="assets/images/volume-off-indicator.svg" (click)="toggleSound()">
        <img *ngIf="!sound" src="assets/images/volume-up-indicator.svg" (click)="toggleSound()">
      </div>

      <video class="video" playsinline="playsinline" autoplay="autoplay" [muted]="sound" loop="loop" #videoLpg
        style="width: 100%">
        <source [src]="urlVideo" type="video/mp4">
      </video>
    </div>
  </div>

  <div class="banner-container">
    <div class="banner-head">
      <div class="banner-head-padding banner-head-margin">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12"> <span class="heading-meta"></span>
              <h2 class="pwe-heading animate-box" data-animate-effect="fadeInLeft"></h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="about-section pt-0 pb-0">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 text-center"> <img src="../../assets/images/tete-integral.jpg"
            class="img-fluid mb-30 animate-box" data-animate-effect="fadeInLeft" alt="">
          <h4 class="pwe-about-subheading animate-box" data-animate-effect="fadeInUp">La minceur <strong>100%
              naturelle</strong> - <strong>100% efficace</strong></h4>
        </div>
        <div class="col-md-6 animate-box" data-animate-effect="fadeInLeft">
          <h3 class="pwe-about-heading">La révolution endermologie</h3>
          <h4 class="pwe-about-subheading">Après 30 ans d’expertises et de recherche scientifique, LPG® crée un nouveau
            brevet pour maîtriser tous ces mécanismes naturels en un seul et même traitement!</h4>
          <p>
            A l’opposé de nombreuses techniques disponibles sur le marché, LPG® endermologie propose une
            alternative 100% naturelle : relancer l’activité cellulaire endormie au cœur de notre peau
            pour lutter contre toutes manifestations inesthétiques (rides, peau relâchée, rondeurs
            rebelles, peau d’orange…).
          </p>
          <p>
            La stimulation mécanique des cellules, appelée endermologie®, permet ainsi de relancer leur
            processus de réveil, naturellement et sans douleur
          </p>
          <p>
            Déstocker, affiner, sculpter et raffermir ma silhouette sans douleur, sans risque pour la
            santé, sans agression pour mon corps. Le lipo-modelage Cellu M6 Intégral S agit tout en
            douceur, pour un résultat rapide, de manière confortable, indolore et vraiment efficace.
          </p>

          <p><b>Nous avons des cures adaptées à tous</b><br /><a href="#cures" routerLink="/cures"
              class="linkCure">Cliquez ici pour voir toutes nos cures et options</a></p>
        </div>
      </div>
    </div>
  </div>



  <div class="about-section pt-0 pb-0" style="margin-top: 60px">
    <div class="container-fluid">
      <div class="row">

        <div class="col-md-6 animate-box" data-animate-effect="fadeInLeft">
          <h3 class="pwe-about-heading">Des résultats visible
            dès <strong>6 séances</strong></h3>
          <p><strong>Endermologie® corps</strong> est une technique naturelle et agréable, qui mobilise
            délicatement la peau pour déclencher en profondeur des réponses biologiques. La stimulation
            exercée par la tête de traitement brevetée LPG® relance les échanges circulatoires, active
            le
            déstockage des graisses rebelles, lisse les capitons et raffermit les contours, là où vous
            le
            souhaitez.
          </p>
          <ul>
            <li>Déstockage des graisses résistantes et localisées +70%*</li>
            <li>Lissage de la peau d’orange</li>
            <li>Redensification naturelle du derme (collagène, élastine, acide hyaluronique endogènes)
            </li>
          </ul>
          <p>
            Véritable fitness de la peau, <strong>endermologie® visage</strong> réveille la synthèse
            naturelle des substances essentielles de jeunesse. Des résultats naturels et rapides dans le
            total respect de la peau.
          </p>
          <ul>
            <li>Collagène pour plus de fermeté</li>
            <li>Élastine pour plus de souplesse : +46%</li>
            <li>Acide hyaluronique pour plus de volume et d’hydratation : +80%</li>
          </ul>

          <p>Un bilan personnalisé est obligatoire avant l'utilisation du système
            LPG. Le Bilan personnalisé de 30 min (50€) est offert pour tous les forfaits et abonnements.
            La
            tenue Endermowear est également obligatoire (20€) et est offerte pour tous les abonnements
            et
            forfaits</p>

          <p>Pour toutes demandes d'informations complémentaires et/ou de
            réservations, merci de nous contacter par téléphone ou directement en institut.</p>
        </div>
        <div class="col-md-6 text-center"> <img src="../../assets/images/resultat-corps.jpg"
            class="img-fluid mb-30 animate-box" data-animate-effect="fadeInLeft" alt="">


          <h4 class="pwe-about-subheading animate-box" data-animate-effect="fadeInUp"><a href="#cures"
              routerLink="/cures" class="linkCure">Vous pouvez également consulter notre section
              Cures</a></h4>


        </div>
      </div>
    </div>
  </div>



  <!--

  <header style="margin-top: 68px;">
    <div class="overlay"></div>
    <video playsinline="playsinline" autoplay="autoplay" [muted]="sound" loop="loop" #videoLpg>
      <source [src]="urlVideo" type="video/mp4">
    </video>
    <div class="btnCorps" (click)="toggleMovie('corps')">Présentation Corps</div>
    <div class="btnVisage" (click)="toggleMovie('visage')">Présentation Visage</div>
    <div class="btnMuted">
      <img *ngIf="sound" src="assets/images/volume-off-indicator.svg" (click)="toggleSound()">
      <img *ngIf="!sound" src="assets/images/volume-up-indicator.svg" (click)="toggleSound()">
    </div>

    <div class="containerTop">
      <div class="d-flex h-100 text-center align-items-center">
        <div class="w-100 text-white">
          <h1 class="display-3 bold title">LPG System</h1>
        </div>
      </div>
    </div>
    <div class="containerBottom">Réservation et tarifs ici</div>
  </header>

-->
