import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StorePrestationsPendingService } from '@services/store-prestations-pending.service';
import { DispatchBookingComponent } from '@shared/components/dispatch-action/booking/booking.component';

// ('../dispatch-action/dispatch-action.component');

@Component({
  selector: 'app-basket-booking',
  templateUrl: './basket-booking.component.html',
  styleUrls: ['./basket-booking.component.scss'],
})
export class BasketBookingComponent implements OnInit {
  public count: number = 0;

  constructor(
    private storeManagement: StorePrestationsPendingService,
    public dialog: MatDialog
  ) {
    this.storeManagement.changes.subscribe((e) => {
      if (e !== null) {
        setTimeout(() => {
          this.count = e.length;
        }, 10);
      }
    });
    this.storeManagement.call();
  }

  ngOnInit(): void {}

  openModal() {
    this.dialog.open(DispatchBookingComponent, {
      data: {
        type: 'READ',
      },
      panelClass: 'zalona-modalbox',
    });
  }
}
