import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import IProduct from '@models/product.model';
import { StoreCartService } from '@services/store-cart.service';
import { DetailsProductComponent } from './details-product/details-product.component';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit {
  @Input() product: IProduct;
  public description: string = '';

  constructor(public dialog: MatDialog, private storeCart: StoreCartService) {}

  ngOnInit(): void {
    if (this.product.description) {
      this.description = this.product.description.substring(0, 120) + ' ...';
    }
  }

  openDetails(product: IProduct) {
    this.dialog.open(DetailsProductComponent, {
      data: {
        product: product,
      },
      panelClass: 'zalona-modalbox',
      width: '400px',
    });
  }

  buy(product: IProduct) {
    this.storeCart.store({
      type: 'produit',
      values: product,
    });
  }

  detail(product: IProduct) {}
}
