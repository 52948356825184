<div class="banner-container">
  <div class="banner-img"> <img class="banner-img-width" src="../../assets/images/banner-prestation04.jpg" alt="">
  </div>
  <div class="banner-head">
    <div class="banner-head-padding banner-head-margin">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12"> <span class="heading-meta">Zalona</span>
            <h2 class="pwe-heading animate-box" data-animate-effect="fadeInLeft">Mes cures</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="team-section team bg-pink" style="padding-top: 10px">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 owl-carousel owl-theme">
        <div class="item animate-box" *ngFor="let cure of cures" style="position: relative"
          data-animate-effect="fadeInLeft">


          <div class="ribbon1" *ngIf="cure.state === 'usabled'"><span>En-cours</span></div>
          <div class="ribbon2" *ngIf="cure.state === 'used'"><span>Utilisée</span></div>
          <div class="ribbon3" *ngIf="cure.state === 'closed'"><span>Terminé</span></div>
          <div class="ribbon" *ngIf="cure.state === 'expired'"><span>Expirée</span></div>


          <div class="info">
            <h6>Cure {{cure.product.name}}</h6>
            <p>n°{{cure.id}}</p>

            <div class=" social valign">
              <div class="full-width">
                <div class="btn-contact end" style="margin-bottom: 10px"> <a href="" [routerLink]=""
                    class="btnCancelReservation" (click)="detailsCure(cure)">
                    <mat-icon>line_style</mat-icon> <span>Détail de votre cure</span>
                  </a> </div>


                <div class="btn-contact end" *ngIf="cure.ticket_id !== null"> <a href="" [routerLink]=""
                    class="btnTicket" (click)="getPdf(cure.ticket_id)">
                    <mat-icon>local_offer</mat-icon> <span>Détail de mon ticket de caisse</span>
                  </a> </div>


              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 owl-carousel owl-theme animate-box" data-animate-effect="fadeInLeft"
        *ngIf="cures.length === 0">
        Aucune cure répertoriée
      </div>
    </div>
  </div>
</div>
