import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import IPrestation from '@models/prestation.model';

interface IValuesInject {
  prestation: IPrestation;
}

@Component({
  selector: 'app-details-prestation',
  templateUrl: './details-prestation.component.html',
  styleUrls: ['./details-prestation.component.scss'],
})
export class DetailsPrestationComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public valuesInject: IValuesInject) {}

  ngOnInit(): void {}
}
