<aside id="pwe-aside">
  <!-- Logo -->
  <h1 id="pwe-logo" style="margin-bottom: 30px; position: relative;">
    <a href="#home" routerLink="/home" class="switchToogleMenu" style="position: relative;">Zalôna<i>®</i><span>triel
        sur
        Seine</span>
      <img src="assets/images/spa_france.png" alt="Spa de France"
        style="position: absolute; bottom: -28px; right: -18px; width: 50px; height: 50px;">
    </a>
  </h1>
  <ul style="text-align: left; padding-left: 0px;" class="zalona-flex-center" *ngIf="isConnectedService.isConnected">
    <li class="userConnected">
      <!--<a [routerLink]="" href="null" (click)="openUserMenu()" style="cursor: pointer">-->
      <a [routerLink]="" href="null" (click)="openDialog()" style="cursor: pointer; text-align: center">
        <span class="cadre"><strong>{{getUserConnectedRealtime.user.prenom}}
            {{getUserConnectedRealtime.user.nom}}</strong>

          <br />
          <span style="font-size: 10px; color: rgba(0,0,0,0.5)">Accédez à votre compte</span>
          <!--<mat-icon aria-hidden="false" aria-label="menu is open" class="iconArrow" *ngIf="displayForm">
              arrow_drop_down</mat-icon>
            <mat-icon aria-hidden="false" aria-label="menu is not open" class="iconArrow" *ngIf="!displayForm">
              arrow_drop_up</mat-icon>-->
        </span>
      </a>
    </li>
  </ul>

  <hr style="border: 2px solid rgba(0,0,0,0.08); margin-bottom: 20px" />
  <!-- Menu -->
  <nav id="pwe-main-menu">
    <ul style="text-align: left; padding-left: 20px;">
      <li routerLinkActive="pwe-active"><a href="#home" routerLink="/home" class="switchToogleMenu">
          <mat-icon style="font-size: 16px; position: absolute; top: 0px; left: -27px">home
          </mat-icon> Accueil
        </a></li>
      <li routerLinkActive="pwe-active"><a href="#prestations" routerLink="/prestations" class="switchToogleMenu">
          <mat-icon style="font-size: 16px; position: absolute; top: 0px; left: -27px">spa
          </mat-icon> Notre
          carte de soins
        </a></li>

      <li routerLinkActive="pwe-active"><a href="#cures" routerLink="/cures" class="switchToogleMenu">
          <mat-icon style="font-size: 16px; position: absolute; top: 0px; left: -27px">card_membership</mat-icon> Nos
          cures & abonnements
        </a></li>
      <!--
              <li routerLinkActive="pwe-active"><a href="#produits" routerLink="/produits" class="switchToogleMenu">
          <mat-icon style="font-size: 16px; position: absolute; top: 0px; left: -27px">local_florist
          </mat-icon> Nos produits
          cosmétiques
        </a></li>
      -->

      <li routerLinkActive="pwe-active"><a href="#offres" routerLink="/offres-du-moment" class="switchToogleMenu">
          <mat-icon style="font-size: 16px; position: absolute; top: 0px; left: -27px">local_florist
          </mat-icon> Nos Offres du moment
        </a></li>

      <li routerLinkActive="pwe-active"><a href="#sport" routerLink="/salle-de-remise-en-forme"
          class="switchToogleMenu">
          <mat-icon style="font-size: 16px; position: absolute; top: 0px; left: -27px">fitness_center</mat-icon> Salle
          de remise en forme
        </a>
      </li>

      <li routerLinkActive="pwe-active"><a href="#lpg" routerLink="/lpg-minceur" class="switchToogleMenu">
          <mat-icon style="font-size: 16px; position: absolute; top: 0px; left: -27px">waves</mat-icon> LPG Alliance
          Corps & Visage
        </a>
      </li>
      <!--
          <li routerLinkActive="pwe-active"><a href="#onglerie" routerLink="/onglerie" class="switchToogleMenu">Onglerie</a>
      </li>
          -->

      <!--<li routerLinkActive="pwe-active"><a href="#stripe" routerLink="/stripe" class="switchToogleMenu">Nos cartes
          cadeau</a></li>-->

      <!--
      <li><a style="cursor: pointer" (click)="openGift()" class="switchToogleMenu">
          <mat-icon style="font-size: 16px; position: absolute; top: 0px; left: -27px">card_giftcard
          </mat-icon> Plaisir d'offrir
        </a>
      </li>
      
      -->


      <li routerLinkActive="pwe-active"><a href="#gift" routerLink="/cartes-cadeau" class="switchToogleMenu">
          <mat-icon style="font-size: 16px; position: absolute; top: 0px; left: -27px">card_giftcard
          </mat-icon> Plaisir d'offrir
        </a>
      </li>
      <!--
      <li routerLinkActive="pwe-active"><a href="#galerie" routerLink="/galerie"
          class="switchToogleMenu">Balnéothérapie</a>
      </li>

      <li routerLinkActive="pwe-active"><a href="#galerie" routerLink="/galerie" class="switchToogleMenu">LPG System</a>
      </li>
-->


      <li routerLinkActive="pwe-active"><a href="#galerie" routerLink="/galerie" class="switchToogleMenu">
          <mat-icon style="font-size: 16px; position: absolute; top: 0px; left: -27px">photo_size_select_actual
          </mat-icon> Galerie
          photos
        </a>
      </li>

      <li routerLinkActive="pwe-active"><a href="#contact" routerLink="/contactez-nous" class="switchToogleMenu">
          <mat-icon style="font-size: 16px; position: absolute; top: 0px; left: -27px">email
          </mat-icon> Nous
          contacter
        </a>
      </li>

      <!--
      <li routerLinkActive="pwe-active"><a href="#home" [routerLink]='"/home"' [fragment]="'contact'"
          class="switchToogleMenu">Contact</a></li>
      -->



      <li *ngIf="!isConnectedService.isConnected"><a [routerLink]="" href="null" (click)="openForm()"
          style="cursor: pointer">
          <mat-icon style="font-size: 16px; position: absolute; top: 0px; left: -27px">person
          </mat-icon> Mon compte client
        </a></li>


    </ul>
  </nav>

  <hr style="border: 2px solid rgba(0,0,0,0.08); margin-top: 20px; margin-bottom: 0px" />

  <div *ngIf="displayForm" @openClose class="screenSize">
    <app-zalona-user (sendToCloseMenu)="updateMenuUser($event)"></app-zalona-user>
  </div>



  <!-- Sidebar Footer -->
  <div class="pwe-footer">
    <div class="separator"></div>
    <p style="margin-bottom: 0px"><a class="" href="tel:+33985140056">09 85 14 00 56</a>
      <br /><a href="mailto:contact@zalona.fr">contact@zalona.fr</a></p>
    <div class="separator"></div>
    <p>39 sente des Châtelets<br />78510 Triel-sur-seine</p>
    <div class="social"> <a href="https://www.facebook.com/zalona78/" target="_blank"><i class="ti-facebook"></i></a>

      <!--<a
        href="https://twitter.com/zalona78" target="_blank"><i class="ti-twitter-alt"></i></a>-->
      <a href="https://www.instagram.com/zalona78" target="_blank"><i class="ti-instagram"></i></a>

      <!--<a href="#"><i
          class="ti-pinterest"></i></a>-->
    </div>
  </div>
</aside>
