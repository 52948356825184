import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IsConnectedService } from '@services/is-connected.service';
import { Router } from '@angular/router';

export interface DialogData {
  connected: any;
}

@Component({
  selector: 'app-list-menu',
  templateUrl: './list-menu.component.html',
  styleUrls: ['./list-menu.component.scss'],
})
export class ListMenuComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public isConnectedService: IsConnectedService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  toDisconnect() {
    this.isConnectedService.disconnect();
    this.router.navigate(['/home']);
    this.dialogRef.close();
  }

  historique() {
    this.router.navigate(['/client/historique']);
    this.dialogRef.close();
  }

  cures() {
    this.router.navigate(['/client/cures']);
    this.dialogRef.close();
  }

  achats() {
    this.router.navigate(['/client/achats']);
    this.dialogRef.close();
  }

  compte() {
    this.router.navigate(['/client/compte']);
    this.dialogRef.close();
  }

  // gifts() {
  //   this.router.navigate(['/client/gifts']);
  //   this.dialogRef.close();
  // }
}
