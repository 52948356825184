import { Pipe, PipeTransform } from '@angular/core';
import IPrestation from '@models/prestation.model';

@Pipe({
  name: 'searchFilter',
})
export class SearchFilterPipe implements PipeTransform {
  transform(value: IPrestation[], searchValue: string): IPrestation[] {
    return value.filter((e) => {
      const name = e.name.toLowerCase();
      const search = searchValue.toLowerCase();
      return search === '' ? true : name.indexOf(search) !== -1;
    });
  }
}
