<div class="banner-container" #onTop>
  <div class="banner-img"> <img class="banner-img-width" src="../../assets/images/banner-prestation-cadeaux.jpg"
      alt="Offrez des cartes cadeau">
  </div>
  <div class="banner-head">
    <div class="banner-head-padding banner-head-margin">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12"> <span class="heading-meta">Zalona</span>
            <h2 class="pwe-heading animate-box" data-animate-effect="fadeInLeft" style="font-size: 18px">Personnalisez
              votre carte cadeau à
              offrir et recevez la directement par email !
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<div id="services" class="services clear pt-0 bg-pink">
  <div class="container-fluid"
    style="background: url(../../assets/images/gift-background.png) no-repeat center center; background-size: cover;">
    <div class="row zalona-flex-center">
      <div style="max-width: 600px; width: 100%; padding-top: 30px !important">
        <app-offer></app-offer>
      </div>
    </div>
  </div>
</div>
