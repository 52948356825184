import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { share } from 'rxjs/operators';
import ICookie from '@models/cookie.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { environment } from '@env/environment';
import * as moment from 'moment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IsConnectedService {
  isConnected: boolean = false;
  private onSubject = new Subject<ICookie>();
  public changes = this.onSubject.asObservable().pipe(share());

  constructor(
    private cookieService: CookieService,
    private httpClient: HttpClient
  ) {
    this.isConnected = cookieService.check('zalona');
  }

  public call(): void {
    if (this.isConnected) {
      this.onSubject.next(this.getDetails());
    }
  }

  createCookie(data: ICookie): void {
    const expire = moment.unix(data.expire);
    const current = moment().startOf('day');
    const expireInDays = Math.round(
      moment.duration(expire.diff(current)).asDays()
    );

    this.cookieService.set('zalona', btoa(JSON.stringify(data)), {
      expires: expireInDays,
      sameSite: 'Lax',
    });
    this.isConnected = true;
    this.onSubject.next(data);
  }

  disconnect(): void {
    this.cookieService.delete('zalona');
    this.isConnected = false;
    this.onSubject.next(null);
  }

  getDetails(): ICookie {
    return JSON.parse(atob(this.cookieService.get('zalona')));
  }

  updateInformation() {
    this.httpClient
      .get('api/v1/software/user/informations?token=' + this.getDetails().token)
      .subscribe({
        next: (res: any) => {
          if (res.customer_id && res.customer_id !== null) {
            const details = this.getDetails();
            const data = {
              ...details,
              user: {
                ...details.user,
                id: res.customer_id,
                nom: res.lastname,
                prenom: res.firstname,
                email: res.email,
              },
            };
            this.onSubject.next(data);
            this.createCookie(data);
          }
        },
        error: (err) => {},
        complete: () => {
          // this.spinner.hide();
        },
      });
  }
}
