declare var $: any;

export default () => {
  $('#pwe-hero .flexslider').flexslider({
    animation: 'fade',
    slideshowSpeed: 10000,
    directionNav: true,
    touch: true,
    // mousewheel: true,
    // pauseInvisible: true,
    pauseOnHover: true,
    start: function () {
      setTimeout(function () {
        $('.slider-text').removeClass('animated fadeInUp');
        $('.flex-active-slide')
          .find('.slider-text')
          .addClass('animated fadeInUp');
      }, 500);
    },
    before: function () {
      setTimeout(function () {
        $('.slider-text').removeClass('animated fadeInUp');
        $('.flex-active-slide')
          .find('.slider-text')
          .addClass('animated fadeInUp');
      }, 500);
    },
  });
};
