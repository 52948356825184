import Image from './image.model';

export default class Product {
  constructor(
    public id: string,
    public buying_price: string,
    public category: {
      id: string;
      name: string;
    },
    public description: string,
    public ean: string,
    public image: Image,
    public name: string,
    public reference: string,
    public sell_online: '0' | '1',
    public sell_price: string,
    public stock_quantity: string,
    public stock_state: string,
    public vat_rate: {
      id: string;
      vat_rate: string;
    },
    public provider: {
      id: string;
      business_name: string;
    },
    public basketQty: number
  ) {}
}
