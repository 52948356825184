import { Component, OnInit, Renderer2 } from '@angular/core';
import AnimationsMethod from '@animations';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TempBookingComponent } from '@shared/components/temp-booking/temp-booking.component';
import CarrouselMethod from './caroussel';
import { DataService } from '@services/data.service';
import IPrestation from '@models/prestation.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { createChip } from '@shared/functions';
import { StoreCartService } from '@services/store-cart.service';

@Component({
  selector: 'app-zalona-sport',
  templateUrl: './zalona-sport.component.html',
  styleUrls: ['./zalona-sport.component.scss'],
})
export class ZalonaSportComponent implements OnInit {
  public animationsMain: any = AnimationsMethod;
  public carousselMain: any = CarrouselMethod;

  public listIdAbo: string[] = ['1801363', '1801367', '1801368', '1806107'];
  public fullList: IPrestation[] = new Array<IPrestation>();

  constructor(
    public dialog: MatDialog,
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private storeCart: StoreCartService,
    private renderer: Renderer2
  ) {
    spinner.show();
  }

  ngOnInit(): void {
    this.dataService.getPackages().subscribe((response: any) => {
      this.fullList = response.results
        .map((item) => {
          return new IPrestation(
            item.id,
            item.name,
            item.description,
            item.sell_online,
            item.sell_price,
            item.image,
            item.vat_rate,
            item.category,
            item.duration,
            item.online_use_duration,
            item.online_duration,
            item.allowed_calendar,
            item.allowed_equipment,
            item.allowed_equipments,
            item.online,
            item.online_message,
            item.online_ask,
            item.online_ask_question,
            item.with_step,
            item.last_edit,
            item.created,
            item.month_validity
          );
        })
        .filter((e) => this.listIdAbo.includes(e.id));
      this.spinner.hide();
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.animationsMain();
      setTimeout(() => {
        this.carousselMain();
      }, 500);
    }, 500);
  }

  contactUs() {
    this.dialog.open(TempBookingComponent, {
      panelClass: 'zalona-modalbox',
      width: '400px',
      data: {
        element: '',
        type: 'CURE',
      },
    });
  }

  buy(event: MouseEvent, id: string) {
    const cure: IPrestation = this.fullList.find((e) => e.id === id);
    createChip(event, '.containerBaskBuying', this.renderer).then((e) => {
      this.storeCart.store({
        type: 'cure',
        values: cure,
      });
    });
  }
}
