<div mat-dialog-content mat-dialog-title>
  <div class="row">
    <div class="col-md-12" style="text-align: center"> <span class="heading-meta">votre compte</span>
      <h2 class="pwe-heading" style="font-size: 16px; text-align: center">Bonjour {{data.connected.user.prenom}}</h2>
    </div>
  </div>
</div>
<div mat-dialog-content>

  <p class="menuUser" (click)="historique()">
    <mat-icon>history</mat-icon><br /><span style="margin-left: 10px">Mes réservations</span>
  </p>
  <p class="menuUser" (click)="cures()">
    <mat-icon>loop</mat-icon><br /><span style="margin-left: 10px">Mes cures</span>
  </p>
  <!--<p class="menuUser" (click)="gifts()">
    <mat-icon>card_giftcard</mat-icon> <span style="margin-left: 10px">Mes cartes cadeaux</span>
  </p>-->
  <p class="menuUser" (click)="achats()">
    <mat-icon>card_giftcard</mat-icon><br /><span style="margin-left: 10px">Mes achats</span>
  </p>
  <p class="menuUser" (click)="compte()">
    <mat-icon>account_box</mat-icon><br /><span style="margin-left: 10px">Modifier mon compte</span>
  </p>

  <p style="margin-top: 20px; text-align: center">
    <span class="toDisconnect" (click)="toDisconnect()" style="cursor: pointer">Se déconnecter</span>
  </p>

</div>
