<div class="banner-container" #onTop>
  <div class="banner-img"> <img class="banner-img-width" src="../../assets/images/banner-prestation02.jpg"
      alt="Notre institut en photos">
  </div>
  <div class="banner-head">
    <div class="banner-head-padding banner-head-margin">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12"> <span class="heading-meta">Zalona</span>
            <h2 class="pwe-heading animate-box" data-animate-effect="fadeInLeft">Notre centre de bien-être</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="services-section services pt-90 pb-90 bg-pink" style="padding-top: 30px; padding-bottom: 30px">
  <div class="container-fluid">
    <div class="row">

      <div class="col-md-4 animate-box" data-animate-effect="fadeInLeft" *ngFor="let picture of pictures; let i = index"
        style="height: 369px">
        <div class="item mb-30" style="height: 339px; cursor: pointer" (click)="isShown = !isShown; indexPicture = i">
          <div class="position-re o-hidden containerPic"> <img src="assets/images/institut/{{picture.img}}"
              alt="{{picture.description}}">
          </div>
          <div class="con">
            <h5><a (click)="isShown = !isShown; indexPicture = i"><i class="ti-eye"></i></a></h5> <a
              (click)="isShown = !isShown; indexPicture = i" style="color: white">Voir la
              photo</a>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>


<div [@inOut]="isShown" class="containerPicture zalona-flex-center">


  <div [@inOutChild]="isShown" class="zalona-flex-center" style="width: 100%; height: 100%">

    <h5 class="iconClose"><a (click)="isShown = !isShown"><i class="ti-close"></i></a></h5>

    <h5 class="iconChange next" *ngIf="pictures.length - 1 !== indexPicture"><a (click)="changePicture('NEXT')"><i
          class="ti-arrow-circle-right"></i></a></h5>
    <h5 class="iconChange back" *ngIf="indexPicture !== 0"><a (click)="changePicture('BACK')"><i
          class="ti-arrow-circle-left"></i></a></h5>


    <figure class="wave">
      <img src="assets/images/institut/{{pictures[indexPicture].img}}" alt="{{pictures[indexPicture].description}}" />
      <figcaption style="color: white;font-size: 20px;">{{pictures[indexPicture].description}}</figcaption>
    </figure>
  </div>


</div>

<!--
<div class="price-section pt-60 pb-60 price bg-pink">
  <div class="container-fluid" style="padding-right: 0px; padding-left: 0px">

    <div class="row">
      <div class="col-md-12" style="margin-left: 15px"> <span class="heading-meta">Video</span>
        <h2 class="pwe-heading animate-box" data-animate-effect="fadeInLeft">Tout en détail</h2>
      </div>
    </div>

    <div class="row" style="margin-right: 0px; margin-left: 0px">

      <div class="overlay-video" style="width:100%">
        <video autoplay="" loop="" muted="" style="width: 100%; border-radius: 25px; padding: 20px; ">
          <source src="assets/images/intro.mp4" type="video/mp4" style="box-shadow: 0px 0px 13px 8px rgb(0 0 0 / 10%);">
        </video>
      </div>

    </div>
  </div>
</div>

-->
