import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import IPrestation from '@models/prestation.model';
import IFamilly from '@models/familly.model';
import IProduct from '@models/product.model';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(private httpClient: HttpClient) {}

  getCategoriesPrestations(): Observable<IFamilly[]> {
    return this.httpClient.get<IFamilly[]>(
      'api/v1/software/catalog/categories/services'
    );
  }

  getPrestations(): Observable<IPrestation[]> {
    return this.httpClient.get<IPrestation[]>(
      'api/v1/software/catalog/services'
    );
  }

  getCategoriesPackages(): Observable<IFamilly[]> {
    return this.httpClient.get<IFamilly[]>(
      'api/v1/software/catalog/categories/packages'
    );
  }

  getPackages(): Observable<IPrestation[]> {
    return this.httpClient.get<IPrestation[]>(
      'api/v1/software/catalog/packages'
    );
  }

  getCategoriesProducts(): Observable<IFamilly[]> {
    return this.httpClient.get<IFamilly[]>(
      'api/v1/software/catalog/categories/products '
    );
  }

  getProducts(): Observable<IProduct[]> {
    return this.httpClient.get<IProduct[]>('api/v1/software/catalog/products');
  }
}
