import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import IProduct from '@models/product.model';

interface IValuesInject {
  product: IProduct;
}

@Component({
  selector: 'app-details-product',
  templateUrl: './details-product.component.html',
  styleUrls: ['./details-product.component.scss'],
})
export class DetailsProductComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public valuesInject: IValuesInject) {}

  ngOnInit(): void {}
}
