import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-zalona-footer',
  templateUrl: './zalona-footer.component.html',
  styleUrls: ['./zalona-footer.component.scss'],
})
export class ZalonaFooterComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  cgu() {
    this.router.navigate(['/cgu']);
  }
}
