import { Component, OnInit, ViewChild } from '@angular/core';
// import { Title } from '@angular/platform-browser';
import AnimationsMethod from '@animations';

@Component({
  selector: 'app-zalona-cgu',
  templateUrl: './zalona-cgu.component.html',
  styleUrls: ['./zalona-cgu.component.scss'],
})
export class ZalonaCguComponent implements OnInit {
  public animationsMain: any = AnimationsMethod;

  constructor() {
    // private titleService: Title
    // this.titleService.setTitle('Conditions générales de vente');
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.animationsMain();
    }, 50);
  }

  ngAfterViewInit(): void {}
}
