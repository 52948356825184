import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { share } from 'rxjs/operators';
import IPrestation from '@models/prestation.model';

@Injectable({
  providedIn: 'root',
})
export class StorePrestationsPendingService {
  private key: string = 'prestationsBookingGroup';
  private onSubject = new Subject<IPrestation[]>();
  public changes = this.onSubject.asObservable().pipe(share());

  constructor() {
    this.start();
  }

  ngOnDestroy() {
    this.stop();
  }

  public getStorage() {
    return JSON.parse(localStorage.getItem(this.key));
  }

  public getCurrentPrestations(): IPrestation[] {
    const currentPrestations: IPrestation[] = JSON.parse(
      localStorage.getItem(this.key)
    );
    return currentPrestations === null ? [] : currentPrestations;
  }

  public store(data: IPrestation): void {
    const newValues = [...this.getCurrentPrestations(), data];

    localStorage.setItem(this.key, JSON.stringify(newValues));
    this.onSubject.next(newValues);
  }

  public call() {
    this.onSubject.next(this.getCurrentPrestations());
  }

  public clear() {
    localStorage.removeItem(this.key);
    this.onSubject.next([]);
  }

  public remove(index: number) {
    const newValues = this.getCurrentPrestations().filter((e, i) => {
      return i !== index;
    });
    localStorage.setItem(this.key, JSON.stringify(newValues));
    this.onSubject.next(newValues);
  }

  private start(): void {
    window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  private storageEventListener(event: StorageEvent) {
    if (event.storageArea == localStorage) {
      let v;
      try {
        v = JSON.parse(event.newValue);
      } catch (e) {
        v = event.newValue;
      }
      this.onSubject.next(v);
    }
  }

  private stop(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
    this.onSubject.complete();
  }
}
