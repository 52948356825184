<ngx-spinner size="default" color="#fff" type="ball-beat" [fullScreen]="true" [name]="nameLocalSpinner"></ngx-spinner>


<p mat-dialog-title
  style="font-family: 'Nunito Sans', sans-serif !important; text-transform:uppercase; text-align: center" class="bold">
  Selectionnez un créneau ou ajouter d'autres
  prestations
</p>


<div mat-dialog-content>

  <div class="row"
    style="margin-bottom: 10px; width: 100%; margin-left: 0px; margin-right: 0px; margin-left: 0px; margin-right: 0px"
    *ngIf="prestationsSelected.length > 0">
    <div class="item animate-box" [class.animate-box]="isAnimated" data-animate-effect="fadeInUp"
      *ngFor="let prestation of prestationsSelected; let i = index">
      <div class="row" style="width: 100%">
        <div class="col-sm-8">{{prestation.name}}</div>
        <div class="col-sm-2" *ngIf="prestation.online_duration === '0'">{{prestation.duration}} min</div>
        <div class="col-sm-2" *ngIf="prestation.online_duration !== '0'">{{prestation.online_duration}} min</div>
        <div class="col-sm-2">{{prestation.sell_price |  currency:'EUR':'symbol':'1.2-2':'fr'}}

          <mat-icon aria-hidden="false" class="closedPosition" (click)="removePrestation($event, i)">
            delete</mat-icon>

        </div>
      </div>
    </div>
  </div>

  <div class="btn-contact end" *ngIf="prestationsSelected.length  > 0"> <a href="" [routerLink]=""
      class="btnChooseAnotherPresta" mat-dialog-close>
      <mat-icon>add</mat-icon> <span>Autre prestation</span>
    </a> </div>


  <div class="btn-contact" *ngIf="prestationsSelected.length > 0"> <a href="" [routerLink]="" class="btnOpenCalendar"
      (click)="isCalendarShow=true" id="openModalCalendar">
      <span *ngIf="selectedDate === null" style="text-align: center">Sélectionner une date pour votre réservation</span>
      <span *ngIf="selectedDate !== null" style="text-align: center">{{selectedDate | moment:'dddd D MMM YYYY'}}</span>
    </a> </div>

  <div class="btn-contact" *ngIf="prestationsSelected.length  === 0"> <a href="" [routerLink]=""
      class="btnOpenCalendarDisable" mat-dialog-close>
      <span>Veuillez ajouter au moins une prestation</span>
    </a> </div>



  <div class="zoneDispo zalona-flex-center" *ngIf="!isLoading && prestationsSelected.length > 0"
    style="position:relative">
    <div style="display: flex; justify-content: center; flex-wrap: wrap;">
      <div class="btn-contact" *ngFor="let hour of listOfAvailabilities"><a href="" [routerLink]="" class="slot"
          [class.isSelectTime]="hour === isSelectTime" (click)="selectTime(hour)"><span>{{hour}}</span></a></div>
    </div>
  </div>



  <div class="zoneDispo zalona-flex-center"
    *ngIf="listOfAvailabilities !== undefined && listOfAvailabilities.length === 0">
    <div class="aucunePlace">Malheureusement tous les créneaux de cette journée sont pris
    </div>
  </div>
</div>

<div mat-dialog-actions *ngIf="isSelectTime !== null && selectedDate !== null && prestationsSelected.length > 0"
  class="matAction">
  <div class="row displayDown600" style="height: 70px; width: 100%">
    <div class="col-md-12" style="display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    align-self: center;">
      <div>
        <div class="zoneBeforAddBasker" style="margin-top: 0px; margin-bottom: 4px">
          Sélection <strong>{{selectedDate | moment:'D MMM YY'}}</strong> - <strong>{{isSelectTime}}</strong>
        </div>
        <div class="btn-contact"> <a href="" style="margin-bottom: 0px" [routerLink]="" class="btnChooseAnotherPresta"
            (click)="addShopping($event)">
            <mat-icon>add_shopping_cart</mat-icon> <span>Ajouter au panier</span>
          </a> </div>
      </div>
    </div>
  </div>

  <div class="row displayUp600" style="height: 70px;">
    <div class="col-md-7" style="display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    align-self: center;">
      <div class="zoneBeforAddBasker" style="margin-top: 0px">
        Vous avez sélectionné le créneau
        du <strong>{{selectedDate | moment:'dddd D MMM YYYY'}} à <strong>{{isSelectTime}}</strong></strong>
      </div>
    </div>
    <div class="col-md-5" style="height: 70px; display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    align-self: center;">

      <div class="btn-contact"> <a href="" style="margin-bottom: 0px" [routerLink]="" class="btnChooseAnotherPresta"
          (click)="addShopping($event)">
          <mat-icon>add_shopping_cart</mat-icon> <span>Ajouter au panier</span>
        </a> </div>


    </div>
  </div>



</div>



<div class="overlayCalendar" *ngIf="isCalendarShow" @openClose (click)="closeCalendar($event)">
  <div class="zalona-flex-center" style="height: 100%;" @apear id="overlayCalendar">
    <mat-calendar #calendar class="inline-calendar-card" [(selected)]="selectedDate" [dateFilter]="dateFilter"
      (selectedChange)="selectedChange($event)" [startAt]="startAt" [minDate]="minDate"
      [headerComponent]="customHeader">
    </mat-calendar>
  </div>
</div>
