import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import AnimationsMethod from '@animations';

@Component({
  selector: 'app-zalona-lpg',
  templateUrl: './zalona-lpg.component.html',
  styleUrls: ['./zalona-lpg.component.scss'],
})
export class ZalonaLpgComponent implements OnInit {
  public heightvideo: number = 0;
  public urlVideo: string = 'assets/videos/corps.mp4';
  public sound: boolean = false;
  public animationsMain: any = AnimationsMethod;
  public selected: string = 'corps';
  public titlesArray: any = {
    corps: 'Présentation vidéo pour le corps',
    corvisageps: 'Présentation vidéo pour le visage',
  };

  @ViewChild('videoLpg') videoLpg: ElementRef;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.animationsMain();
    }, 500);
  }

  toggleMovie(movie: string): void {
    this.selected = movie;
    this.urlVideo = 'assets/videos/' + movie + '.mp4';
    this.videoLpg.nativeElement.load();
  }

  toggleSound(): void {
    this.videoLpg.nativeElement.muted = !this.videoLpg.nativeElement.muted;
    this.sound = this.videoLpg.nativeElement.muted;
  }
}
