<h1 mat-dialog-title
  style="font-family: 'Nunito Sans', sans-serif !important; text-transform:uppercase; text-align: center" class="bold">
  {{valuesInject.details.subTitle}}</h1>

<div mat-dialog-content>
  <div class="row"
    style="margin-bottom: 10px; width: 100%; margin-left: 0px; margin-right: 0px; margin-left: 0px; margin-right: 0px">
    <div class="col-md-12"></div>
    <img src="../../../assets/images/slider/{{valuesInject.details.img}}" alt="img"
      style="margin-bottom: 15px; width: 100%" />
    <p [innerHTML]="valuesInject.details.description"></p>
  </div>
</div>
