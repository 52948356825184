import { Component, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IsConnectedService } from '@services/is-connected.service';
import { AnimationLogin } from '@animationsAngular';
import ICookie from '@models/cookie.model';
import { ListMenuComponent } from '@components/zalona-user/list-menu/list-menu.component';
import { ConnexionComponent } from '@components/zalona-user/connexion/connexion.component';
import { UsermenuService } from '@services/usermenu.service';
import { OfferComponent } from '@shared/components/dispatch-action/offer/offer.component';

@Component({
  selector: 'app-zalona-menu',
  templateUrl: './zalona-menu.component.html',
  styleUrls: ['./zalona-menu.component.scss'],
  animations: [AnimationLogin],
})
export class ZalonaMenuComponent implements OnInit {
  public displayForm: boolean = false;
  public getUserConnectedRealtime: ICookie = null;

  constructor(
    public isConnectedService: IsConnectedService,
    public dialog: MatDialog,
    private usermenuService: UsermenuService
  ) {
    this.usermenuService.changes.subscribe((e) => {
      this.displayForm = e.sendToCloseMenu;
    });
    this.usermenuService.init();

    this.isConnectedService.changes.subscribe((e: ICookie) => {
      this.getUserConnectedRealtime = e;
    });
    this.isConnectedService.call();
  }

  ngDoCheck(): void {}

  ngOnInit(): void {}

  openForm(): void {
    this.dialog.open(ConnexionComponent, {
      data: {},
      panelClass: 'zalona-modalbox',
      width: '600px',
    });

    // this.usermenuService.update('sendToCloseMenu', !this.displayForm);
    // this.displayForm = !this.displayForm;
  }

  openDialog() {
    this.dialog.open(ListMenuComponent, {
      data: {
        connected: this.getUserConnected(),
      },
      panelClass: 'zalona-modalbox',
      width: '600px',
    });
  }

  openUserMenu(): void {
    // if (!this.isConnectedService.isConnected) {
    //   this.displayForm = false;
    // } else {
    //   this.displayForm = !this.displayForm;
    // }
  }

  getUserConnected(): ICookie {
    return this.isConnectedService.getDetails();
  }

  updateMenuUser($event) {
    this.displayForm = $event;
  }

  openGift() {
    this.dialog.open(OfferComponent, {
      data: {
        // prestation: prestation,
        type: 'AMOUNT',
      },
      panelClass: 'zalona-modalbox',
      // width: '600px',
      // maxWidth: '100vw',
      // maxHeight: '95vh',
    });
  }
}
