declare var $: any;
export default () => {
  let i = 0;
  $('.animate-box').waypoint(
    function (direction: any) {
      if (direction === 'down' && !$(this.element).hasClass('animated')) {
        i++;
        $(this.element).addClass('item-animate');
        setTimeout(function () {
          $('body .animate-box.item-animate').each(function (k: any) {
            var el = $(this);
            setTimeout(
              function () {
                var effect = el.data('animate-effect');
                if (effect === 'fadeIn') {
                  el.addClass('fadeIn animated');
                } else if (effect === 'fadeInLeft') {
                  el.addClass('fadeInLeft animated');
                } else if (effect === 'fadeInRight') {
                  el.addClass('fadeInRight animated');
                } else {
                  el.addClass('fadeInUp animated');
                }
                el.removeClass('item-animate');
              },
              k * 100,
              'easeInOutExpo'
            );
          });
        }, 50);
      }
    },
    {
      offset: '100%',
    }
  );
};
