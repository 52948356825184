import {
  Component,
  OnInit,
  Input,
  Renderer2,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DispatchBookingComponent } from '@shared/components/dispatch-action/booking/booking.component';
import { OfferComponent } from '@shared/components/dispatch-action/offer/offer.component';
import { TempBookingComponent } from '@shared/components/temp-booking/temp-booking.component';
import { DetailsPrestationComponent } from '@components/zalona-prestations/services-details/details-prestation/details-prestation.component';
import IPrestation from '@models/prestation.model';
import { StoreCartService } from '@services/store-cart.service';
import { AnimationBasket } from '@animationsAngular';
import { createChip, manageSlug } from '@shared/functions';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
  animations: [AnimationBasket],
})
export class ServicesComponent implements OnInit {
  @Input() prestation: IPrestation;
  @Input() detailsIdUrl: string;
  @Output() detailsIdUrlChange: EventEmitter<string> =
    new EventEmitter<string>();
  public description: string = '';
  private slugs: any[] = null;
  constructor(
    public dialog: MatDialog,
    private storeCart: StoreCartService,
    private renderer: Renderer2,
    private location: Location
  ) {
    this.slugs = manageSlug(this.location.path(), 'cures');
  }

  ngOnInit(): void {
    if (this.prestation.description) {
      this.description = this.prestation.description.substring(0, 120) + ' ...';
    }
  }

  ngOnDestroy() {
    this.detailsIdUrlChange.emit(null);
  }

  ngAfterViewInit(): void {
    if (
      this.detailsIdUrl !== null &&
      this.detailsIdUrl === this.prestation.id
    ) {
      if (this.slugs[1] && this.slugs[1].type === 'details') {
        this.openDetails(this.prestation);
      }

      if (this.slugs[1] && this.slugs[1].type === 'offrir') {
        this.offer(this.prestation);
      }
    }
  }

  buy(event: MouseEvent, cure: IPrestation) {
    createChip(event, '.containerBaskBuying', this.renderer).then((e) => {
      this.storeCart.store({
        type: 'cure',
        values: cure,
      });
    });
  }

  contactUs(prestation: IPrestation) {
    this.dialog.open(TempBookingComponent, {
      panelClass: 'zalona-modalbox',
      width: '400px',
      data: {
        element: prestation,
        type: 'CURE',
      },
    });
  }

  manageUrl(prestation: IPrestation, active: boolean, type: string) {
    if (this.slugs !== null && this.slugs.length > 0) {
      const firstFragment = this.slugs[0].path;
      if (active === true) {
        const id = prestation.id;
        const name = prestation.name.trim().replace(/\s/g, '-');
        this.location.replaceState(
          `cures/${firstFragment}/${type}-${id}-${name}`
        );
      } else {
        this.location.replaceState(`cures/${firstFragment}`);
      }
    }
  }

  openDetails(prestation: IPrestation) {
    this.manageUrl(prestation, true, 'details');
    this.dialog
      .open(DetailsPrestationComponent, {
        data: {
          prestation: prestation,
        },
        panelClass: 'zalona-modalbox',
        width: '600px',
      })
      .afterClosed()
      .subscribe(() => {
        this.manageUrl(null, false, '');
      });
  }

  offer(prestation: IPrestation) {
    this.manageUrl(prestation, true, 'offrir');
    this.dialog
      .open(OfferComponent, {
        data: {
          prestation: prestation,
          type: 'SERVICE',
        },
        panelClass: 'zalona-modalbox',
        // width: '600px',
        // maxWidth: '100vw',
        // maxHeight: '100%',
      })
      .afterClosed()
      .subscribe(() => {
        this.manageUrl(null, false, '');
      });
  }
}
