import { Component, OnInit, Renderer2 } from '@angular/core';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import IFamilly from '@models/familly.model';
import IPrestation from '@models/prestation.model';
import AnimationsMethod from '@animations';
import { DataService } from '@services/data.service';
import { manageSlug, createChip } from '@shared/functions';
import { TempBookingComponent } from '@shared/components/temp-booking/temp-booking.component';
import { DispatchBookingComponent } from '@shared/components/dispatch-action/booking/booking.component';
import {
  animate,
  animateChild,
  group,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { OfferComponent } from '@shared/components/dispatch-action/offer/offer.component';
import { MatDialog } from '@angular/material/dialog';

// import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-zalona-offres',
  templateUrl: './zalona-offres.component.html',
  styleUrls: ['./zalona-offres.component.scss'],
  animations: [
    trigger('inOut', [
      state(
        'false',
        style({ opacity: 0, backdropFilter: 'blur(0px)', display: 'none' })
      ),
      state('true', style({ opacity: 1, backdropFilter: 'blur(5px)' })),
      transition('false=>true', [
        style({ display: 'block' }),
        group([
          query('@inOutChild', animateChild({ delay: 500 })),
          animate('500ms'),
        ]),
      ]),
      transition('true=>false', [
        group([query('@inOutChild', animateChild()), animate('200ms')]),
      ]),
    ]),
    trigger('inOutChild', [
      state('false', style({ opacity: 0, filter: 'blur(5px)' })),
      state('true', style({ opacity: 1, filter: 'blur(0px)' })),
      transition('false=>true', [animate('500ms')]),
      transition('true=>false', [animate('200ms')]),
    ]),
  ],
})
export class ZalonaOffresComponent implements OnInit {
  public animationsMain: any = AnimationsMethod;

  public isLoading: boolean = false;
  public famillies: IFamilly[] = new Array<IFamilly>();
  public prestation: IPrestation[] = new Array<IPrestation>();
  public templateFamillies: IFamilly[] = [];
  public templatePrestations: IPrestation[] = [];
  public selectedParent: string[] = [];
  public search: string = '';
  public famillyIdUrl = null;
  public detailsIdUrl = null;
  public isInitDataLoading: boolean = false;
  public dispatchBack: boolean = false;
  public selectPrestation: IPrestation = null;
  private slugs: any[] = null;

  constructor(
    private spinner: NgxSpinnerService,
    private dataService: DataService,
    private location: Location,
    public dialog: MatDialog,
    private renderer: Renderer2
  ) {
    spinner.show();
    this.slugs = manageSlug(this.location.path(), 'prestations');
    this.famillyIdUrl =
      this.slugs !== null && this.slugs.length > 0 ? this.slugs[0].id : null;
    this.detailsIdUrl =
      this.slugs !== null && this.slugs.length > 1 ? this.slugs[1].id : null;
  }

  ngOnInit(): void {
    this.dataService.getCategoriesPrestations().subscribe((response: any) => {
      this.famillies = response.results
        .filter((item) => item.online === 1)
        .map((item) => {
          return new IFamilly(
            item.id,
            item.name,
            item.type,
            item.show_order,
            item.online,
            item.color,
            item.image,
            item.created,
            item.last_edit,
            item.parent
          );
        });

      this.dataService.getPrestations().subscribe((response: any) => {
        this.prestation = response.results
          .filter((item) => item.online === 1 || item.online === 0)
          .map((item) => {
            return new IPrestation(
              item.id,
              item.name,
              item.description,
              item.sell_online,
              item.sell_price,
              item.image,
              item.vat_rate,
              item.category,
              item.duration,
              item.online_use_duration,
              item.online_duration,
              item.allowed_calendar,
              item.allowed_equipment,
              item.allowed_equipments,
              item.online,
              item.online_message,
              item.online_ask,
              item.online_ask_question,
              item.with_step,
              item.last_edit,
              item.created
            );
          });
        this.spinner.hide();
        this.isInitDataLoading = true;

        this.selectedParent.push('327677');
        this.findParentCategory('327677');

        this.getFamillies('327677', false);
      });
    });
  }

  findParentCategory(id): void {
    const category = this.famillies.find((e) => e.id === id);
    if (category !== undefined && category.parent && category.parent !== null) {
      this.selectedParent.push(category.parent.id);
      this.findParentCategory(category.parent.id);
    } else {
      this.selectedParent.reverse();
    }
  }

  ngAfterViewInit(): void {}

  ngDoCheck(): void {}

  ngOnDestroy() {
    this.animationsMain = null;
  }

  clickFamilly(parent: IFamilly = null): void {
    if (parent === null) {
      this.location.replaceState(`prestations`);

      this.getFamillies(null, true);
    } else {
      const id = parent.id;
      const name = parent.name.trim().replace(/\s/g, '-');
      this.location.replaceState(`prestations/categorie-${id}-${name}`);
      this.getFamillies(parent.id, true);
    }
  }

  getFamillies(parent: string = null, isClick: boolean): void {
    if (parent === null) {
      this.selectedParent = [];
    } else {
      if (this.selectedParent.find((e) => e === parent) !== undefined) {
        const newArray = [...this.selectedParent];
        const index = this.selectedParent.findIndex((e) => e === parent);
        newArray.length = index + 1;
        this.selectedParent = newArray;
      } else {
        this.selectedParent = [...this.selectedParent, parent];
      }
    }

    this.templateFamillies = this.famillies
      .filter((e) => {
        return parent === null ? !e.parent : e.parent && e.parent.id === parent;
      })
      .sort((a, b) => {
        return a.show_order - b.show_order;
      });

    this.templatePrestations = this.prestation.filter(
      (e) => e.category.id === parent
    );
    setTimeout(() => {
      const element = document.querySelector('body');
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      this.animationsMain();
      setTimeout(() => {
        // console.log('templatePrestations', this.templatePrestations);
      }, 50);
    }, 50);
  }

  breadcrumbFamillies(): IFamilly[] {
    if (this.isInitDataLoading) {
      return this.selectedParent.map((e) => {
        return this.famillies.find((f) => f.id === e);
      });
    } else {
      return [];
    }
  }

  dispatch(prestation: IPrestation) {
    this.dispatchBack = true;
    this.selectPrestation = prestation;
  }

  disabledPopup(event: any) {
    console.log('event', event);

    if (!event.target.classList.contains('notClose')) {
      this.dispatchBack = false;
      this.selectPrestation = null;
    }
  }

  reservation(event, prestation: IPrestation) {
    this.dispatchBack = false;
    this.selectPrestation = null;
    createChip(event, '.containerBaskBooking', this.renderer).then((e) => {
      this.dialog.open(DispatchBookingComponent, {
        data: {
          prestation: prestation,
          type: 'BOOKING',
        },
        panelClass: 'zalona-modalbox',
        width: '600px',
      });
    });
  }

  contactUs(prestation: IPrestation) {
    this.dispatchBack = false;
    this.selectPrestation = null;
    this.dialog.open(TempBookingComponent, {
      panelClass: 'zalona-modalbox',
      width: '400px',
      data: {
        element: prestation,
        type: 'CURE',
      },
    });
  }

  offer(prestation: IPrestation) {
    this.dispatchBack = false;
    this.selectPrestation = null;
    this.manageUrl(prestation, true, 'offrir');
    this.dialog
      .open(OfferComponent, {
        data: {
          prestation: prestation,
          type: 'SERVICE',
        },
        panelClass: 'zalona-modalbox',
      })
      .afterClosed()
      .subscribe(() => {
        this.manageUrl(null, false, '');
      });
  }

  manageUrl(prestation: IPrestation, active: boolean, type: string) {
    if (this.slugs !== null && this.slugs.length > 0) {
      const firstFragment = this.slugs[0].path;
      if (active === true) {
        const id = prestation.id;
        const name = prestation.name.trim().replace(/\s/g, '-');
        this.location.replaceState(
          `prestations/${firstFragment}/${type}-${id}-${name}`
        );
      } else {
        this.location.replaceState(`prestations/${firstFragment}`);
      }
    }
  }
}
