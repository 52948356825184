import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import IFamilly from '@models/familly.model';
import IProduct from '@models/product.model';
import AnimationsMethod from '@animations';
import { environment } from '@env/environment';
import { DataService } from '@services/data.service';
import { ScriptService } from '@services/extern-script.service';
// import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-zalona-products',
  templateUrl: './zalona-products.component.html',
  styleUrls: ['./zalona-products.component.scss'],
})
export class ZalonaProductsComponent implements OnInit {
  public animationsMain: any = AnimationsMethod;
  public isLoading: boolean = false;

  public famillies: IFamilly[] = new Array<IFamilly>();
  public products: IProduct[] = new Array<IProduct>();

  public templateFamillies: IFamilly[] = [];
  public templateProducts: IProduct[] = [];

  public selectedParent: string[] = [];
  public search: string = '';

  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService // private titleService: Title
  ) {
    spinner.show();
    // this.titleService.setTitle('Notre gamme de produits');
  }

  ngOnInit(): void {
    this.dataService.getCategoriesProducts().subscribe((response: any) => {
      this.famillies = response.results
        .map((item) => {
          return new IFamilly(
            item.id,
            item.name,
            item.type,
            item.show_order,
            item.online,
            item.color,
            item.image,
            item.created,
            item.last_edit,
            item.parent
          );
        })
        .filter((e) => e.online === 1);
      this.dataService.getProducts().subscribe((response: any) => {
        this.products = response.results.map((item) => {
          return new IProduct(
            item.id,
            item.buying_price,
            item.category,
            item.description,
            item.ean,
            item.image,
            item.name,
            item.reference,
            item.sell_online,
            item.sell_price,
            item.stock_quantity,
            item.stock_state,
            item.vat_rate,
            item.provider,
            0
          );
        });
        this.spinner.hide();
        this.getFamillies();
      });
    });
  }

  ngAfterViewInit(): void {}

  ngDoCheck(): void {}

  ngOnDestroy() {
    this.animationsMain = null;
  }

  getFamillies(parent: string = null): void {
    if (parent === null) {
      this.selectedParent = [];
    } else {
      if (this.selectedParent.find((e) => e === parent) !== undefined) {
        const newArray = [...this.selectedParent];
        const index = this.selectedParent.findIndex((e) => e === parent);
        newArray.length = index + 1;
        this.selectedParent = newArray;
      } else {
        this.selectedParent = [...this.selectedParent, parent];
      }
    }

    this.templateFamillies = this.famillies
      .filter((e) => {
        return parent === null ? !e.parent : e.parent && e.parent.id === parent;
      })
      .sort((a, b) => {
        return a.show_order - b.show_order;
      });

    this.templateProducts = this.products.filter(
      (e) => e.category.id === parent
    );
    setTimeout(() => {
      const element = document.querySelector('body');
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      this.animationsMain();
    }, 50);
  }

  breadcrumbFamillies(): IFamilly[] {
    return this.selectedParent.map((e) => {
      return this.famillies.find((f) => f.id === e);
    });
  }
}
