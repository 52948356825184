import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import IFamilly from '@models/familly.model';
import IPrestation from '@models/prestation.model';
import AnimationsMethod from '@animations';
import { DataService } from '@services/data.service';
import { manageSlug } from '@shared/functions';

@Component({
  selector: 'app-zalona-cures',
  templateUrl: './zalona-cures.component.html',
  styleUrls: ['./zalona-cures.component.scss'],
})
export class ZalonaCuresComponent implements OnInit {
  public animationsMain: any = AnimationsMethod;
  public isLoading: boolean = false;
  public famillies: IFamilly[] = new Array<IFamilly>();
  public prestation: IPrestation[] = new Array<IPrestation>();
  public templateFamillies: IFamilly[] = [];
  public templatePrestations: IPrestation[] = [];
  public selectedParent: string[] = [];
  public search: string = '';
  public famillyIdUrl = null;
  public detailsIdUrl = null;
  public isInitDataLoading: boolean = false;

  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private location: Location
  ) {
    spinner.show();
    const slugs = manageSlug(this.location.path(), 'cures');
    this.famillyIdUrl = slugs !== null && slugs.length > 0 ? slugs[0].id : null;
    this.detailsIdUrl = slugs !== null && slugs.length > 1 ? slugs[1].id : null;
  }

  ngOnInit(): void {
    this.dataService.getCategoriesPackages().subscribe((response: any) => {
      this.famillies = response.results.map((item) => {
        return new IFamilly(
          item.id,
          item.name,
          item.type,
          item.show_order,
          item.online,
          item.color,
          item.image,
          item.created,
          item.last_edit,
          item.parent
        );
      });
      this.dataService.getPackages().subscribe((response: any) => {
        this.prestation = response.results.map((item) => {
          return new IPrestation(
            item.id,
            item.name,
            item.description,
            item.sell_online,
            item.sell_price,
            item.image,
            item.vat_rate,
            item.category,
            item.duration,
            item.online_use_duration,
            item.online_duration,
            item.allowed_calendar,
            item.allowed_equipment,
            item.allowed_equipments,
            item.online,
            item.online_message,
            item.online_ask,
            item.online_ask_question,
            item.with_step,
            item.last_edit,
            item.created,
            item.month_validity
          );
        });
        this.spinner.hide();
        this.isInitDataLoading = true;
        if (this.famillyIdUrl !== null) {
          this.selectedParent.push(this.famillyIdUrl);
          this.findParentCategory(this.famillyIdUrl);
        }
        this.getFamillies(this.famillyIdUrl, false);
      });
    });
  }

  findParentCategory(id): void {
    const category = this.famillies.find((e) => e.id === id);
    if (category !== undefined && category.parent && category.parent !== null) {
      this.selectedParent.push(category.parent.id);
      this.findParentCategory(category.parent.id);
    } else {
      this.selectedParent.reverse();
    }
  }

  ngAfterViewInit(): void {}

  ngDoCheck(): void {}

  ngOnDestroy() {
    this.animationsMain = null;
  }

  clickFamilly(parent: IFamilly = null): void {
    if (parent === null) {
      this.location.replaceState(`cures`);
      this.getFamillies(null, true);
    } else {
      const id = parent.id;
      const name = parent.name.trim().replace(/\s/g, '-');
      // this.location.go(`prestations/categorie-${id}-${name}`);
      this.location.replaceState(`cures/categorie-${id}-${name}`);
      this.getFamillies(parent.id, true);
    }
  }

  getFamillies(parent: string = null, isClick: boolean): void {
    if (parent === null) {
      this.selectedParent = [];
    } else {
      if (this.selectedParent.find((e) => e === parent) !== undefined) {
        const newArray = [...this.selectedParent];
        const index = this.selectedParent.findIndex((e) => e === parent);
        newArray.length = index + 1;
        this.selectedParent = newArray;
      } else {
        this.selectedParent = [...this.selectedParent, parent];
      }
    }

    this.templateFamillies = this.famillies
      .filter((e) => {
        return parent === null ? !e.parent : e.parent && e.parent.id === parent;
      })
      .sort((a, b) => {
        return a.show_order - b.show_order;
      });

    this.templatePrestations = this.prestation.filter(
      (e) => e.category.id === parent
    );
    setTimeout(() => {
      const element = document.querySelector('body');
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      this.animationsMain();
    }, 50);
  }

  breadcrumbFamillies(): IFamilly[] {
    if (this.isInitDataLoading) {
      return this.selectedParent.map((e) => {
        return this.famillies.find((f) => f.id === e);
      });
    } else {
      return [];
    }
  }
}
