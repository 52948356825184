<div id="pwe-footer2">
  <div class="pwe-narrow-content">
    <div class="row">
      <div class="col-md-4 animate-box" data-animate-effect="fadeInLeft">
        <h2>
          <a href="index.html">Zalôna<i>®</i><span>triel sur Seine</span></a>
        </h2>
        <div class="social"> <a href="https://www.facebook.com/zalona78/" target="_blank"><i
              class="ti-facebook"></i></a>
          <a href="https://www.instagram.com/zalona78"><i class="ti-instagram"></i></a> </div>
      </div>
      <div class="col-md-4 animate-box" data-animate-effect="fadeInLeft">
        <h6>Téléphone</h6>
        <p><a class="linkMail" href="tel:+33985140056">09 85 14 00 56</a></p>
        <h6>Nous contacter</h6>
        <p><a class="linkMail" href="mailto:contact@zalona.fr">contact@zalona.fr</a></p>
      </div>
      <div class="col-md-4 animate-box" data-animate-effect="fadeInLeft">
        <h6>Adresse</h6>
        <p>39 sente des châtelets
          <br />78510 Triel-sur-Seine</p>
        <p (click)="cgu()" style="cursor: pointer">Mentions légales
        </p>
        <p class="copyright">&copy; 2022. Tous droits réservés.</p>
      </div>
    </div>
  </div>
</div>
