<div class="banner-container">
  <div class="banner-img"> <img class="banner-img-width" src="../../assets/images/banner-prestation04.jpg" alt="">
  </div>
  <div class="banner-head">
    <div class="banner-head-padding banner-head-margin">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12"> <span class="heading-meta">Zalona</span>
            <h2 class="pwe-heading animate-box" data-animate-effect="fadeInLeft">Mes achats</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="team-section team bg-pink" style="padding-top: 10px">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 owl-carousel owl-theme">
        <div class="item animate-box" *ngFor="let ticket of tickets" style="position: relative"
          data-animate-effect="fadeInLeft">

          <div class="ribbon1"><span>{{ticket.total_ttc| currency:'EUR':'symbol':'1.2-2':'fr'}}</span></div>

          <div class="info">
            <h6><u>Ticket n°{{ticket.reference}}</u></h6>
            <p>Le {{ticket.date | date:'mediumDate'}} à
              {{ticket.date | date:'HH:mm'}}</p>

            <div class=" social valign">
              <div class="full-width">
                <!--<div class="btn-contact end"> <a href="" [routerLink]="" class="btnCancelReservation"
                    (click)="detailsTicket(ticket)">
                    <mat-icon>line_style</mat-icon> <span>Détail de votre ticket</span>
                  </a> </div>-->

                <div class="btn-contact end" *ngIf="ticket.id !== null"> <a href="" [routerLink]="" class="btnTicket"
                    (click)="getPdf(ticket.id)">
                    <mat-icon>local_offer</mat-icon> <span>Détail de mon ticket de caisse</span>
                  </a> </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 owl-carousel owl-theme animate-box" data-animate-effect="fadeInLeft"
        *ngIf="tickets.length === 0">
        Aucun ticket répertorié
      </div>
    </div>
  </div>
</div>
