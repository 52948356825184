<aside id="pwe-hero" class="js-fullheight">
  <div class="flexslider js-fullheight">
    <ul class="slides">

      <ng-container *ngFor="let dt of data; index as i; first as isFirst">
        <li [ngStyle]="{'background-image': 'url(../../assets/images/slider/' + adaptImg(getScreenWidth, dt)}"
          *ngIf="dt.img" title="{{dt.alt}}">
          <div class="overlay"></div>



          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6 js-fullheight slider-text">
                <div class="slider-text-inner" *ngIf="dt.title !== null">
                  <div class="desc">
                    <h6>{{dt.title}}</h6>
                    <h1>{{dt.subTitle}}</h1>
                    <p [innerHTML]="contactFn(dt.description)"></p>
                    <div class="btn-contact" *ngIf="displayBtn(dt.description)" style="cursor: pointer"><a href=""
                        [routerLink]="" (click)="openDetails(i)" class="btnSubContact"
                        style="cursor: pointer"><span>Voir plus...</span></a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>

      </ng-container>

    </ul>
  </div>
</aside>



<div class="banner-container">
  <div class="banner-head">
    <div class="banner-head-padding banner-head-margin">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12"> <span class="heading-meta"></span>
            <h2 class="pwe-heading animate-box" data-animate-effect="fadeInLeft"></h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="about-section pt-0 pb-0">
  <div class="container-fluid">
    <div class="row">

      <div class="col-md-6 animate-box" data-animate-effect="fadeInLeft">
        <h3 class="pwe-about-heading">Zalôna® est Label <strong>SPA de France</strong></h3>

        <h4 class="pwe-about-subheading">Protéger nos ressources naturelles et ainsi contribuer au développement durable
        </h4>

        <p>Les centres Spa bénéficiant du label Spas de France s’efforcent de faire le choix de protéger nos ressources
          naturelles et ainsi contribuer au développement durable. Le respect de la santé, c’est-à dire considérer la
          santé comme une ressource à préserver par des comportements responsables, en visant le développement durable
          de la santé.</p>

        <p>Ils s’engagent à promouvoir de bonnes habitudes de vie afin d’améliorer la santé globale et durable de leurs
          clients.</p>

        <p>Le bien-être durable dans la vie, tant individuel que familial et social, (comme le préconise la philosophie
          indienne de l’Ayurveda) est une valeur fondamentale de l’univers des centres Spa. L’homme est replacé dans sa
          dimension à la fois physique et spirituelle.</p>

        <p>Le retour aux sources de la vie et notamment la recherche de l’harmonie des cinq éléments de la vie (la
          terre,
          le bois, le feu, le métal et l’eau) qui représentent les énergies essentielles, est une valeur primordiale
          dont la préservation doit être une préoccupation de tous…</p>

        <p>
          <strong>Zalôna est labellisé Spa de France 3 Lotus depuis Aôut 2024 et répond donc aux 20 critères de
            la charte Qualité du label Spas de France, attestant ainsi de l’excellence de ses installations et
            de ses prestations</strong>
        </p>

      </div>
      <div class="col-md-6 text-center"> <img src="assets/images/spa_france_exp.png" class="img-fluid mb-30 animate-box"
          data-animate-effect="fadeInLeft" alt="">
        <h4 class="pwe-about-subheading animate-box" data-animate-effect="fadeInUp"></h4>
      </div>
    </div>
  </div>
</div>

<div class="about-section pt-0 pb-0" style="margin-top: 60px">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 text-center"> <img src="../../assets/images/lpg2.jpg" class="img-fluid mb-30 animate-box"
          data-animate-effect="fadeInLeft" alt="LPG System">
        <h4 class="pwe-about-subheading animate-box" data-animate-effect="fadeInUp">Le CelluM6 Alliance dernière
          génération chez Zalôna!</h4>
      </div>
      <div class="col-md-6 animate-box" data-animate-effect="fadeInLeft">
        <h3 class="pwe-about-heading">La révolution endermologie</h3>
        <h4 class="pwe-about-subheading">Après 30 ans d’expertises et de recherche scientifique, LPG® crée un nouveau
          brevet pour maîtriser tous ces mécanismes naturels en un seul et même traitement!</h4>
        <p>Des réglages ultra précis grâce au dispositif Alliance Skin Identity (ASI) intégré qui permet au
          professionnel d’adapter parfaitement l’intensité de la stimulation selon le type de tissu traité (souple,
          dense, fibreux) pour un soin 100% personnalisé et efficace.</p>
        <p>Résultats visibles dès 3 séances !</p>
        <p>Grâce à la nouvelle tête de traitement brevetée Alliance, endermologie® permet de cibler et d’affiner les
          zones rebelles à l’exercice et à l’hygiène alimentaire (bras, dos, ventre, taille, cuisses..) tout en
          s’adaptant précisément aux besoins de chaque peau.</p>

        <p><b>Nous avons des cures adaptées à tous</b><br /><a href="#cures" routerLink="/cures"
            class="linkCure">Cliquez ici pour voir toutes nos cures et options</a></p>
      </div>
    </div>
  </div>
</div>


<div class="about-section pt-0 pb-0" style="margin-top: 60px">
  <div class="container-fluid">
    <div class="row">

      <div class="col-md-6 animate-box" data-animate-effect="fadeInLeft">
        <h3 class="pwe-about-heading">Les Happycuriennes®</h3>

        <h4 class="pwe-about-subheading">Une Écoute Attentionnée de votre peau</h4>

        <p>Créé par l’éco-cosmétologue Carole Marchais, notre Coeur de formule « Happybiome », visionnaire, doit son
          efficacité à une triple action épiderme + microbiote cutané + émotions. Écoutons la peau, ses différents
          stress, son équilibre ; son écosystème bactérien, et son état émotionnel…</p>

        <p>La peau est un organe complexe aux rôles multiples : immunité, émonctoire, régulation thermique, etc. Ses 3
          couches principales*, bien connues des scientifiques ne sont pourtant que la partie visible d’un
          supra-organisme étonnant peau-émotions-microbiome, nommé Holobiome. On vous explique tout. * Épiderme, derme,
          hypoderme?</p>
        <p>La peau, miroir de nos émotions, est très sensible à nos « états d’être » émotionnels conscients et
          inconscients, grâce à une connexion étroite avec le cerveau.

          Cette étroite relation, connue de la science, est curieusement peu exploitée en cosmétique. Un stress
          psychologique est pourtant souvent à l’origine de problèmes cutanés (dermatite atopique, acné…).</p>
        <p><a href="#produits" routerLink="/produits" class="linkCure">Découvrez nos produits de la gamme Les
            Happycuriennes® en
            cliquant ici</a> ou <a href="#prestations" routerLink="/prestations" class="linkCure">Découvrez nos soins de
            la gamme Les Happycuriennes® en
            cliquant ici</a></p>
      </div>
      <div class="col-md-6 text-center"> <img src="../../assets/images/leshappycuriennes_homepage.jpg"
          class="img-fluid mb-30 animate-box" data-animate-effect="fadeInLeft" alt="">
        <h4 class="pwe-about-subheading animate-box" data-animate-effect="fadeInUp"></h4>
      </div>
    </div>
  </div>
</div>


<!--
<div id="portfolio" class="portfolio pt-90 pb-60 bg-pink">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 mb-30"> <span class="heading-meta">L'institut</span>
        <h2 class="pwe-heading mb-30 animate-box" data-animate-effect="fadeInLeft">Notre centre de bien-être</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="item animate-box" data-animate-effect="fadeInLeft">
          <div class="portfolio-img">
            <a href="portfolio-page.html"><img src="../../assets/images/520x400.jpg" alt=""></a>
          </div>
          <div class="content">
            <h5><a href="portfolio-page.html">Eleanor <b>&</b> Stefano</a></h5>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="item animate-box" data-animate-effect="fadeInLeft">
          <div class="portfolio-img">
            <a href="portfolio-page.html"><img src="../../assets/images/520x400.jpg" alt=""></a>
          </div>
          <div class="content">
            <h5><a href="portfolio-page.html">Freida <b>&</b> Pablo</a></h5>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="item animate-box" data-animate-effect="fadeInLeft">
          <div class="portfolio-img">
            <a href="portfolio-page.html"><img src="../../assets/images/520x400.jpg" alt=""></a>
          </div>
          <div class="content">
            <h5><a href="portfolio-page.html">Olivia <b>&</b> Enrico</a></h5>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="item animate-box" data-animate-effect="fadeInLeft">
          <div class="portfolio-img">
            <a href="portfolio-page.html"><img src="../../assets/images/520x400.jpg" alt=""></a>
          </div>
          <div class="content">
            <h5><a href="portfolio-page.html">Maddie <b>&</b> Daniel</a></h5>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="item animate-box" data-animate-effect="fadeInLeft">
          <div class="portfolio-img"> <img src="../../assets/images/520x400.jpg" alt=""> </div>
          <div class="content">
            <h5><a href="portfolio-page.html">Vanessa <b>&</b> Chris</a></h5>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="item animate-box" data-animate-effect="fadeInLeft">
          <div class="portfolio-img">
            <a href="portfolio-page.html"><img src="../../assets/images/520x400.jpg" alt=""></a>
          </div>
          <div class="content">
            <h5><a href="portfolio-page.html">Tammy <b>&</b> Matthew</a></h5>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
-->

<div class="clients-section clients">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 owl-carousel owl-theme">
        <div class="client-logo">
          <a href="#"><img src="../../assets/images/clients/7.jpg" alt=""></a>
        </div>
        <div class="client-logo">
          <a href="#"><img src="../../assets/images/clients/2.jpg" alt=""></a>
        </div>
        <div class="client-logo">
          <a href="#"><img src="../../assets/images/clients/3.jpg" alt=""></a>
        </div>
        <div class="client-logo">
          <a href="#"><img src="../../assets/images/clients/4.jpg" alt=""></a>
        </div>
        <div class="client-logo">
          <a href="#"><img src="../../assets/images/clients/8.jpg" alt=""></a>
        </div>
        <div class="client-logo">
          <a href="#"><img src="../../assets/images/clients/6.jpg" alt=""></a>
        </div>
        <div class="client-logo">
          <a href="#"><img src="../../assets/images/clients/9.jpg" alt=""></a>
        </div>
      </div>
    </div>
  </div>
</div>

<app-zalona-footer></app-zalona-footer>
