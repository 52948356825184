<div class="banner-container" #onTop>
  <div class="banner-img"> <img class="banner-img-width" src="../../assets/images/banner-prestation-fitness.jpg"
      alt="remise en forme & fitness">
  </div>
  <div class="banner-head">
    <div class="banner-head-padding banner-head-margin">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12"> <span class="heading-meta">Zalona</span>
            <h2 class="pwe-heading animate-box" data-animate-effect="fadeInLeft">Notre salle de remise en forme</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  style="background: url(../../assets/images/tytax-element-logo2.jpg) fixed bottom center; background-size: cover; height: 200px; width: 100%">
</div>

<div id="services" class="services clear pt-90 bg-pink">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 mb-30 animate-box fadeInLeft animated" data-animate-effect="fadeInLeft">
        <h3 class="pwe-about-heading">35m² privatisé rien que pour vous</h3>
        <h4 class="pwe-about-subheading">Salle dédiée au fitness, à la remise en forme et à la préparation
          physique</h4>
        <p>Profitez d'un lieu privatisé pour pratiquer le fitness, la musculation, vos préparations physique !
          Disponible à n'importe quel moment de la journée de 6h à 22h par abonnement et réservation en ligne (voir nos
          abonnements ci-dessous)</p>
        <p>Avec son matériel professionnel TYTAX T1-X, son tapis de course, son rameur et tous les accessoires
          nécessaires, vous pourrez profiter d'une salle de sport professionnelle tout en étant dans un endroit dédié
          uniquement pour vous.</p>
        <h4 class="pwe-about-subheading animate-box fadeInUp animated" data-animate-effect="fadeInUp">Equipements
          disponibles:</h4>
        <ul class="animate-box fadeInDown animated" data-animate-effect="fadeInDown"
          style="font-family: 'Cormorant Garamond', serif;font-size: 17px; list-style-type: disc">
          <li>Machine TYTAX T1-X avec toutes les options comprenant 1200 exercices avec tous les groupes musculaires
          </li>
          <li>Haltères de 4 à 42kg (Bowflex)</li>
          <li>TRX</li>
          <li>Roue abdominale</li>
          <li>Rameur (heubozen PRO)</li>
          <li>1 Barre Olympique</li>
          <li>1 Barre de squat</li>
          <li>1 Barre W</li>
          <li>2 Bancs</li>
          <li>480Kg de disques</li>
          <li>Bandes élastiques</li>
          <li>2 chaines acier de 16kg</li>
          <li>2 steppers</li>
          <li>2 tapis</li>
          <li>Tapis de Course</li>
          <li>Ballon de Gym</li>
        </ul>
      </div>
      <div class="col-md-6">
        <img src="../../assets/images/fond-salle.jpg" class="img-fluid animate-box fadeInLeft animated"
          data-animate-effect="fadeInLeft" alt="salle de musculation nouvelle génération">

        <div class="row" style="margin-top: 20px">

          <div class="col-md-12 animate-box fadeInRight animated" data-animate-effect="fadeInRight">
            <div style="width: 100%; display: flex; justify-content: center;">
              <div class="btn-contact">
                <a href="" [routerLink]="" class="btnSeanceEssai" (click)="contactUs()">
                  <mat-icon>fitness_center</mat-icon> <span>Demandez une séance d'essai gratuite</span>
                </a>
              </div>
            </div>


          </div>
        </div>

      </div>
    </div>
  </div>
</div>



<!-- Services -->
<div id="services" class="services clear pb-90 bg-pink" style="padding-top: 10px">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 mb-30">
        <h2 class="pwe-heading animate-box" style="text-align: center" data-animate-effect="fadeInRight"><u>Nos
            abonnements
            mensuels</u>
        </h2>
      </div>

      <div class="col-md-12 mb-30">
        <p style="text-align: center">Les serviettes de toilette sont fournies à chaque séance + boissons incluses</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="owl-carousel owl-theme">
          <div class="item img-fluid">

            <div class="position-re o-hidden">
              <div class="zoneDetails zalona-flex-center">4 Séances d'une heure</div>

              <img src="../../assets/images/abo01.jpg" alt="">
            </div>
            <div class="con">
              <h5><a href="" [routerLink]="">49€ par mois</a>
              </h5>
              <a href="" [routerLink]="" (click)="buy($event, '1801363')"><i class="ti-shopping-cart"></i></a>
            </div>
          </div>
          <div class="item img-fluid">
            <div class="position-re o-hidden">
              <div class="zoneDetails zalona-flex-center">8 Séances d'une heure</div>
              <img src="../../assets/images/abo02.jpg" alt="">
            </div>
            <div class="con">
              <h5><a href="" [routerLink]="">89€ par mois</a></h5> <a href="" [routerLink]=""
                (click)="buy($event, '1801367')"><i class="ti-shopping-cart"></i></a>
            </div>
          </div>
          <div class="item img-fluid">
            <div class="position-re o-hidden">
              <div class="zoneDetails zalona-flex-center">12 Séances d'une heure</div>
              <img src="../../assets/images/abo03.jpg" alt="">
            </div>
            <div class="con">
              <h5><a href="" [routerLink]="">129€ par mois</a></h5> <a href="" [routerLink]=""
                (click)="buy($event, '1801368')"><i class="ti-shopping-cart"></i></a>
            </div>
          </div>

          <div class="item img-fluid">
            <div class="position-re o-hidden">
              <div class="zoneDetails zalona-flex-center">16 Séances d'une heure</div>
              <img src="../../assets/images/abo04.jpg" alt="">
            </div>
            <div class="con">
              <h5><a href="" [routerLink]="">159€ par mois</a></h5> <a href="" [routerLink]=""
                (click)="buy($event, '1806107')"><i class="ti-shopping-cart"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
