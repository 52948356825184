declare var $: any;

export default () => {
  $('.services .owl-carousel').owlCarousel({
    loop: false,
    margin: 30,
    mouseDrag: true,
    touchDrap: true,
    autoplay: false,
    dots: true,
    center: false,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  });
};
