import { Component, OnInit } from '@angular/core';
import AnimationsMethod from '@animations';
// import { Title } from '@angular/platform-browser';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IsConnectedService } from '@services/is-connected.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '@env/environment';
import Validation from './validation';

@Component({
  selector: 'app-zalona-inscription',
  templateUrl: './zalona-inscription.component.html',
  styleUrls: ['./zalona-inscription.component.scss'],
})
export class ZalonaInscriptionComponent implements OnInit {
  form: UntypedFormGroup = new UntypedFormGroup({
    civility: new UntypedFormControl('Mme'),
    lastname: new UntypedFormControl(''),
    firstname: new UntypedFormControl(''),
    phone: new UntypedFormControl(''),
    email: new UntypedFormControl(''),
    password: new UntypedFormControl(''),
    confirmPassword: new UntypedFormControl(''),
    address: new UntypedFormControl(''),
    zipcode: new UntypedFormControl(''),
    city: new UntypedFormControl(''),
    birthday: new UntypedFormControl(''),
    acceptTerms: new UntypedFormControl(false),
    marketing: new UntypedFormControl(false),
  });

  submitted = false;

  public animationsMain: any = AnimationsMethod;
  public isLoading: boolean = false;
  public nameLocalSpinner: string = 'localSpinnerInscription';
  public errMessageInscription: string = null;

  constructor(
    private httpClient: HttpClient,
    public isConnectedService: IsConnectedService,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder // private titleService: Title
  ) {
    // this.titleService.setTitle('Création compte client');
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        civility: [null, Validators.required],
        lastname: ['', Validators.required],
        firstname: ['', Validators.required],
        phone: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(13),
          ],
        ],
        confirmPassword: ['', Validators.required],
        address: [''],
        zipcode: [''],
        city: [''],
        birthday: [''],
        acceptTerms: [false, Validators.requiredTrue],
        marketing: [false],
      },
      {
        validators: [Validation.match('password', 'confirmPassword')],
      }
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.animationsMain();
    }, 500);
  }

  openPopup() {
    (<any>window).open(
      'https://www.rdv360.com/cgu/',
      'popup',
      'width=600,height=600'
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onClickSubmitInscription() {
    this.submitted = true;
    if (!this.form.invalid) {
      this.isLoading = true;
      this.spinner.show();

      this.httpClient
        .post(
          'api/v1/software/user/inscription',
          {
            ...this.form.value,
            password: encodeURIComponent(
              (<any>window).btoa(this.form.value.password)
            ),
            confirmPassword: encodeURIComponent(
              (<any>window).btoa(this.form.value.password)
            ),
          },
          { observe: 'response' }
        )
        .subscribe({
          next: (res: any) => {
            if (res.body.result === 'ERROR') {
              this.errMessageInscription = res.body.message;
            } else {
              this.errMessageInscription = null;
              this.isConnectedService.createCookie({
                token: res.body.token,
                user: {
                  nom: this.form.value.lastname,
                  prenom: this.form.value.firstname,
                  email: this.form.value.email,
                  id: null,
                },
                expire: res.body.expire,
              });

              setTimeout(() => {
                this.animationsMain();
              }, 500);
            }
          },
          error: (err) => {
            if (err.status === 403) {
              this.errMessageInscription =
                'Vos identifiants ne semblent pas correctes';
            } else {
              this.errMessageInscription =
                'Un problème est survenu - Réassayez dans quelques instant';
            }
            this.isLoading = false;
            this.spinner.hide();
          },
          complete: () => {
            this.isLoading = false;
            this.spinner.hide();
          },
        });
    }
  }
}
