<p mat-dialog-title
  style="font-family: 'Nunito Sans', sans-serif !important; text-transform:uppercase; text-align: center; font-size: 16px"
  class="bold">
  {{valuesInject.element.name}}
</p>


<div mat-dialog-content style="text-align: center">

  <p
    style="font-family: 'Nunito Sans', sans-serif !important; text-transform:uppercase;text-align: center; font-size: 12px">
    Cette cure / option / prestation n'est pas disponible en ligne</p>

  <a class="" href="tel:+33985140056"><img src="../../../../assets/images/phone.png"
      style="width: 80px; margin-bottom: 15px" /></a>
  <p
    style="font-family: 'Nunito Sans', sans-serif !important; text-transform:uppercase;text-align: center; font-size: 12px">
    Pour plus d'informations contactez nous directement par téléphone</p>

  <p
    style="font-family: 'Nunito Sans', sans-serif !important; text-transform:uppercase; text-align: center; font-weight: bold; font-size: 20px">
    <a class="" href="tel:+33985140056">09 85 14 00 56</a></p>

</div>
