export const createChip = (event, container, renderer): Promise<boolean> => {
  let $: any = (<any>window).$;
  const cart: any = $(container);
  const chipElement = renderer.createElement('div');
  renderer.addClass(chipElement, 'chipClass');
  renderer.setStyle(chipElement, 'left', Math.round(event.pageX) + 'px');
  renderer.setStyle(chipElement, 'top', Math.round(event.pageY) + 'px');
  renderer.setStyle(chipElement, 'color', 'white');
  renderer.setStyle(chipElement, 'font-size', '11px');
  renderer.setStyle(chipElement, 'font-weight', 'bold');
  renderer.setStyle(chipElement, 'display', 'flex');
  renderer.setStyle(chipElement, 'align-items', 'center');
  renderer.setStyle(chipElement, 'justify-content', 'center');
  renderer.setStyle(
    chipElement,
    'box-shadow',
    '0px 0px 11px 0px rgba(0,0,0,0.75)'
  );
  renderer.appendChild(chipElement, renderer.createText('+1'));
  renderer.appendChild(document.body, chipElement);

  $(chipElement).animate(
    {
      top: cart.offset().top - 15,
      left: cart.offset().left + 40,
    },
    1000,
    'easeInOutExpo'
  );

  setTimeout(() => {
    cart.effect(
      'shake',
      {
        times: 4,
      },
      300
    );
  }, 600);

  return new Promise((resolve: any) => {
    setTimeout((e) => {
      $(chipElement).animate(
        {
          width: 0,
          height: 0,
          opacity: 0,
        },
        function () {
          $(this).detach();
        }
      );

      resolve(true);
    }, 800);
  });
};

interface IReturn {
  type: string;
  id: string;
  label: string;
  path: string;
}

export const manageSlug = (url: string, route: string): IReturn[] => {
  const explodeUrl = url.split('/');

  let obj = [];

  if (explodeUrl.length > 2) {
    if (explodeUrl[0] === '' && explodeUrl[1] === route) {
      const split: string[] = explodeUrl[2].split('-');
      if (split.length > 2) {
        const typeSlug = split[0];
        const idSlug = split[1];
        const labelSlug = split.splice(0, 1).join('-');

        if (Number.isInteger(parseInt(idSlug))) {
          obj.push({
            type: typeSlug,
            id: idSlug,
            label: labelSlug,
            path: explodeUrl[2],
          });

          if (explodeUrl.length > 3) {
            const split: string[] = explodeUrl[3].split('-');
            if (split.length > 2) {
              const typeSlug = split[0];
              const idSlug = split[1];
              const labelSlug = split.splice(0, 1).join('-');
              if (Number.isInteger(parseInt(idSlug))) {
                obj.push({
                  type: typeSlug,
                  id: idSlug,
                  label: labelSlug,
                  path: explodeUrl[3],
                });
              }
            }
          }
          return obj;
        }
      }
    }
  }

  return null;
};
