<p mat-dialog-title
  style="font-family: 'Nunito Sans', sans-serif !important; text-transform:uppercase; text-align: center; font-size: 18px"
  class="bold" *ngIf="valuesInject">
  Personnalisez votre carte cadeau à offrir et recevez directement par email !
</p>

<div mat-dialog-content *ngIf="valuesInject">
  <div class="container-fluid">
    <div [formGroup]="form" class="row">

      <div class="col-md-12 " *ngIf="type === 'SERVICE'">
        <p class="placeholder">Type de carte cadeau*</p>
        <div class="form-group">
          <select name="type" id="type" formControlName="type" class="form-control selectStyle" #selectForm
            (change)="changeType($event)">
            <option [ngValue]="'AMOUNT'" selected>Offrir un motant</option>
            <option [ngValue]="valuesInject.prestation.id">{{valuesInject ? valuesInject.prestation.name : ''}}
            </option>
          </select>
        </div>
      </div>

      <div class="col-md-12" *ngIf="form.value.type === 'AMOUNT'">
        <p class="placeholder">Le montant de la carte cadeau*</p>
        <div class="form-group">
          <input type="number" class="form-control" formControlName="amount" required min="20"
            [ngClass]="{ 'is-invalid': submitted && form.controls['amount'].errors }">
          <div *ngIf="submitted && form.controls['amount'].errors" class="invalid-feedback">
            <div *ngIf="form.controls['amount'].errors['required']">Le montant de votre carte cadeau est obligatoire
            </div>
            <div *ngIf="form.controls['amount'].errors['min']">Le montant minimum de votre carte cadeau est de 20€
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <p class="placeholder">Nom du bénéficiaire*</p>
        <div class="form-group">
          <input type="text" class="form-control" formControlName="lastname" required
            [ngClass]="{ 'is-invalid': submitted && form.controls['lastname'].errors }">
          <div *ngIf="submitted && form.controls['lastname'].errors" class="invalid-feedback">
            <div *ngIf="form.controls['lastname'].errors['required']">Le nom du bénéficiaire est obligatoire</div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <p class="placeholder">Prénom du bénéficiaire*</p>
        <div class="form-group">
          <input type="text" class="form-control" formControlName="firstname" required
            [ngClass]="{ 'is-invalid': submitted && form.controls['lastname'].errors }">
          <div *ngIf="submitted && form.controls['firstname'].errors" class="invalid-feedback">
            <div *ngIf="form.controls['firstname'].errors['required']">Le prénom du bénéficiaire est obligatoire</div>
          </div>
        </div>
      </div>

      <div class="col-md-12 checkboxStyle"><input type="checkbox" id="sendEmail" formControlName="sendEmail"
          (change)="changeEmailOption($event)">
        <label for="sendEmail">Envoyer
          la carte cadeau par mail au bénéficiaire</label>
      </div>

      <div class="col-md-12" *ngIf="form.value.sendEmail">
        <p class="placeholder">Email du bénificiaire*</p>
        <div class="form-group">
          <input type="email" class="form-control" formControlName="email" required
            [ngClass]="{ 'is-invalid': submitted && form.controls['email'].errors }">
          <div *ngIf="submitted && form.controls['email'].errors" class="invalid-feedback">
            <div *ngIf="form.controls['email'].errors['required']">Le mail du bénéficiaire est obligatoire si vous
              cochez cette
              option</div>
            <div *ngIf="form.controls['email'].errors['email']">Le mail du bénéficiaire est incorrecte</div>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <p class="placeholder">Titre de votre carte cadeau*</p>
        <div class="form-group">
          <input type="text" class="form-control" formControlName="title" required
            placeholder="Exemple: Joyeux aniversaire !" (input)="changeTitle($event)"
            [ngClass]="{ 'is-invalid': submitted && form.controls['title'].errors }">
          <div *ngIf="submitted && form.controls['title'].errors" class="invalid-feedback">
            <div *ngIf="form.controls['title'].errors['required']">Le titre de votre carte cadeau est obligatoire
            </div>
          </div>
        </div>
      </div>


      <div class="col-md-12">
        <p class="placeholder">Votre message*</p>
        <div class="form-group">
          <textarea class="form-control" formControlName="message" required
            placeholder="Exemple: Je te souhaite un très bon anniversaire ... !" (input)="changeMessage($event)"
            [ngClass]="{ 'is-invalid': submitted && form.controls['message'].errors }"></textarea>
          <div *ngIf="submitted && form.controls['message'].errors" class="invalid-feedback">
            <div *ngIf="form.controls['message'].errors['required']">Le message de votre carte cadeau est obligatoire
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <hr />
      </div>

      <div class="col-md-12" style="text-align: center; margin-top: 10px; margin-bottom: 10px">
        <div *ngIf="form.value.type !== 'AMOUNT'">
          <p style="margin-bottom: 0px">Échanger cette carte cadeau contre :</p>
          <p
            style="font-weight: bold; font-size: 18px; font-family: 'Nunito Sans', sans-serif !important; color: #a2783a">
            {{valuesInject && valuesInject.prestation ? valuesInject.prestation.name : ''}}</p>
        </div>

        <div *ngIf="form.value.type === 'AMOUNT' && form.value.amount > 19">
          <p style="margin-bottom: 0px">Valeur de la carte cadeau :</p>
          <p
            style="font-weight: bold; font-size: 18px;font-family: 'Nunito Sans', sans-serif !important; color: #a2783a">
            {{form.value.amount}} €</p>
        </div>

        <img src="../../../../../assets/images/barcode.png" style="width: 210px" /><br />
        <span style="color: #a2783a">XXXX XXXX XXXX XXXX</span>
      </div>


      <div class="col-md-12"
        style="margin-bottom: 10px; margin-top: 1px; text-align: center; font-size: 16px; font-weight: bold;"
        *ngIf="title !== null && title != ''">
        {{title}}
      </div>

      <div class="col-md-12" *ngIf="title === null || title==''"
        style="margin-bottom: 10px; margin-top: 1px; color: rgba(0,0,0,0.5); text-align: center; font-size: 16px">
        --- Votre titre ---
      </div>

      <div class="col-md-12" *ngIf="pictureSelected !== null" style="margin-bottom: 10px; margin-top: 10px">
        <img src="{{pictureSelected}}" class="pictureEffect" />
      </div>

      <div class="col-md-12">
        <div class="form-group" style="display: flex; align-items: center; justify-content: center;">
          <span *ngFor="let img of pictures" style="margin: 3px" class="radio"
            (click)="changePicture($event, radioReference)">
            <input type="radio" formControlName="pictGift" name="pictGift" [value]="img.id" #radioReference
              [attr.value]="img.id" class="radioStyle">
            <span>&nbsp;</span>
          </span>
        </div>
      </div>


      <div class="col-md-12"
        style="margin-bottom: 2px; margin-top: 10px; text-align: left; font-size: 14px; font-weight: 400; white-space: pre-wrap; padding-left: 10px"
        *ngIf="message !== null && message != ''" [innerHTML]="message">
      </div>


      <div class="col-md-12" *ngIf="title === null || title==''"
        style="margin-bottom: 2px; margin-top: 10px; text-align: center; font-size: 14px; font-weight: 400; white-space: pre-wrap;color: rgba(0,0,0,0.5); padding-left: 10px">
        --- Votre titre ---
      </div>
    </div>

  </div>
</div>

<div mat-dialog-actions class="matAction" *ngIf="valuesInject">
  <div class="row" style="height: 70px; width: 100%; margin-right: 0px; margin-left: 0px">
    <div class="col-md-12" style="width: 100%; height: 70px; display: flex;
    align-items: center;
    justify-content: center;">
      <div class="btn-contact"> <a href="" style="margin-bottom: 0px" [routerLink]="" class="btnChooseAnotherPresta"
          (click)="addToCart($event)">
          <mat-icon>add_shopping_cart</mat-icon> <span>Ajouter au panier</span>
        </a> </div>
    </div>
  </div>
</div>


<div *ngIf="!valuesInject">
  <div class="container-fluid">
    <div [formGroup]="form" class="row">

      <div class="col-md-12 " *ngIf="type === 'SERVICE'">
        <p class="placeholder">Type de carte cadeau*</p>
        <div class="form-group">
          <select name="type" id="type" formControlName="type" class="form-control selectStyle" #selectForm
            (change)="changeType($event)">
            <option [ngValue]="'AMOUNT'" selected>Offrir un motant</option>
            <option [ngValue]="valuesInject.prestation.id">{{valuesInject ? valuesInject.prestation.name : ''}}
            </option>
          </select>
        </div>
      </div>

      <div class="col-md-12" *ngIf="form.value.type === 'AMOUNT'">
        <p class="placeholder">Le montant de la carte cadeau*</p>
        <div class="form-group">
          <input type="number" class="form-control" formControlName="amount" required min="20"
            [ngClass]="{ 'is-invalid': submitted && form.controls['amount'].errors }">
          <div *ngIf="submitted && form.controls['amount'].errors" class="invalid-feedback">
            <div *ngIf="form.controls['amount'].errors['required']">Le montant de votre carte cadeau est obligatoire
            </div>
            <div *ngIf="form.controls['amount'].errors['min']">Le montant minimum de votre carte cadeau est de 20€
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <p class="placeholder">Nom du bénéficiaire*</p>
        <div class="form-group">
          <input type="text" class="form-control" formControlName="lastname" required
            [ngClass]="{ 'is-invalid': submitted && form.controls['lastname'].errors }">
          <div *ngIf="submitted && form.controls['lastname'].errors" class="invalid-feedback">
            <div *ngIf="form.controls['lastname'].errors['required']">Le nom du bénéficiaire est obligatoire</div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <p class="placeholder">Prénom du bénéficiaire*</p>
        <div class="form-group">
          <input type="text" class="form-control" formControlName="firstname" required
            [ngClass]="{ 'is-invalid': submitted && form.controls['lastname'].errors }">
          <div *ngIf="submitted && form.controls['firstname'].errors" class="invalid-feedback">
            <div *ngIf="form.controls['firstname'].errors['required']">Le prénom du bénéficiaire est obligatoire</div>
          </div>
        </div>
      </div>

      <div class="col-md-12 checkboxStyle"><input type="checkbox" id="sendEmail" formControlName="sendEmail"
          (change)="changeEmailOption($event)">
        <label for="sendEmail">Envoyer
          la carte cadeau par mail au bénéficiaire</label>
      </div>

      <div class="col-md-12" *ngIf="form.value.sendEmail">
        <p class="placeholder">Email du bénificiaire*</p>
        <div class="form-group">
          <input type="email" class="form-control" formControlName="email" required
            [ngClass]="{ 'is-invalid': submitted && form.controls['email'].errors }">
          <div *ngIf="submitted && form.controls['email'].errors" class="invalid-feedback">
            <div *ngIf="form.controls['email'].errors['required']">Le mail du bénéficiaire est obligatoire si vous
              cochez cette
              option</div>
            <div *ngIf="form.controls['email'].errors['email']">Le mail du bénéficiaire est incorrecte</div>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <p class="placeholder">Titre de votre carte cadeau*</p>
        <div class="form-group">
          <input type="text" class="form-control" formControlName="title" required
            placeholder="Exemple: Joyeux aniversaire !" (input)="changeTitle($event)"
            [ngClass]="{ 'is-invalid': submitted && form.controls['title'].errors }">
          <div *ngIf="submitted && form.controls['title'].errors" class="invalid-feedback">
            <div *ngIf="form.controls['title'].errors['required']">Le titre de votre carte cadeau est obligatoire
            </div>
          </div>
        </div>
      </div>


      <div class="col-md-12">
        <p class="placeholder">Votre message*</p>
        <div class="form-group">
          <textarea class="form-control" formControlName="message" required
            placeholder="Exemple: Je te souhaite un très bon anniversaire ... !" (input)="changeMessage($event)"
            [ngClass]="{ 'is-invalid': submitted && form.controls['message'].errors }"></textarea>
          <div *ngIf="submitted && form.controls['message'].errors" class="invalid-feedback">
            <div *ngIf="form.controls['message'].errors['required']">Le message de votre carte cadeau est obligatoire
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <hr />
      </div>

      <div class="col-md-12" style="text-align: center; margin-top: 10px; margin-bottom: 10px">
        <div *ngIf="form.value.type !== 'AMOUNT'">
          <p style="margin-bottom: 0px">Échanger cette carte cadeau contre :</p>
          <p
            style="font-weight: bold; font-size: 18px; font-family: 'Nunito Sans', sans-serif !important; color: #a2783a">
            {{valuesInject && valuesInject.prestation ? valuesInject.prestation.name : ''}}</p>
        </div>

        <div *ngIf="form.value.type === 'AMOUNT' && form.value.amount > 19">
          <p style="margin-bottom: 0px">Valeur de la carte cadeau :</p>
          <p
            style="font-weight: bold; font-size: 18px;font-family: 'Nunito Sans', sans-serif !important; color: #a2783a">
            {{form.value.amount}} €</p>
        </div>

        <img src="../../../../../assets/images/barcode.png" style="width: 210px" /><br />
        <span style="color: #a2783a">XXXX XXXX XXXX XXXX</span>
      </div>


      <div class="col-md-12"
        style="margin-bottom: 10px; margin-top: 1px; text-align: center; font-size: 16px; font-weight: bold;"
        *ngIf="title !== null && title != ''">
        {{title}}
      </div>

      <div class="col-md-12" *ngIf="title === null || title==''"
        style="margin-bottom: 10px; margin-top: 1px; color: rgba(0,0,0,0.5); text-align: center; font-size: 16px">
        --- Votre titre ---
      </div>

      <div class="col-md-12" *ngIf="pictureSelected !== null" style="margin-bottom: 10px; margin-top: 10px">
        <img src="{{pictureSelected}}" class="pictureEffect" />
      </div>

      <div class="col-md-12">
        <div class="form-group" style="display: flex; align-items: center; justify-content: center;">
          <span *ngFor="let img of pictures" style="margin: 3px" class="radio"
            (click)="changePicture($event, radioReference)">
            <input type="radio" formControlName="pictGift" name="pictGift" [value]="img.id" #radioReference
              [attr.value]="img.id" class="radioStyle">
            <span>&nbsp;</span>
          </span>
        </div>
      </div>


      <div class="col-md-12"
        style="margin-bottom: 2px; margin-top: 10px; text-align: left; font-size: 14px; font-weight: 400; white-space: pre-wrap; padding-left: 10px"
        *ngIf="message !== null && message != ''" [innerHTML]="message">
      </div>


      <div class="col-md-12" *ngIf="title === null || title==''"
        style="margin-bottom: 2px; margin-top: 10px; text-align: center; font-size: 14px; font-weight: 400; white-space: pre-wrap;color: rgba(0,0,0,0.5); padding-left: 10px">
        --- Votre titre ---
      </div>



      <div class="col-md-12" style="margin-top:15px; margin-bottom: 15px" *ngIf="!valuesInject">
        <div class="btn-contact"> <a href="" style="margin-bottom: 0px" [routerLink]=""
            class="btnChooseAnotherPrestaBig" (click)="addToCart($event)">
            <mat-icon>add_shopping_cart</mat-icon> <span>Ajouter cette carte cadeau à votre panier</span>
          </a> </div>
      </div>
    </div>

  </div>
</div>
