import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { ScriptService } from '@services/extern-script.service';
import { IsConnectedService } from '@services/is-connected.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { StoreCartService } from '@services/store-cart.service';
import AnimationsMethod from '@animations';

declare let Stripe: any;
type TStatusStripe =
  | 'succeeded'
  | 'canceled'
  | 'requires_payment_method'
  | 'requires_confirmation'
  | 'requires_action'
  | 'processing'
  | 'requires_capture';

interface IStripeResponse {
  amount: number;
  capture_method: string;
  created: number;
  currency: string;
  livemode: boolean;
  payment_method_types: string[];
  status: TStatusStripe;
}

@Component({
  selector: 'app-zalona-checkout',
  templateUrl: './zalona-checkout.component.html',
  styleUrls: ['./zalona-checkout.component.scss'],
})
export class ZalonaCheckoutComponent implements OnInit {
  public stripe: any = null;
  public message: string = null;
  public redirect_status: TStatusStripe;
  public payment_intent: string = null;
  public payment_intent_client_secret: string = null;
  public detailsStripe: IStripeResponse;
  public title: string = '';
  public animationsMain: any = AnimationsMethod;
  public description: string = '';

  constructor(
    private scriptService: ScriptService,
    private currentRoute: ActivatedRoute,
    private router: Router,
    public isConnectedService: IsConnectedService,
    private spinner: NgxSpinnerService,
    private storeManagement: StoreCartService
  ) {
    this.currentRoute.queryParams.subscribe((params: any) => {
      if (
        params &&
        params.payment_intent &&
        params.payment_intent_client_secret &&
        params.redirect_status &&
        isConnectedService.isConnected
      ) {
        this.redirect_status = params.redirect_status;
        this.payment_intent = params.payment_intent;
        this.payment_intent_client_secret = params.payment_intent_client_secret;

        this.spinner.show();
        const stripeSCRIPTAUTO = document.getElementById('stripeSCRIPTAUTO');

        setTimeout(() => {
          if (stripeSCRIPTAUTO === null) {
            this.scriptService
              .load('stripe')
              .then((data) => {
                this.stripeRetrieve();
              })
              .catch((e) => console.error(e));
          } else {
            this.stripeRetrieve();
          }
        }, 200);
      } else {
        this.router.navigate(['/redirect']);
      }
    });
  }

  ngOnInit(): void {}

  stripeRetrieve() {
    this.stripe = Stripe(environment.stripeKey);
    this.stripe
      .retrievePaymentIntent(this.payment_intent_client_secret)
      .then((res) => {
        this.detailsStripe = res.paymentIntent;
        if (res.paymentIntent.status === 'succeeded') {
          this.storeManagement.clear();
        }
        setTimeout(() => {
          this.animationsMain();
          this.spinner.hide();
        }, 50);
      });
  }
}
