<div style="width: 100%; text-align: center" *ngIf="!isConnectedService.isConnected">
  <form #userlogin="ngForm" (ngSubmit)="onClickSubmit(userlogin.value)" *ngIf="isConnect">
    <div class="row">
      <div class="col-md-12 form-group zalona-flex-center" style="margin-bottom:2px">
        <div class="col-md-7" style="padding-right: 0px; padding-left: 0px">
          <input name="login" type="text" placeholder="Votre login *" required ngModel>
        </div>
      </div>
      <div class="col-md-12 zalona-flex-center" style="margin-top:2px; margin-bottom:2px">
        <div class="col-md-7" style="padding-right: 0px; padding-left: 0px">
          <input name="password" type="password" placeholder="votre mot de passe *" required ngModel>
        </div>
      </div>
      <div class="col-md-12 zalona-flex-center" style="margin-top:2px; margin-bottom:2px"
        *ngIf="errMessageConnexion !== null" @openClose>
        <div class="col-md-7 errMessage" [innerHTML]="errMessageConnexion"></div>
      </div>
      <div class="col-md-12 zalona-flex-center" style="margin-top:2px; margin-bottom:2px">
        <div class="col-md-7" style="padding-right: 0px; padding-left: 0px">
          <input name="submit" type="submit" value="Connexion" class="btnConnexion" *ngIf="!isLoading">
        </div>
      </div>
    </div>
  </form>

  <form #loginForget="ngForm" (ngSubmit)="onClickSubmitForget(loginForget.value)" *ngIf="isForget">
    <div class="row">
      <div class="col-md-12 form-group zalona-flex-center" style="margin-bottom:2px">
        <div class="col-md-7" style="padding-right: 0px; padding-left: 0px">
          <input name="email" type="email" placeholder="Votre email *" required ngModel>
        </div>
      </div>
      <div class="col-md-12 zalona-flex-center" style="margin-top:2px; margin-bottom:2px"
        *ngIf="errMessageConnexion !== null" @openClose>
        <div class="col-md-7 errMessage" [innerHTML]="errMessageConnexion"></div>
      </div>
      <div class="col-md-12 zalona-flex-center" style="margin-top:2px; margin-bottom:2px">
        <div class="col-md-7" style="padding-right: 0px; padding-left: 0px">
          <input name="submit" type="submit" value="Envoyer code" class="btnConnexion" *ngIf="!isLoading">
        </div>
      </div>
    </div>
  </form>


  <div class="row" *ngIf="successMessage !== null" @openClose>
    <div class="col-md-12 zalona-flex-center" style="margin-top:2px; margin-bottom:2px">
      <div class="col-md-7 successMessage">
        {{successMessage}}
      </div>
    </div>
  </div>


  <div class="row" *ngIf="!isForget && !isConnect">
    <div class="col-md-12 zalona-flex-center" style="margin-top:2px; margin-bottom: 2px">
      <div class="col-md-7" style="padding-right: 0px; padding-left: 0px">
        <input name="button" type="button" value="Connexion" class="btnConnexion" (click)="isConnectFn($event)">
      </div>
    </div>
    <div class="col-md-12 zalona-flex-center" style="margin-top:2px;">
      <div class="col-md-7" style="padding-right: 0px; padding-left: 0px">
        <input name="button" type="button" value="Inscription" class="btnInscription switchToogleMenu"
          routerLink="/inscription">
      </div>
    </div>
    <div class="col-md-12 zalona-flex-center" style="margin-top:2px;" *ngIf="!isForget">
      <div class="col-md-7" style="text-align: right; padding-right: 0px; padding-left: 0px">
        <span class="pwdForget" (click)="isForgetFn($event)"><i>Mot de passe oublié
            ?</i></span>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="(isForget || isConnect)">
    <div class="col-md-12 zalona-flex-center" style="margin-top:2px;">
      <div class="col-md-7" style="text-align: right; padding-right: 0px; padding-left: 0px">
        <div class="row" style="margin-right: 0px; margin-left: 0px">
          <div class="col-md-6" style="text-align: right; padding-right: 0px; padding-left: 0px">
            <span class="pwdForget" (click)="isForgetFn($event)" *ngIf="!isForget"><i>Mot de passe oublié
                ?</i></span>
          </div>
          <div class="col-md-6" style="text-align: right; padding-right: 0px; padding-left: 0px">
            <span class="pwdForget" (click)="isRetourFn($event)"><i>Retour</i></span>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
