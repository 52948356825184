<div class="example-header">
  <div class="btn-contact" style="margin-bottom: 5px;" *ngIf="isShow">
    <a href="" [routerLink]="" class="btnMonth" (click)="previousClicked('month')"
      style="margin-left: 40px; margin-top: 10px">
      <mat-icon>navigate_before</mat-icon>
    </a>
  </div>

  <span class="example-header-label">{{periodLabel}}</span>

  <div class="btn-contact" style="margin-bottom: 5px;">
    <a href="" [routerLink]="" class="btnMonth" (click)="nextClicked('month')"
      style="margin-right: 40px; margin-top: 10px">
      <mat-icon>navigate_next</mat-icon>
    </a>
  </div>
</div>
