<div class="cont">
  <div class="type">
    <h6 class="titleProduct">{{prestation.name}}</h6>
  </div>
  <div class="valueAmount">
    <h4>{{prestation.sell_price}}<span>€</span></h4>

    <span style="position: relative">
      <mat-icon aria-hidden="false" style="position: absolute; top: -2px; left: -30px">access_time</mat-icon>
      Validité {{prestation.month_validity}} mois
    </span>

  </div>
  <p class="subTitlePresta"><u>Description</u></p>
  <div class="feat" (click)="openDetails(prestation)">
    <div class="container" [innerHTML]="description"></div>
  </div>
</div>
<div class="btn-contact" style="margin-bottom: 5px;" *ngIf="prestation.sell_online === '1'">
  <a href="" [routerLink]="" class="btnReservation" (click)="buy($event, prestation)">
    <mat-icon>shopping_basket</mat-icon> <span>Ajouter au panier</span>
  </a>
</div>

<div class="btn-contact"> <a href="" [routerLink]="" class="btnOffrirContact" *ngIf="prestation.sell_online === '0'"
    (click)="contactUs(prestation)">
    <mat-icon>spa</mat-icon> <span>Nous contacter</span>
  </a> </div>
