import { Component, OnInit, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import IPrestationClient from '@models/prestation-client.model';
import AnimationsMethod from '@animations';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { IsConnectedService } from '@services/is-connected.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HotToastService } from '@ngneat/hot-toast';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit {
  public animationsMain: any = AnimationsMethod;
  constructor(
    @Inject(MAT_DIALOG_DATA) public valuesInject: IPrestationClient,
    public dialogRef: MatDialogRef<ConfirmationComponent>,
    private httpClient: HttpClient,
    private titleService: Title,
    public isConnectedService: IsConnectedService,
    private spinner: NgxSpinnerService,
    private toast: HotToastService
  ) {
    this.titleService.setTitle('Historique de vos réservations');
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.animationsMain();
    }, 50);
  }

  cancelation() {
    this.spinner.show();
    this.httpClient
      .post(
        'api/v1/software/booking/cancel/' + this.valuesInject.id,
        {
          token: this.isConnectedService.getDetails().token,
          id: this.valuesInject.id,
        },
        { observe: 'response' }
      )
      .subscribe({
        next: (res: any) => {
          if (res.body.result === 'ERROR') {
            this.toast.error(
              "Une erreur est survenue pendant l'annulation de votre rendez-vous. Merci de réassayer dans queqlues instants",
              { position: 'bottom-center' }
            );
          } else {
            this.toast.success('Votre réservation a bien été annulée', {
              position: 'top-center',
            });
            this.dialogRef.close('updateStore');
          }

          this.spinner.hide();
        },
        error: (err) => {
          this.toast.error(
            "Une erreur est survenue pendant l'annulation de votre rendez-vous. Merci de réassayer dans queqlues instants",
            { position: 'bottom-center' }
          );
          this.spinner.hide();
        },
        complete: () => {
          this.spinner.hide();
        },
      });
  }

  closeModal() {
    this.dialogRef.close();
  }
}
