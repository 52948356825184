<div class="cont">
  <div class="type">
    <h6 class="titleProduct">{{product.name}}</h6>
  </div>
  <div class="valueAmount">
    <h4>{{product.sell_price}}<span>€</span></h4>

    <span style="position: relative">Stock restant {{product.stock_quantity | number : '1.0-0'}}</span>
    <!-- <span style="position: relative">
      <mat-icon aria-hidden="false" style="position: absolute; top: -2px; left: -30px">access_time</mat-icon>
      {{product.online_duration}} min
    </span>-->

  </div>
  <p class="subTitlePresta"><u>Description</u></p>
  <div class="feat" (click)="openDetails(product)">
    <div class="container" [innerHTML]="description"></div>
  </div>
</div>

<div class="btn-contact" style="margin-bottom: 5px;" *ngIf="product.sell_online === '1' || product.sell_online === '0'">
  <a href="" [routerLink]="" class="btnReservation disabled">
    <mat-icon>shopping_basket</mat-icon> <span>Acheter</span>
  </a>
</div>
<!--
<div class="btn-contact"> <a href="" [routerLink]="" class="btnOffrir" *ngIf="product.sell_online === '0'"
    (click)="detail(product)">
    <mat-icon>spa</mat-icon> <span>Détails</span>
  </a> </div>

-->
