import { Component, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IsConnectedService } from '@services/is-connected.service';
import { environment } from '@env/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import IPrestationClient from '@models/prestation-client.model';
import AnimationsMethod from '@animations';
import { HotToastService } from '@ngneat/hot-toast';
import { ConfirmationComponent } from './confirmation/confirmation.component';
// import { Title } from '@angular/platform-browser';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-historique',
  templateUrl: './historique.component.html',
  styleUrls: ['./historique.component.scss'],
})
export class HistoriqueComponent implements OnInit {
  public isConnected: boolean = false;
  public values: IPrestationClient[] = new Array<IPrestationClient>();
  public animationsMain: any = AnimationsMethod;

  constructor(
    public isConnectedService: IsConnectedService,
    private httpClient: HttpClient,
    private router: Router,
    public spinner: NgxSpinnerService,
    private toast: HotToastService,
    public dialog: MatDialog // private titleService: Title
  ) {
    this.isConnected = this.isConnectedService.isConnected;
    // this.titleService.setTitle('Historique de vos réservations');
  }

  ngOnInit(): void {
    if (this.isConnected) {
      this.getAllReservations();
    } else {
      this.router.navigate(['/redirect']);
    }
  }

  getAllReservations() {
    this.spinner.show();
    this.httpClient
      .get(
        'api/v1/software/user/historique?token=' +
          this.isConnectedService.getDetails().token
      )
      .subscribe({
        next: (res: any) => {
          this.values = res.results;
          setTimeout(() => {
            this.animationsMain();
          }, 50);
        },
        error: (err) => {},
        complete: () => {
          this.spinner.hide();
        },
      });
  }

  getType(type: '1' | '2' | '3') {
    return this.values
      .filter((e) => {
        if (type === '2') {
          return (
            e.status === '2' ||
            e.status === '3' ||
            (e.status === '1' &&
              Math.round(new Date(e.begin).getTime() / 1000) <
                Math.round(new Date().getTime() / 1000))
          );
        } else {
          return (
            e.status === '1' &&
            Math.round(new Date(e.begin).getTime() / 1000) >
              Math.round(new Date().getTime() / 1000)
          );
        }
      })
      .sort((a, b) => {
        if (type === '2') {
          return (
            Math.round(new Date(b.begin).getTime() / 1000) -
            Math.round(new Date(a.begin).getTime() / 1000)
          );
        } else {
          return (
            Math.round(new Date(a.begin).getTime() / 1000) -
            Math.round(new Date(b.begin).getTime() / 1000)
          );
        }
      });
  }

  ngOnDestroy() {
    this.animationsMain = null;
  }

  returnPrestationsString(booking: IPrestationClient) {
    return booking.contents.join('-');
  }

  checkCancelation(datetime) {
    const check =
      Math.round(new Date(datetime).getTime() / 1000) -
      Math.round(new Date().getTime() / 1000);
    if (check < 172800) {
      return false;
    }
    return true;
  }

  downloadPdf(base64String, fileName) {
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement('a');
    link.href = source;
    link.id = 'zoneDownloadPdf';
    link.download = `${fileName}`;
    link.click();
    document.getElementById('zoneDownloadPdf').remove();
    this.spinner.hide();
  }

  getPdf(ticketId: string) {
    this.spinner.show();
    this.httpClient.get('api/v1/software/ticket/pdf/' + ticketId).subscribe({
      next: (res: any) => {
        if (res.content && res.content !== null) {
          this.downloadPdf(res.content, res.name);
        } else {
          this.toast.error(
            'Une erreur est survenue lors du téléchargement de votre facture. Veuillez recommencer dans quelques instant',
            { position: 'bottom-center' }
          );
          this.spinner.hide();
        }
      },
      error: (err) => {
        this.spinner.hide();
        this.toast.error(
          'Une erreur est survenue lors du téléchargement de votre facture. Veuillez recommencer dans quelques instant',
          { position: 'bottom-center' }
        );
      },
      complete: () => {
        this.spinner.hide();
      },
    });
  }

  cancelBooking(booking: IPrestationClient) {
    this.dialog
      .open(ConfirmationComponent, {
        data: { ...booking },
        panelClass: 'zalona-modalbox',
        width: '350px',
      })
      .afterClosed()
      .subscribe((next) => {
        {
          if (next === 'updateStore') {
            this.getAllReservations();
          }
        }
      });
  }
}
