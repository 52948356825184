<div class="banner-container">
  <div class="banner-img"> <img class="banner-img-width" src="../../assets/images/banner-prestation.jpg" alt=""> </div>
  <div class="banner-head">
    <div class="banner-head-padding banner-head-margin">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12"> <span class="heading-meta">Zalona</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
