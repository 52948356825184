import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Inject,
} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IsConnectedService } from '@services/is-connected.service';
import { AnimationLogin } from '@animationsAngular';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { UsermenuService } from '@services/usermenu.service';
import { HotToastService } from '@ngneat/hot-toast';

@Component({
  selector: 'app-zalona-user',
  templateUrl: './zalona-user.component.html',
  styleUrls: ['./zalona-user.component.scss'],
  animations: [AnimationLogin],
})
export class ZalonaUserComponent implements OnInit {
  public isForget: boolean = false;
  public isConnect: boolean = false;
  public errMessageConnexion: string = null;
  public isLoading: boolean = false;
  public successMessage: string = null;

  @Output() sendToCloseMenu = new EventEmitter<boolean>();

  constructor(
    private httpClient: HttpClient,
    public isConnectedService: IsConnectedService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private usermenuService: UsermenuService,
    private toast: HotToastService
  ) {
    this.usermenuService.changes.subscribe((e) => {
      this.isForget = e.isForget;
      this.isConnect = e.isConnect;
    });
    this.usermenuService.init();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  onClickSubmit(data) {
    if (data.login == '' || data.password == '') {
      this.errMessageConnexion = 'Les deux champs sont obligatoires';
    } else {
      this.isLoading = true;
      this.spinner.show();

      this.httpClient
        .post(
          'api/v1/software/user/connect',
          {
            login: data.login,
            password: data.password,
          },
          { observe: 'response' }
        )
        .subscribe({
          next: (res: any) => {
            if (res.body.result === 'ERROR') {
              this.errMessageConnexion = res.body.message;
            } else {
              this.errMessageConnexion = null;
              this.sendToCloseMenu.emit(false);
              this.isConnectedService.createCookie({
                token: res.body.token,
                user: {
                  nom: res.body.details.lastname,
                  prenom: res.body.details.firstname,
                  email: res.body.details.email,
                  id: res.body.details.customer_id,
                },
                expire: res.body.expire,
              });

              this.toast.success(
                'Vous êtes maintenant connecté sur le compte de ' +
                  res.body.details.firstname +
                  ' ' +
                  res.body.details.lastname,
                { position: 'top-center' }
              );
            }
          },
          error: (err) => {
            if (err.status === 403) {
              this.errMessageConnexion =
                'Vos identifiants ne semblent pas correctes';
            } else {
              this.errMessageConnexion =
                'Un problème est survenu - Réassayez dans quelques instant';
            }
            this.isLoading = false;
            this.spinner.hide();
          },
          complete: () => {
            this.isLoading = false;
            this.spinner.hide();
          },
        });
    }
  }

  onClickSubmitForget(data) {
    if (data.email == '') {
      this.errMessageConnexion =
        'Votre est obligatoire pour réinitialiser votre compte';
    } else {
      this.isLoading = true;
      this.spinner.show();

      this.httpClient
        .post(
          'api/v1/software/user/reinit',
          {
            email: data.email,
          },
          { observe: 'response' }
        )
        .subscribe({
          next: (res: any) => {
            console.log('res', res.body);
            if (res.body.result === 'ERROR') {
              this.errMessageConnexion = res.body.message;
            } else {
              this.isForget = false;
              this.isConnect = false;
              this.errMessageConnexion = null;
              this.successMessage = res.body.message;
            }
          },
          error: (err) => {
            if (err.status === 403) {
              this.errMessageConnexion =
                'Votre email ne semble pas exister dans la base de donnée de RDV360';
            } else {
              this.errMessageConnexion =
                'Un problème est survenu - Réassayez dans quelques instant';
            }
            this.isLoading = false;
            this.spinner.hide();
          },
          complete: () => {
            this.isLoading = false;
            this.spinner.hide();
          },
        });
    }
  }

  isConnectFn(event) {
    event.stopPropagation();
    this.isConnect = true;
  }

  isForgetFn(event) {
    event.stopPropagation();
    this.isForget = true;
    this.isConnect = false;
    this.errMessageConnexion = null;
  }

  isRetourFn(event) {
    event.stopPropagation();
    this.isForget = false;
    this.isConnect = false;
    this.errMessageConnexion = null;
  }

  toDisconnect(): void {
    this.isConnectedService.disconnect();
    this.sendToCloseMenu.emit(false);
    this.router.navigate(['/home']);
  }
}
