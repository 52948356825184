import {
  animate,
  animateChild,
  group,
  query,
  stagger,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Renderer2,
} from '@angular/core';
import AnimationsMethod from '@animations';

const ShakeAnimation = [
  style({ opacity: 0 }),
  animate('15s', style({ opacity: 1 })),
];

@Component({
  selector: 'app-institut',
  templateUrl: './zalona-institut.component.html',
  styleUrls: ['./zalona-institut.component.scss'],
  animations: [
    trigger('inOut', [
      state(
        'false',
        style({ opacity: 0, backdropFilter: 'blur(0px)', display: 'none' })
      ),
      state('true', style({ opacity: 1, backdropFilter: 'blur(5px)' })),
      transition('false=>true', [
        style({ display: 'block' }),
        group([
          query('@inOutChild', animateChild({ delay: 1000 })),
          animate('1000ms'),
        ]),
      ]),
      transition('true=>false', [
        group([query('@inOutChild', animateChild()), animate('600ms')]),
      ]),
    ]),
    trigger('inOutChild', [
      state('false', style({ opacity: 0, filter: 'blur(5px)' })),
      state('true', style({ opacity: 1, filter: 'blur(0px)' })),
      transition('false=>true', [animate('1000ms')]),
      transition('true=>false', [animate('600ms')]),
    ]),
  ],
})
export class ZalonaInstitutComponent implements OnInit {
  @ViewChild('modalContainer') div: ElementRef;

  public isShown: boolean = false;
  public currentState: 'INITIAL' | 'FINAL' = 'INITIAL';
  public animationsMain: any = AnimationsMethod;
  public indexPicture: number = 0;
  public pictures = [
    {
      img: '01e495e4-b588-4afb-a362-43e955bab020.jpg',
      description: 'Accueil chic et relaxant',
    },
    {
      img: '0d5d5541-1289-3997-89e0-70278b8bb089.jpg',
      description: 'Feu de cheminée',
    },
    {
      img: '1de94078-5268-4270-9442-f292ebf7bfe3.jpg',
      description: 'Soin duo exceptionnellement zen',
    },
    {
      img: '2e00443b-221f-4f5c-a40a-d1ecbb31a8e9.jpg',
      description: "Vue d'ensemble",
    },
    {
      img: '2d5d5541-4289-6997-89e0-70278b8bb089.jpg',
      description: 'Sauna infra-rouge',
    },
    {
      img: 'b5d0a91b-61e5-4a9e-b24e-b0cdf738b73e.jpg',
      description: 'Nos produits français Blanc crème',
    },
    {
      img: 'b7fc185d-f4b1-43a7-a62c-f601f0fa9b9d.jpg',
      description: 'Notre salle de sport privée',
    },
    {
      img: 'c4b003bf-e5fb-457f-915e-56990bf80c3f.jpg',
      description: 'Détente extérieur',
    },
    {
      img: 'cae9e615-4a93-4f8f-932f-209111654cf0.jpg',
      description: 'Notre balnéothérapie chaleureuse',
    },
    {
      img: 'ec5525ec-9ca5-46dd-8f85-7d887be36927.jpg',
      description: 'Nos équipements sportifs',
    },
    { img: 'home3.jpg', description: 'Accueil Zalôna' },
    { img: '0011.jpeg', description: 'Massage Solo' },
    { img: 'duo.jpg', description: 'Massage Duo' },
    { img: '0001.jpeg', description: 'Balnéothérapie + gommage' },
    { img: '0003.jpeg', description: 'Escapade marine' },
    { img: 'workout.jpg', description: 'Salle de remise en forme' },
    { img: 'onglerie.jpg', description: 'Exemple de réalisation' },
    { img: '0012.jpeg', description: 'Salle privatisée' },
    { img: 'massage.jpg', description: 'Coin cosi' },
    { img: 'repos2.jpg', description: 'Salle de repos' },
    { img: '0015.jpeg', description: 'LPG visage' },
    { img: '0009.jpeg', description: 'Accueil Zalôna' },
    { img: 'home2.jpg', description: 'Accueil Zalôna' },
    { img: '0016.jpeg', description: 'Massage Duo' },
    { img: 'repos.jpg', description: 'Salle de repos' },
    { img: '0017.jpeg', description: 'Détente extérieure' },
    { img: 'balneo.jpg', description: 'Balnéothérapie' },
    { img: '0018.jpeg', description: 'Salle Balnéo' },
    { img: 'home.jpg', description: 'Acceuil Zalôna' },
    { img: '0020.jpeg', description: 'LPG Dernière génération' },
    { img: '0021.jpeg', description: 'Salle de remise en forme' },
  ];

  constructor(public _render: Renderer2) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.animationsMain();
    }, 500);
  }

  changePicture(type: 'BACK' | 'NEXT'): void {
    if (type === 'NEXT') {
      this.indexPicture =
        this.pictures.length - 1 < this.indexPicture + 1
          ? this.pictures.length - 1
          : this.indexPicture + 1;
    }

    if (type === 'BACK') {
      this.indexPicture = this.indexPicture - 1 < 0 ? 0 : this.indexPicture - 1;
    }
  }
}
