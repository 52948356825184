<div class="banner-container">
  <div class="banner-img"> <img class="banner-img-width" src="../../assets/images/banner-prestation03.jpg" alt="">
  </div>
  <div class="banner-head">
    <div class="banner-head-padding banner-head-margin">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12"> <span class="heading-meta">Zalona</span>
            <h2 class="pwe-heading animate-box" data-animate-effect="fadeInLeft">Gestion de votre compte client</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="team-section team bg-pink" style="padding-top: 10px">
  <div class="container-fluid">
    <form [formGroup]="form" (ngSubmit)="onClickSubmitUpdate()" class="row">

      <div class="col-md-12 animate-box" data-animate-effect="fadeInRight">
        <p style="text-align: center">Vous pouvez modifier vos informations personnelles <strong>Zalôna</strong>
          <p style="text-align: center">Pour rappel, vos données personnelles sont gérées par notre partenaire
            <strong>RDV360</strong> (Aucune donnée personnelle n'est stockée sur le site Zalona)</p>
          <p style="text-align: center"><a href="https://www.rdv360.com/" target="_blank"><img
                src="../../assets/images/rdv360.png" style="width: 300px" /></a></p>

      </div>



      <div class="col-md-12 animate-box" data-animate-effect="fadeInLeft">

        <div class="form-group" style="width: 120px">
          <select name="civility" id="civility" formControlName="civility" class="form-control selectStyle">
            <option [ngValue]="null" disabled>Civilité</option>
            <option [ngValue]="'Mme'" selected>Mme</option>
            <option [ngValue]="'Mlle'">Mlle</option>
            <option [ngValue]="'M'">Mr</option>
          </select>
        </div>
      </div>
      <div class="col-md-6 animate-box" data-animate-effect="fadeInLeft">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Votre nom *" formControlName="lastname" required
            [ngClass]="{ 'is-invalid': submitted && f['lastname'].errors }">
          <div *ngIf="submitted && f['lastname'].errors" class="invalid-feedback">
            <div *ngIf="f['lastname'].errors['required']">Votre nom est obligatoire</div>
          </div>
        </div>
      </div>
      <div class="col-md-6 animate-box" data-animate-effect="fadeInLeft">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Votre prénom *" required formControlName="firstname"
            [ngClass]="{ 'is-invalid': submitted && f['firstname'].errors }">

          <div *ngIf="submitted && f['firstname'].errors" class="invalid-feedback">
            <div *ngIf="f['firstname'].errors['required']">Votre prénom est obligatoire</div>
          </div>

        </div>
      </div>
      <div class="col-md-6 animate-box" data-animate-effect="fadeInLeft">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="votre numéro de portable *" required
            formControlName="phone" [ngClass]="{ 'is-invalid': submitted && f['phone'].errors }">
          <div *ngIf="submitted && f['phone'].errors" class="invalid-feedback">
            <div *ngIf="f['phone'].errors['required']">Votre téléphone est obligatoire</div>
          </div>
        </div>
      </div>
      <div class="col-md-6 animate-box" data-animate-effect="fadeInLeft">
        <div class="form-group">
          <input type="email" class="form-control" placeholder="votre email *" required formControlName="email"
            [ngClass]="{ 'is-invalid': submitted && f['email'].errors }">

          <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
            <div *ngIf="f['email'].errors['required']">Votre mail est obligatoire</div>
            <div *ngIf="f['email'].errors['email']">Votre mail n'est pas conforme</div>
          </div>
        </div>
      </div>

      <hr />
      <div class="col-md-12 animate-box" data-animate-effect="fadeInRight"
        style="font-size: 14px; text-align: center; margin-top: 20px">
        <u>Modifier mon mot de passe</u></div>
      <div class="col-md-6 animate-box" data-animate-effect="fadeInLeft">
        <div class="form-group">
          <input type="password" class="form-control" placeholder="votre nouveau mot de passe" required
            formControlName="password" [ngClass]="{ 'is-invalid': submitted && f['password'].errors }">

          <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
            <div *ngIf="f['password'].errors['required']">Votre mot de passe est obligatoire</div>
            <div *ngIf="f['password'].errors['minlength']">Votre mot de passe est inférieur à 6 caractères</div>
            <div *ngIf="f['password'].errors['maxlength']">Votre mot de passe est supérieur à 13 caractères</div>
          </div>

        </div>
      </div>

      <div class="col-md-6 animate-box" data-animate-effect="fadeInLeft">
        <div class="form-group">
          <input type="password" class="form-control" placeholder="confirmez votre nouveau mot de passe *"
            formControlName="confirmPassword" required
            [ngClass]="{ 'is-invalid': submitted && f['confirmPassword'].errors }">
          <div *ngIf="submitted && f['confirmPassword'].errors" class="invalid-feedback">
            <div *ngIf="f['confirmPassword'].errors['required']">Le contrôle de votre mot de passe est obligatoire
            </div>
            <div *ngIf="f['confirmPassword'].errors['matching']">Votre mot de passe ne correspond pas au contrôle
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 animate-box" data-animate-effect="fadeInRight"
        style="font-size: 12px; text-align: center; margin-bottom: 20px"></div>
      <hr />
      <!--
            <div class="col-md-6">
        <div class="form-group">
          <input type="password" class="form-control" placeholder="votre mot de passe *" required
            formControlName="password" [ngClass]="{ 'is-invalid': submitted && f['password'].errors }">

          <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
            <div *ngIf="f['password'].errors['required']">Votre mot de passe est obligatoire</div>
            <div *ngIf="f['password'].errors['minlength']">Votre mot de passe est inférieur à 6 caractères</div>
            <div *ngIf="f['password'].errors['maxlength']">Votre mot de passe est supérieur à 13 caractères</div>
          </div>

        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="confirmez votre mot de passe *"
            formControlName="confirmPassword" required
            [ngClass]="{ 'is-invalid': submitted && f['confirmPassword'].errors }">
          <div *ngIf="submitted && f['confirmPassword'].errors" class="invalid-feedback">
            <div *ngIf="f['confirmPassword'].errors['required']">Le contrôle de votre mot de passe est obligatoire
            </div>
            <div *ngIf="f['confirmPassword'].errors['matching']">Votre mot de passe ne correspond pas au contrôle
            </div>
          </div>
        </div>
      </div>
      -->

      <div class="col-md-12 animate-box" data-animate-effect="fadeInLeft">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Votre adresse postal" formControlName="address">
        </div>
      </div>
      <div class="col-md-5 animate-box" data-animate-effect="fadeInLeft">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Votre code postal" formControlName="zipcode">
        </div>
      </div>
      <div class="col-md-7 animate-box" data-animate-effect="fadeInLeft">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Votre ville" formControlName="city">
        </div>
      </div>


      <div class="col-md-3"></div>
      <div class="col-md-6 animate-box" data-animate-effect="fadeInLeft">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Votre date de naissance" formControlName="birthday"
            mask="00/00/0000">
        </div>
      </div>
      <div class="col-md-3"></div>

      <div class="col-md-12 checkboxStyle animate-box" data-animate-effect="fadeInLeft"><input type="checkbox" id="cgu"
          formControlName="acceptTerms">

        <label for="cgu">J'ai lu et j'accepte
          les
          conditions
          générales d'utilisation.</label> <span (click)="openPopup()"
          style="cursor: pointer; font-size: 12px"><strong>(cliquer
            ici pour voir les CGU)</strong></span>
      </div>
      <div class="col-md-12">
        <div class="invalid-feedback" *ngIf="submitted && f['acceptTerms'].errors">
          Vous devez accepter les conditions générales
          d'utilisation
        </div>
      </div>

      <div class="col-md-12 checkboxStyle animate-box" data-animate-effect="fadeInLeft"><input type="checkbox"
          id="marketing" formControlName="marketing">

        <label for="marketing">J’autorise
          l’établissement à m’envoyer des messages marketing
          conformément à la politique
          de confidentialité.</label>


      </div>
      <div class="col-md-12 animate-box" data-animate-effect="fadeInLeft">
        <div class="form-group" style="text-align: center; ">
          <input type="submit" class="btn btn-contact" style="border-radius: 3px;
          box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.3);" value="Mettre à jour vos données" *ngIf="!isLoading">
        </div>
      </div>


    </form>

  </div>

</div>
