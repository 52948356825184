import {
  trigger,
  transition,
  style,
  query,
  group,
  animateChild,
  animate,
  state,
  keyframes,
} from '@angular/animations';

export const slideInAnimation2 = trigger('routerTransition', [
  transition('* <=> *', [
    query(':enter, :leave', style({ position: 'fixed', width: '100%' }), {
      optional: true,
    }),
    group([
      query(
        ':enter',
        [
          style({ transform: 'translateX(100%)' }),
          animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' })),
        ],
        {
          optional: true,
        }
      ),
      query(
        ':leave',
        [
          style({ transform: 'translateX(0%)' }),
          animate(
            '0.5s ease-in-out',
            style({ transform: 'translateX(-100%)' })
          ),
        ],
        {
          optional: true,
        }
      ),
    ]),
  ]),
]);

export const AnimationLogin = trigger('openClose', [
  transition(':enter', [
    style({ transform: 'translateY(100%)', opacity: 0 }),
    animate(
      '0.3s ease-in-out',
      style({ transform: 'translateY(0%)', opacity: 1 })
    ),
  ]),
  transition(':leave', [
    animate(
      '0.3s ease-in-out',
      style({ transform: 'translateY(100%)', opacity: 0 })
    ),
  ]),
]);

export const AnimationOverlay = trigger('openClose', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('0.3s ease-in-out', style({ opacity: 1 })),
  ]),
  transition(':leave', [animate('0.3s ease-in-out', style({ opacity: 0 }))]),
]);

export const AnimationCalendar = trigger('apear', [
  transition(':enter', [
    style({ transform: 'translateY(100%)', opacity: 0 }),
    animate(
      '0.3s ease-in-out',
      style({ transform: 'translateY(0%)', opacity: 1 })
    ),
  ]),
  transition(':leave', [
    animate(
      '0.3s ease-in-out',
      style({ transform: 'translateY(100%)', opacity: 0 })
    ),
  ]),
]);
export const AnimationBasket = trigger('customAnimation', [
  transition(
    ':leave',
    [
      animate(
        '500ms ease-in',
        keyframes([style({ top: '{{top}}px', right: '{{right}}px' })])
      ),
    ],
    { params: { top: '0', right: '0' } }
  ),
]);
