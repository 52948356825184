<div class="banner-container">
  <div class="banner-img"> <img class="banner-img-width" src="../../assets/images/banner-prestation06.jpg"
      alt="Nos mentions légales">
  </div>
  <div class="banner-head">
    <div class="banner-head-padding banner-head-margin">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12"> <span class="heading-meta">Zalona</span>
            <h2 class="pwe-heading animate-box" data-animate-effect="fadeInLeft">Mentions légales et protection des
              données</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="team-section team bg-pink" style="padding-top: 10px">
  <div class="container-fluid">
    <h6>1- Objet et acceptation des conditions générales</h6>
    <p>
      Le site Zalôna (ci-après dénommé le « Site ») est un site de réservation et de commerce électronique accessible
      par le réseau Internet à l'adresse zalona.fr en passant par le logiciel RDV360. Il est ouvert à tout utilisateur
      de ce réseau (ci-après dénommé « Utilisateur »).
    </p>
    <p>
      Il est édité par :<br />
      Zalona SAS (ci-après dénommée « Zalona »)<br />
      Société par Actions Simplifiée | Capital social : 7 500 € | RCS Versailles 839 594 496<br />
      Siège social : 39 sente des Châtelets, 78510 Triel sur Seine – FRANCE<br />
      Email : Formulaire de contact sur la page principale ou contact@zalona.fr<br />
      N° TVA : FR 20839594496<br />
      Directeur de Publication : Lydie Senaud, Présidente de Zalona SAS.
    </p>

    <h6>2- Hébergeur</h6>

    <p>
      Le site zalona.fr est hébergé par la société OVH dont le siège social est :<br />

      SA par action simplifiée à associé unique<br />

      Adresse : 2 rue Kellermann<br />

      SIREN : 424 761 419<br /><br />

      Le Site Zalona permet à l'institut Zalôna de proposer des réservations de prestations mais également à la vente,
      des produits cosmétiques, des cures / abonnements ou cartes cadeaux à des Internautes naviguant sur le Site
      (ci-après dénommés « Utilisateurs ») par le biais de la solution RDV 360. Pour l'application des présentes, il est
      convenu que l'Utilisateur,l'institut Zalôna/RDV360 seront collectivement dénommés les « Parties » et
      individuellement dénommés « Partie », et
      que l'Utilisateur ayant validé une commande sera alors dénommé « Acheteur ». Les droits et obligations de
      l'Utilisateur s'appliquent nécessairement à l'Acheteur.<br /><br />

      Toute commande de Produit/carte cadeau/abonnements/cures proposé sur le Site suppose la consultation et
      l'acceptation expresse des présentes
      conditions générales de vente, sans toutefois que cette acceptation soit conditionnée par une signature manuscrite
      de la part de l'Utilisateur.<br />

      Il est précisé que l'Utilisateur peut sauvegarder ou imprimer les présentes conditions générales de vente sur le
      site RDV360 lors de l'inscription au site, à
      condition toutefois de ne pas les modifier.<br />

      RDV360 se réservent le droit de modifier les présentes Conditions Générales et spécifiques de
      vente à tout moment, sans préavis, étant entendu que de telles modifications seront inapplicables aux réservations
      et aux commandes préalablement acceptées et confirmées par l’Acheteur. Zalona fera le nécessaire pour communiquer
      les changement des présentes conditions Générales de ventes auprès de l'Utilisateur.<br />

      Les présentes conditions s'appliquent à l'exclusion de tout autre document.
    </p>


    <h6>3 - Propriété intellectuelle</h6>

    <p>
      En accord avec les lois régissant la propriété des droits littéraires et artistiques ou autres droits similaires,
      le présent Site et tous les éléments, marques, dessins, modèles, photographies, textes, illustrations, logos,
      séquences animées ou non, sonores ou non, graphiques etc. se trouvant dans le présent site ainsi que leur
      compilation sont la propriété exclusive de Zalona celle-ci ne concédant aucune licence ni aucun autre droit que
      celui de consulter le Site.<br /><br />

      La reproduction, ou l’utilisation de tout ou partie de ces éléments est seulement autorisée aux fins exclusives
      d’information pour un usage personnel et privé, toute reproduction et toute utilisation de copies réalisées à
      d’autres fins étant expressément interdite. Ni le Site internet (en tout ou partie), ni son contenu, ni les
      marques ne peuvent être utilisés, reproduits, dupliqués, copiés, vendus, revendus, rendus accessibles, modifiés ou
      exploités de toute autre manière, en tout ou partie, pour quelque finalité que ce soit sauf autorisation préalable
      et écrite de Yon-Ka.

      Toute autre utilisation est constitutive de contrefaçon.
    </p>

    <h6>4 - Responsabilités</h6>
    <p>
      Zalona fera le meilleur effort pour assurer la fiabilité, l’exactitude et la mise à jour des informations fournies
      sur ce site, néanmoins, Zalona : ne garantit en aucune façon la précision ou l’exhaustivité des informations mises
      à disposition sur ce site ; et plus généralement pour tous dommages, directs ou indirects, qu’elles qu’en soient
      les causes, origines, natures ou conséquences, provoqués à raison de l’accès de quiconque au site ou de
      l’impossibilité d’y accéder, de même que l’utilisation du site et/ou du crédit accordé à une quelconque
      information provenant directement ou indirectement de ce dernier.<br /><br />

      Zalona se réserve le droit de modifier ou de corriger le contenu des documents publiés sur son site à tout moment
      et sans préavis.
    </p>

    <h6>5 - Liens</h6>
    <p>
      La création de lien hypertexte vers le site zalona.fr ne peut être faite qu’avec l’autorisation expresse, écrite
      et préalable de Zalona. Zalona décline toute responsabilité concernant le contenu, la publicité, les produits ou
      les services disponibles sur ou à partir des sites liés par un lien hypertexte à son site.
    </p>

    <h6>6 - Cookies</h6>

    <p>
      Les « cookies » sont des identifiants alphanumériques déposés sur votre ordinateur, votre téléphone mobile ou
      votre tablette numérique, par l'intermédiaire de votre navigateur Internet, afin de reconnaître votre navigateur
      et vous proposer des services personnalisés.<br /><br />
      Nous utilisons des cookies pour les besoins de la navigation sur notre site internet, afin de mémoriser votre
      accès via votre compte client, de mémoriser vos préférences, de vous donner une meilleure expérience client et
      optimiser notre site internet en fournissant un contenu adapté à vos besoins.<br />
    </p>
    <p>Nous utilisons les cookies afin de :</p>
    <ul>
      <li>Vous identifier lorsque vous vous connectez à notre site internet.</li>
      <li>Mémoriser votre panier d'achat et les options d'achat.</li>
      <li>Connaître les produits que vous consultez et achetez, afin de vous fournir des suggestions adaptées. Nous ne
        recueillons pas de données personnelles mais uniquement des données statistiques afin d’optimiser notre site
        internet.</li>
    </ul>



    <h6>7 - Données personnelles</h6>
    <p>
      Yonka s’engage à préserver la confidentialité des informations personnelles éventuellement fournies en ligne par
      l’internaute. Cependant, aucune information en dehors des cookies n'est stockées sur le serveur d'hébergement de
      Zalona. Toutes les informations de données personnelles sont stockées par notre partenaire Rdv360 via u protocole
      d'API Rest defini par Rdv360. Pour plus d'informations rendez-vous sur le site de Rdv360.com.
    </p>

  </div>
</div>
