import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  LOCALE_ID,
  APP_INITIALIZER,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CookieService } from 'ngx-cookie-service';
import { IsConnectedService } from '@services/is-connected.service';
import { MaterialModule } from '@shared/modules/material/material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// import { ZalonaStripeComponent } from '@components/zalona-stripe/zalona-stripe.component';
import { SearchFilterPipe } from '@pipes/search-filter.pipe';
import { ScriptService } from '@services/extern-script.service';
import { DataService } from '@services/data.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';

import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { HotToastModule } from '@ngneat/hot-toast';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { MomentPipe } from '@pipes/moment.pipe';

import { ZalonaHomeComponent } from '@components/zalona-home/zalona-home.component';
import { ZalonaMenuComponent } from '@components/zalona-menu/zalona-menu.component';
import { ZalonaUserComponent } from '@components/zalona-user/zalona-user.component';
import { ListMenuComponent } from '@components/zalona-user/list-menu/list-menu.component';
import { BasketBookingComponent } from '@shared/components/basket-booking/basket-booking.component';
import { UnsecuredComponent } from '@components/unsecured/unsecured.component';
import { CustomHeader } from '@shared/components/dispatch-action/booking/custom-header.component';
import { DispatchBookingComponent } from '@shared/components/dispatch-action/booking/booking.component';
import { BasketBuyingComponent } from '@shared/components/basket-buying/basket-buying.component';
import { CartComponent } from './shared/components/dispatch-action/cart/cart.component';
import { OfferComponent } from './shared/components/dispatch-action/offer/offer.component';
import { TempBookingComponent } from './shared/components/temp-booking/temp-booking.component';
import { ZalonaCheckoutComponent } from '@components/zalona-checkout/zalona-checkout.component';
import { ConfirmationComponent } from '@components/zalona-user/historique/confirmation/confirmation.component';
import { ZalonaCguComponent } from '@components/zalona-cgu/zalona-cgu.component';
import { ZalonaFooterComponent } from '@components/zalona-footer/zalona-footer.component';
//
// import { ZalonaPrestationsComponent } from '@components/zalona-prestations/zalona-prestations.component';
import { ZalonaCuresComponent } from '@components/zalona-cures/zalona-cures.component';
import { ZalonaProductsComponent } from '@components/zalona-products/zalona-products.component';
import { ZalonaInstitutComponent } from '@components/zalona-institut/zalona-institut.component';
import { ProductComponent } from '@components/zalona-products/product/product.component';
import { DetailsProductComponent } from '@components/zalona-products/product/details-product/details-product.component';
import { ServicesDetailsComponent } from '@components/zalona-prestations/services-details/services-details.component';
import { ServicesWithoutDetailsComponent } from '@components/zalona-prestations/services-without-details/services-without-details.component';
import { DetailsPrestationComponent } from '@components/zalona-prestations/services-details/details-prestation/details-prestation.component';
import { ServicesComponent } from '@components/zalona-cures/services/services.component';
import { CuresComponent } from '@components/zalona-user/cures/cures.component';
import { DetailsCureComponent } from '@components/zalona-user/cures/details-cure/details-cure.component';
import { CompteComponent } from '@components/zalona-user/compte/compte.component';
import { ZalonaInscriptionComponent } from '@components/zalona-inscription/zalona-inscription.component';
import { AchatsComponent } from '@components/zalona-user/achats/achats.component';
import { DetailsAchatComponent } from '@components/zalona-user/achats/details-achat/details-achat.component';
import { HistoriqueComponent } from '@components/zalona-user/historique/historique.component';
import { ZalonaPrestationsComponent } from '@components/zalona-prestations/zalona-prestations.component';
import { ZalonaBlankComponent } from './components/zalona-blank/zalona-blank.component';
import { ConnexionComponent } from './components/zalona-user/connexion/connexion.component';
import { ZalonaContactComponent } from './components/zalona-contact/zalona-contact.component';
import { ZalonaOnglerieComponent } from './components/zalona-onglerie/zalona-onglerie.component';
import { ZalonaLpgComponent } from './components/zalona-lpg/zalona-lpg.component';
import { ZalonaSportComponent } from './components/zalona-sport/zalona-sport.component';
import { DetailSliderComponent } from './components/zalona-home/detail-slider/detail-slider.component';
import { ZalonaOfferComponent } from './components/zalona-offer/zalona-offer.component';
import { ZalonaOffresComponent } from './components/zalona-offres/zalona-offres.component';

registerLocaleData(localeFr);

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

const initializeApp = (): Promise<any> => {
  return new Promise((resolve: any, reject) => {
    resolve();
  });
};

@NgModule({
  declarations: [
    AppComponent,
    ZalonaHomeComponent,
    ZalonaMenuComponent,
    ZalonaUserComponent,
    SearchFilterPipe,
    ListMenuComponent,
    UnsecuredComponent,
    DispatchBookingComponent,
    CustomHeader,
    MomentPipe,
    BasketBookingComponent,
    BasketBuyingComponent,
    CartComponent,
    OfferComponent,
    TempBookingComponent,
    ZalonaCheckoutComponent,
    ConfirmationComponent,
    CuresComponent,
    DetailsCureComponent,
    ServicesComponent,
    DetailsPrestationComponent,
    ServicesWithoutDetailsComponent,
    ServicesDetailsComponent,
    DetailsProductComponent,
    ProductComponent,
    ZalonaInstitutComponent,
    ZalonaProductsComponent,
    ZalonaCuresComponent,
    ZalonaPrestationsComponent,
    ZalonaCguComponent,
    ZalonaFooterComponent,
    CompteComponent,
    ZalonaInscriptionComponent,
    HistoriqueComponent,
    AchatsComponent,
    DetailsAchatComponent,
    ZalonaBlankComponent,
    ConnexionComponent,
    ZalonaContactComponent,
    ZalonaOnglerieComponent,
    ZalonaLpgComponent,
    ZalonaSportComponent,
    DetailSliderComponent,
    ZalonaOfferComponent,
    ZalonaOffresComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxMaskModule.forRoot(),
    MatNativeDateModule,
    MatDialogModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatTableModule,
    MatIconModule,
    HotToastModule.forRoot(),
    GoogleMapsModule,
    // NgxMasonryModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: () => initializeApp,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    CookieService,
    IsConnectedService,
    ScriptService,
    DataService,
    { provide: MAT_DATE_LOCALE, useValue: 'fr' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
