import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-detail-slider',
  templateUrl: './detail-slider.component.html',
  styleUrls: ['./detail-slider.component.scss'],
})
export class DetailSliderComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public valuesInject: any) {}

  ngOnInit(): void {}
}
