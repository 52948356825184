<p mat-dialog-title
  style="font-family: 'Nunito Sans', sans-serif !important; text-transform:uppercase; text-align: center; font-size: 14px; color: #a2783a"
  class="bold">
  Détail de votre cure
</p>

<div mat-dialog-content>

  <div class="item animate-box" data-animate-effect="fadeInUp" style="text-align: center; width: 100%">
    <div class="row"
      style="margin-left: 5px; margin-right: 5px; margin-bottom:10px; border: 1px solid rgba(0,0,0,0.1); border-radius: 3px"
      *ngFor="let details of valuesInject.content">
      <div class="col-md-6" style="display: flex; align-items: center;
    justify-content:start;"><strong>{{details.product.name}}</strong></div>
      <div class="col-md-3"><i style="font-size: 9px">Qté(s)
          initiales</i><br /><strong>{{details.quantity}}</strong></div>
      <div class="col-md-3"><i style="font-size: 9px">Qté(s)
          restantes</i><br /><strong>{{details.remaining_quantity}}</strong>
      </div>
    </div>
  </div>
</div>
