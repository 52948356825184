<div class="banner-container">
  <div class="banner-img"> <img class="banner-img-width" src="../../assets/images/banner-prestation06.jpg" alt="">
  </div>
  <div class="banner-head">
    <div class="banner-head-padding banner-head-margin">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12"> <span class="heading-meta">Zalona</span>
            <h2 class="pwe-heading animate-box" data-animate-effect="fadeInLeft" style="color: red">
              Accès refusé
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="about-section pt-0 pb-0">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 animate-box" data-animate-effect="fadeInLeft">
        <h3 class="pwe-about-heading"></h3>
        <h4 class="pwe-about-subheading">Pour accéder à ce contenu vous devez être connecté avec votre compte
          utilisateur</h4>
        <p style="font-size: 18px">
          Si vous êtes connecté et que vous arrivez sur cette page contactez nous sur <strong><u><a
                href="mailto: contact@zalona.fr">contact@zalona.fr</a></u></strong>.
        </p>
      </div>

    </div>
  </div>
</div>
