import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import IPrestation from '@models/prestation.model';

interface IValuesInject {
  element: IPrestation;
  type: 'CURE';
}
@Component({
  selector: 'app-temp-booking',
  templateUrl: './temp-booking.component.html',
  styleUrls: ['./temp-booking.component.scss'],
})
export class TempBookingComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public valuesInject: IValuesInject) {}

  ngOnInit(): void {}
}
