import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  Renderer2,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { MatCalendar } from '@angular/material/datepicker';

import { StorePrestationsPendingService } from '@services/store-prestations-pending.service';
import { StoreCartService } from '@services/store-cart.service';
import { HotToastService } from '@ngneat/hot-toast';
// import { environment } from '@env/environment';
import * as moment from 'moment';
import { CustomHeader } from './custom-header.component';
import { AnimationCalendar, AnimationOverlay } from '@animationsAngular';
import IPrestation from '@models/prestation.model';
import AnimationsMethod from '@animations';

declare let $: any;
interface IValuesInject {
  prestation: IPrestation;
  type: 'BOOKING' | 'READ';
}

@Component({
  selector: 'app-dispatch-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss'],
  animations: [AnimationCalendar, AnimationOverlay],
})
export class DispatchBookingComponent implements OnInit {
  @ViewChild('calendar') calendar: MatCalendar<moment.Moment>;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  isLoading: boolean = false;
  nameLocalSpinner: string = 'localLoadingBooking';
  isAnimated: boolean = true;
  public animationsMain: any = AnimationsMethod;
  public isCalendarShow: boolean = false;
  public customHeader = CustomHeader;
  public selectedDate: any = null;
  public minDate: moment.Moment = moment();
  public startAt: moment.Moment = moment();
  public dateFilter = (date: Date): boolean => {
    return false;
  };
  public enableDays: number[] = [];
  public listOfAvailabilities: string[];
  public prestationsSelected: IPrestation[] = [];
  public isSelectTime: string = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public valuesInject: IValuesInject,
    public dialogRef: MatDialogRef<DispatchBookingComponent>,
    private httpClient: HttpClient,
    private spinner: NgxSpinnerService,
    private storeManagement: StorePrestationsPendingService,
    private cd: ChangeDetectorRef,
    private toast: HotToastService,
    private storeCart: StoreCartService,
    private renderer: Renderer2
  ) {
    this.spinner.show();
    this.httpClient.get('api/v1/software/account/openhours').subscribe({
      next: (res: any) => {
        const array = res
          .map((e) => {
            return e.days.map((f) => parseInt(f));
          })
          .flat();

        this.dateFilter = (date: Date): boolean => {
          return array.includes(moment(date).day());
        };
        this.spinner.hide();
      },
      error: (err) => {},
      complete: () => {},
    });
  }

  ngOnInit(): void {
    this.storeManagement.changes.subscribe((e) => {
      this.prestationsSelected = e;
    });

    if (this.valuesInject.type === 'BOOKING') {
      this.storeManagement.store(this.valuesInject.prestation);
    }
    if (this.valuesInject.type === 'READ') {
      this.storeManagement.call();
    }

    setTimeout(() => {
      this.animationsMain();
      setTimeout(() => {
        this.isAnimated = false;
      }, 1000);
    }, 50);
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }
  selectedChange(event): void {
    this.isSelectTime = null;
    this.getDispo(event.format('YYYY-MM-DD'));
    this.closeCalendar();
  }

  getDispo(date: string) {
    this.isLoading = true;
    this.spinner.show(this.nameLocalSpinner);
    this.httpClient
      .post('api/v1/software/booking/disponibilities', {
        ids: this.prestationsSelected.map((e) => e.id),
        date: date,
        tranche: 1,
      })
      .subscribe({
        next: (res: any) => {
          if (res.result === 'OK') {
            this.listOfAvailabilities = res.disponibilities[0].hours;
          } else {
            this.toast.error(res.msg, {
              position: 'bottom-center',
            });
          }
        },
        error: (err) => {},
        complete: () => {
          this.isLoading = false;
          this.spinner.hide(this.nameLocalSpinner);
        },
      });
  }

  removePrestation(event: MouseEvent, index) {
    const el = this.renderer.parentNode(this.renderer.parentNode(event.target));
    $(el).fadeOut(400, () => {
      this.storeManagement.remove(index);
      if (this.selectedDate !== null && this.prestationsSelected.length > 0) {
        this.getDispo(this.selectedDate.format('YYYY-MM-DD'));
      }
      setTimeout(() => {
        this.animationsMain();
      }, 50);
    });
  }

  closeCalendar(event?: any) {
    if (!event || event.target.id === 'overlayCalendar') {
      this.isCalendarShow = false;
    }
  }

  selectTime(hour: string) {
    if (hour === this.isSelectTime) {
      this.isSelectTime = null;
    } else {
      this.isSelectTime = hour;
      this.scrollToBottom();
    }
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop =
        this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }

  addShopping(event) {
    this.spinner.show(this.nameLocalSpinner);
    this.httpClient
      .post('api/v1/software/booking/blocage', {
        ids: this.storeManagement.getStorage().map((e) => e.id),
        datetime:
          this.selectedDate.format('YYYY-MM-DD') +
          ' ' +
          this.isSelectTime +
          ':00',
      })
      .subscribe({
        next: (res: any) => {
          if (res.result === 'OK') {
            const cart = $('.containerBaskBuying');
            const chipElement = this.renderer.createElement('div');
            this.renderer.addClass(chipElement, 'chipClass');
            this.renderer.setStyle(
              chipElement,
              'left',
              Math.round(event.pageX) + 'px'
            );
            this.renderer.setStyle(
              chipElement,
              'top',
              Math.round(event.pageY) + 'px'
            );
            this.renderer.setStyle(chipElement, 'color', 'white');
            this.renderer.setStyle(chipElement, 'font-size', '11px');
            this.renderer.setStyle(chipElement, 'font-weight', 'bold');
            this.renderer.setStyle(chipElement, 'display', 'flex');
            this.renderer.setStyle(chipElement, 'align-items', 'center');
            this.renderer.setStyle(chipElement, 'justify-content', 'center');
            this.renderer.setStyle(
              chipElement,
              'box-shadow',
              '0px 0px 11px 0px rgba(0,0,0,0.75)'
            );
            this.renderer.appendChild(
              chipElement,
              this.renderer.createText('+1')
            );
            this.renderer.appendChild(document.body, chipElement);

            $(chipElement).animate(
              {
                top: cart.offset().top - 15,
                left: cart.offset().left + 40,
              },
              1000,
              'easeInOutExpo'
            );

            setTimeout(() => {
              cart.effect(
                'shake',
                {
                  times: 4,
                },
                300
              );
            }, 600);

            setTimeout(() => {
              $(chipElement).animate(
                {
                  width: 0,
                  height: 0,
                  opacity: 0,
                },
                function () {
                  $(this).detach();
                }
              );

              this.storeCart.store({
                type: 'service',
                values: {
                  expire: res.expire,
                  token: res.token,
                  datetime:
                    this.selectedDate.format('YYYY-MM-DD') +
                    ' ' +
                    this.isSelectTime +
                    ':00',
                  details: this.storeManagement.getCurrentPrestations(),
                },
              });

              this.storeManagement.clear();
              this.dialogRef.close();
            }, 800);
          } else {
            this.toast.error(res.msg, {
              position: 'bottom-center',
            });
          }
        },
        error: (err) => {},
        complete: () => {
          this.isLoading = false;
          this.spinner.hide(this.nameLocalSpinner);
        },
      });
  }
}
