import { Component, OnInit } from '@angular/core';
import AnimationsMethod from '@animations';

@Component({
  selector: 'app-zalona-offer',
  templateUrl: './zalona-offer.component.html',
  styleUrls: ['./zalona-offer.component.scss'],
})
export class ZalonaOfferComponent implements OnInit {
  public animationsMain: any = AnimationsMethod;
  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.animationsMain();
    }, 500);
  }
}
