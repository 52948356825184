import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StoreCartService } from '@services/store-cart.service';
import { CartComponent } from '@shared/components/dispatch-action/cart/cart.component';
import ICart from '@models/cart.model';

@Component({
  selector: 'app-basket-buying',
  templateUrl: './basket-buying.component.html',
  styleUrls: ['./basket-buying.component.scss'],
})
export class BasketBuyingComponent implements OnInit {
  public count: number = 0;
  constructor(
    private storeManagement: StoreCartService,
    public dialog: MatDialog
  ) {
    this.storeManagement.changes.subscribe((e: ICart) => {
      if (e !== null) {
        setTimeout(() => {
          this.count =
            e?.services?.length +
            e?.gifts?.length +
            e?.produits?.length +
            e?.packages?.length;
        }, 10);
      }
    });
    this.storeManagement.call();
  }

  ngOnInit(): void {}

  openModal() {
    this.dialog.open(CartComponent, {
      data: {},
      panelClass: 'zalona-modalbox',
      width: '600px',
    });
  }
}
