import { Injectable } from '@angular/core';

declare let document: any;

interface Scripts {
  name: string;
  src: string;
}
const ScriptStore: Scripts[] = [
  {
    name: 'stripe',
    src: 'https://js.stripe.com/v3/',
  },
  {
    name: 'planity_core',
    src: 'https://d2skjte8udjqxw.cloudfront.net/widget/production/polyfills.latest.js',
  },
  {
    name: 'planity_app',
    src: 'https://d2skjte8udjqxw.cloudfront.net/widget/production/app.latest.js',
  },
];

@Injectable()
export class ScriptService {
  private scripts: any = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
      };
    });
  }

  load(...scripts: string[]) {
    var promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  remove(...scripts: string[]) {
    var promises: any[] = [];
    scripts.forEach((script) => promises.push(this.removeScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      //resolve if already loaded
      if (this.scripts[name].loaded) {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      } else {
        //load script
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        script.id = name + 'SCRIPTAUTO';
        if (script.readyState) {
          //IE
          script.onreadystatechange = () => {
            if (
              script.readyState === 'loaded' ||
              script.readyState === 'complete'
            ) {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: 'Loaded' });
            }
          };
        } else {
          //Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: 'Loaded' });
          };
        }
        script.onerror = (error: any) =>
          resolve({ script: name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }

  removeScript(name: string) {
    return new Promise((resolve, reject) => {
      //resolve if already loaded
      if (!this.scripts[name].loaded) {
        resolve({ script: name, loaded: false, status: 'Already removed' });
      } else {
        const script = document.getElementById(name + 'SCRIPTAUTO');
        script.remove();

        this.scripts[name].loaded = false;
        resolve({ script: name, loaded: false, status: 'unLoaded' });
      }
    });
  }
}

// import { Renderer2, Inject, Injectable } from '@angular/core';
// import { DOCUMENT } from '@angular/common';

// @Injectable()
// export class ExternScriptService {
//   constructor(@Inject(DOCUMENT) private document: Document) {}

//   public loadJsScript(renderer: Renderer2, src: string): HTMLScriptElement {
//     const script = renderer.createElement('script');
//     script.type = 'text/javascript';
//     script.src = src;
//     renderer.appendChild(this.document.body, script);
//     return script;
//   }
// }
