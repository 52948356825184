<div *ngIf="displayType === 'BASKET'">
  <p mat-dialog-title
    style="font-family: 'Nunito Sans', sans-serif !important; text-transform:uppercase; text-align: center; font-size: 18px"
    class="bold">
    <mat-icon>shopping_cart</mat-icon> Contenu de mon panier
  </p>
  <div mat-dialog-content>
    <div class="row"
      style="margin-bottom: 10px; width: 100%; margin-left: 0px; margin-right: 0px; margin-left: 0px; margin-right: 0px"
      *ngIf="listOfCart !== null && listOfCart.services !== null && listOfCart.services.length > 0">

      <div class="groupItem" *ngFor="let services of listOfCart.services; let line = index"
        [class.disabledService]="currentTime > services.expire">

        <div class="datetimeContainer">Réservation: {{services.datetime | date:'mediumDate'}} à
          {{services.datetime | date:'HH:mm'}}</div>

        <mat-icon aria-hidden="false" class="closedPosition" (click)="removePrestation($event,'service',line)">
          delete</mat-icon>
        <div class="item animate-box" [class.animate-box]="isAnimated" data-animate-effect="fadeInUp"
          *ngFor="let service of services.details; let i = index">
          <div class="row" style="width: 100%">
            <div class="col-sm-8"><strong>{{service.name}}</strong></div>
            <div class="col-sm-2" *ngIf="service.online_duration === '0'">{{service.duration}} min</div>
            <div class="col-sm-2" *ngIf="service.online_duration !== '0'">{{service.online_duration}} min</div>
            <div class="col-sm-2">{{service.sell_price | currency:'EUR':'symbol':'1.2-2':'fr'}}

            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="row"
      style="margin-bottom: 10px; width: 100%; margin-left: 0px; margin-right: 0px; margin-left: 0px; margin-right: 0px"
      *ngIf="listOfCart !== null && listOfCart.gifts !== null && listOfCart.gifts.length > 0">

      <div class="groupItem" *ngFor="let gift of listOfCart.gifts; let line = index">
        <div class="datetimeContainer">Cartes Cadeau</div>
        <mat-icon aria-hidden="false" class="closedPosition" (click)="removePrestation($event,'gift',line)">
          delete</mat-icon>
        <div class="item animate-box" [class.animate-box]="isAnimated" data-animate-effect="fadeInUp">
          <div class="row" style="width: 100%">
            <div class="col-sm-10">
              <strong>Carte cadeau pour {{gift.firstname}} {{gift.lastname}}</strong>
              <p class="detailCarteCadeau" *ngIf="gift.type !== 'AMOUNT'">
                {{gift.service.name}}
              </p>
              <p class="detailCarteCadeau" *ngIf="gift.type === 'AMOUNT'">
                Montant offert
              </p>
            </div>
            <div *ngIf="gift.type === 'AMOUNT'" class="col-sm-2 zalona-flex-center">
              {{gift.amount| currency:'EUR':'symbol':'1.2-2':'fr'}}
            </div>
            <div *ngIf="gift.type !== 'AMOUNT'" class="col-sm-2 zalona-flex-center">
              {{gift.service.sell_price | currency:'EUR':'symbol':'1.2-2':'fr'}}
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="row"
      style="margin-bottom: 10px; width: 100%; margin-left: 0px; margin-right: 0px; margin-left: 0px; margin-right: 0px"
      *ngIf="listOfCart !== null && listOfCart.produits !== null &&  listOfCart.produits.length > 0">

      <div class="groupItem" *ngFor="let produit of listOfCart.produits; let line = index">
        <div class="datetimeContainer">Produits</div>
        <mat-icon aria-hidden="false" class="closedPosition" (click)="removePrestation($event,'produit',line)">
          delete</mat-icon>
        <div class="item animate-box" [class.animate-box]="isAnimated" data-animate-effect="fadeInUp">
          <div class="row" style="width: 100%">
            <div class="col-sm-6">
              <strong>{{produit.name}}</strong>
            </div>
            <div class="col-sm-2 zalona-flex-center">{{produit.sell_price| currency:'EUR':'symbol':'1.2-2':'fr'}}
            </div>
            <div class="col-sm-2 zalona-flex-center">{{produit.basketQty}}
            </div>
            <div class="col-sm-2 zalona-flex-center">
              {{ calculationPrice(produit.sell_price , produit.basketQty) | currency:'EUR':'symbol':'1.2-2':'fr'}}
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="row"
      style="margin-bottom: 10px; width: 100%; margin-left: 0px; margin-right: 0px; margin-left: 0px; margin-right: 0px"
      *ngIf="listOfCart !== null && listOfCart.packages !== null && listOfCart.packages.length > 0">

      <div class="groupItem" *ngFor="let cure of listOfCart.packages; let line = index">
        <div class="datetimeContainer">Cures & Abonnements</div>
        <mat-icon aria-hidden="false" class="closedPosition" (click)="removePrestation($event,'cure',line)">
          delete</mat-icon>
        <div class="item animate-box" [class.animate-box]="isAnimated" data-animate-effect="fadeInUp">
          <div class="row" style="width: 100%">
            <div class="col-sm-6">
              <strong>{{cure.name}}</strong>
            </div>
            <div class="col-sm-4 zalona-flex-center">{{cure.month_validity}} mois de validité
            </div>
            <div class="col-sm-2 zalona-flex-center">
              {{cure.sell_price | currency:'EUR':'symbol':'1.2-2':'fr'}}
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="row"
      style="margin-bottom: 10px; width: 100%; margin-left: 0px; margin-right: 0px; margin-left: 0px; margin-right: 0px"
      *ngIf="totalPayment > 0">
      <div class="item animate-box" [class.animate-box]="isAnimated" data-animate-effect="fadeInUp" style="width: 100%">
        <div class="row noMargin" style="width: 100%">
          <div class="col-sm-4">

          </div>
          <div class="col-sm-6" style="text-align: right">Total TTC du panier
          </div>
          <div class="col-sm-2" style="text-align: right">
            <strong> {{totalPayment | currency:'EUR':'symbol':'1.2-2':'fr'}}</strong>

          </div>
        </div>
        <div class="row noMargin" style="width: 100%" *ngIf="listOfCart.services.length > 0">
          <div class="col-sm-4">

          </div>
          <div class="col-sm-6" style="text-align: right">Acompte Réservation
          </div>
          <div class="col-sm-2" style="text-align: right">
            <strong>{{acompteReservation | currency:'EUR':'symbol':'1.2-2':'fr'}}</strong>
          </div>
        </div>

        <div class="row noMargin" style="width: 100%">
          <div class="col-sm-4">

          </div>
          <div class="col-sm-6" style="text-align: right"><u>Total TTC à payer maintenant</u>
          </div>
          <div class="col-sm-2" style="text-align: right">
            <strong>{{realPayment | currency:'EUR':'symbol':'1.2-2':'fr'}}</strong>
          </div>
        </div>
      </div>
    </div>
    <div class="row"
      style="margin-bottom: 10px; width: 100%; margin-left: 0px; margin-right: 0px; margin-left: 0px; margin-right: 0px"
      *ngIf="initialLoading && listOfCart.services.length === 0 && listOfCart.gifts.length === 0 && listOfCart.produits.length === 0 && listOfCart.packages.length === 0">
      <div class="emptyBasket">
        Votre panier est vide
      </div>
    </div>
    <div mat-dialog-actions style="height: 70px; width: 100%"
      *ngIf="initialLoading && (listOfCart.services.length > 0 || listOfCart.gifts.length > 0 || listOfCart.produits.length > 0 || listOfCart.packages.length > 0)">

      <div class="row" style="height: 70px; width: 100%"
        *ngIf="listOfCart.services.length > 0 && listOfCart.gifts.length === 0 && listOfCart.produits.length === 0 && listOfCart.packages.length === 0 && isConnectedService.isConnected && detailsEcommerce.deposit_amount === '0.00'">
        <div class="col-md-12" style="width: 100%; height: 70px; display: flex;
    align-items: center;
    justify-content: center;">
          <div class="btn-contact"> <a href="" style="margin-bottom: 0px" [routerLink]="" class="btnChooseAnotherPresta"
              (click)="confirmBasket()">
              <mat-icon>calendar_today</mat-icon> <span>Je confirme prendre rendez-vous</span>
            </a> </div>
        </div>
      </div>
      <div class="row" style="height: 70px; width: 100%; margin-right: 0px; margin-left: 0px"
        *ngIf="(listOfCart.services.length > 0 || listOfCart.gifts.length > 0 || listOfCart.produits.length > 0 || listOfCart.packages.length > 0) && isConnectedService.isConnected && realPayment > 0">
        <div class="col-md-12" style="width: 100%; height: 70px; display: flex;
    align-items: center;
    justify-content: center;">
          <div class="btn-contact"> <a href="" style="margin-bottom: 0px" [routerLink]="" class="btnChooseAnotherPresta"
              (click)="confirmBasket()">

              <mat-icon>credit_card</mat-icon> <span>Payer la commande</span>

              <!--<mat-icon>credit_card</mat-icon> <span>Payer la commande de
                {{realPayment | currency:'EUR':'symbol':'1.2-2':'fr'}}</span>-->
            </a> </div>
        </div>
      </div>



      <div class="row" style="height: 70px; width: 100%; margin-right: 0px; margin-left: 0px"
        *ngIf="!isConnectedService.isConnected">
        <div class="col-md-12" style="width: 100%; height: 70px; display: flex;
    align-items: center;
    justify-content: center;" class="unConnected">
          <span>
            Veuillez vous&nbsp;<u (click)="openUserLogin()" style="cursor: pointer">connecter
              en cliquant
              ici</u>&nbsp;ou
            <u style="cursor: pointer" (click)="openInscription()">créer un
              compte</u> pour valider votre
            panier
          </span>

        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="displayType === 'STRIPE'">
  <p mat-dialog-title
    style="font-family: 'Nunito Sans', sans-serif !important; text-transform:uppercase; text-align: center; font-size: 18px"
    class="bold">
    <mat-icon>credit_card</mat-icon> Payez votre panier
  </p>


  <div mat-dialog-content>

    <form #paymentMethod="ngForm" (ngSubmit)="validatedPayment(paymentMethod.value)">

      <div class="containerStripe">

        <div id="payment-element" class="stripe"></div>

      </div>
      <div class="btn-contact">
        <button class="btnConfirmationPayment" type="submit">Valider le règlement de
          {{realPayment  | currency:'EUR':'symbol':'1.2-2':'fr'}}</button>
      </div>

      <div class="btn-contact">
        <button class="btnReturn" type="button" (click)="returnBasket()">
          <mat-icon>keyboard_return</mat-icon> retour au
          panier
        </button>
      </div>

    </form>
  </div>

</div>


<div *ngIf="displayType === 'STATUTBOOKING'">
  <p mat-dialog-title
    style="font-family: 'Nunito Sans', sans-serif !important; text-transform:uppercase; text-align: center; font-size: 18px"
    class="bold">
    <mat-icon>check_circle</mat-icon> <span *ngIf="tempModel.services.length > 1">Vos réservations ont bien été
      enregistrées</span> <span *ngIf="tempModel.services.length === 1">Votre réservation a bien été enregistrée</span>
  </p>

  <div mat-dialog-content>

    <div class="row"
      style="margin-bottom: 10px; width: 100%; margin-left: 0px; margin-right: 0px; margin-left: 0px; margin-right: 0px">
      <div class="emptyBasket">
        Félicitation, {{detailUser.user.prenom}} {{detailUser.user.nom}}<br /><br /><span
          *ngIf="tempModel.services.length > 1">vos réservations du</span> <span
          *ngIf="tempModel.services.length === 1">Votre réservation du</span>
        <ul *ngFor="let service of tempModel.services">
          <li>{{service.datetime |  date : "longDate"}} à {{service.datetime |  date : "HH:mm"}}</li>
        </ul>
        <span *ngIf="tempModel.services.length > 1">ont bien été validées</span> <span
          *ngIf="tempModel.services.length === 1">a bien été validée</span><br />
        Vous recevrez dans quelques instants un mail récapitulatif. Vous pouvez également voir l'historique de vos
        réservations sur votre compte client Zalôna.<br /><br />
        Toute l'équipe Zalôna vous remercie.
      </div>
    </div>

  </div>
</div>
