<div class="banner-container">
  <div class="banner-img"> <img class="banner-img-width" src="../../assets/images/banner-prestation02.jpg" alt="">
  </div>
  <div class="banner-head">
    <div class="banner-head-padding banner-head-margin">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12"> <span class="heading-meta">Zalona</span>
            <h2 class="pwe-heading animate-box" data-animate-effect="fadeInLeft">Mes réservations</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="team-section team bg-pink" style="padding-top: 10px">
  <div class="container-fluid">
    <p class="animate-box" data-animate-effect="fadeInRight"><u>Vos réservations à venir</u></p>
    <div class="row">
      <div class="col-md-12 owl-carousel owl-theme ">
        <div class="item animate-box" *ngFor="let booking of getType('1')" data-animate-effect="fadeInLeft">
          <div class="info">
            <h6>Réservation du {{booking.begin | date:'mediumDate'}} de {{booking.begin | date:'HH:mm'}} à
              {{booking.end | date:'HH:mm'}}</h6>
            <p>n°{{booking.id}}</p>
            <div class="social valign">
              <div class="full-width">
                <p><i>{{returnPrestationsString(booking)}}</i></p>

                <div class="btn-contact end" style="margin-bottom: 10px" *ngIf="checkCancelation(booking.begin)"> <a
                    href="" [routerLink]="" class="btnCancelReservation" (click)="cancelBooking(booking)">
                    <mat-icon>remove_circle</mat-icon> <span>Annuler cette réservation</span>
                  </a> </div>

                <div style="margin-bottom: 10px; font-size: 9px" *ngIf="!checkCancelation(booking.begin)">
                  <span style="color: red"><i>Annulation impossible dans les 48 heures avant votre
                      rendez-vous</i></span></div>

                <div class="btn-contact end" *ngIf="booking.ticket_id !== null"> <a href="" [routerLink]=""
                    class="btnTicket" (click)="getPdf(booking.ticket_id)">
                    <mat-icon>local_offer</mat-icon> <span>Détail de mon ticket de caisse</span>
                  </a> </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 owl-carousel owl-theme animate-box" data-animate-effect="fadeInLeft"
        *ngIf="getType('1').length === 0">
        Aucune réservation à venir
      </div>
    </div>
  </div>
</div>


<div class="team-section team bg-pink" style="padding-top: 10px">
  <div class="container-fluid">
    <p class="animate-box" data-animate-effect="fadeInRight"><u>Vos anciennes réservations ou réservations annulées</u>
    </p>
    <div class="row">

      <div class="col-md-12 owl-carousel owl-theme ">
        <div class="item animate-box" *ngFor="let booking of getType('2')" style="position: relative"
          data-animate-effect="fadeInLeft">

          <div class="ribbon" *ngIf="booking.status === '3'"><span>Annulée</span></div>
          <div class="ribbon2" *ngIf="booking.status === '2'"><span>Pas venue</span></div>
          <div class="ribbon3" *ngIf="booking.status === '1'"><span>Effectué</span></div>

          <div class="info">
            <h6>Réservation du {{booking.begin | date:'mediumDate'}} de {{booking.begin | date:'HH:mm'}} à
              {{booking.end | date:'HH:mm'}}</h6>
            <p>n°{{booking.id}}</p>
            <div class="social valign">
              <div class="full-width">
                <p><i>{{returnPrestationsString(booking)}}</i></p>

                <div class="btn-contact end" *ngIf="booking.ticket_id !== null"> <a href="" [routerLink]=""
                    class="btnTicket" (click)="getPdf(booking.ticket_id)">
                    <mat-icon>local_offer</mat-icon> <span>Détail de mon ticket de caisse</span>
                  </a> </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 owl-carousel owl-theme animate-box" data-animate-effect="fadeInLeft"
        *ngIf="getType('2').length === 0">
        Aucune ancienne réservation
      </div>

    </div>
  </div>
</div>
