<div *ngIf="detailsStripe.status === 'succeeded'">

  <div class="banner-container">
    <div class="banner-img"> <img class="banner-img-width" src="../../assets/images/banner-prestation05.jpg" alt="">
    </div>
    <div class="banner-head">
      <div class="banner-head-padding banner-head-margin">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12"> <span class="heading-meta">Zalona</span>
              <h2 class="pwe-heading animate-box" data-animate-effect="fadeInLeft" style="color: green">
                <mat-icon>verified_user</mat-icon> Transaction réalisée avec succès
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



  <div class="about-section pt-0 pb-0">
    <div class="container-fluid">
      <div class="row">

        <div class="col-md-6 animate-box" data-animate-effect="fadeInLeft">
          <h3 class="pwe-about-heading">Merci pour votre confiance</h3>
          <h4 class="pwe-about-subheading">Votre paiement de
            {{(detailsStripe.amount / 100) | currency:'EUR':'symbol':'1.2-2':'fr'}} a bien été validé</h4>
          <p style="font-size: 18px">
            Vous recvevrez un mail récapitulatif dans quelques instants et vous pouvez consulter votre compte sur le
            site
            pour accéder au détail de votre commande.<br /><br />Toute l'équipe de Zalôna vous remercie pour votre
            achat et vous attend avec impatience dans votre institut.<br />
          </p>
        </div>
        <div class="col-md-6 text-center"> <img src="../../assets/images/paymentvalidated.jpg"
            class="img-fluid mb-30 animate-box" data-animate-effect="fadeInLeft" alt="">
          <h4 class="pwe-about-subheading animate-box" data-animate-effect="fadeInUp">A très très vite chez Zalôna !!
          </h4>
        </div>
      </div>
    </div>
  </div>



</div>


<div
  *ngIf="detailsStripe.status === 'canceled' || detailsStripe.status === 'requires_payment_method' || detailsStripe.status === 'requires_confirmation' || detailsStripe.status === 'requires_action' || detailsStripe.status === 'processing' || detailsStripe.status === 'requires_capture'">

  <div class="banner-container">
    <div class="banner-img"> <img class="banner-img-width" src="../../assets/images/banner-prestation05.jpg" alt="">
    </div>
    <div class="banner-head">
      <div class="banner-head-padding banner-head-margin">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12"> <span class="heading-meta">Zalona</span>
              <h2 class="pwe-heading animate-box" data-animate-effect="fadeInLeft" style="color: red">
                <mat-icon>cancel</mat-icon> Votre paiement a échoué
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



  <div class="about-section pt-0 pb-0">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 animate-box" data-animate-effect="fadeInLeft">
          <h3 class="pwe-about-heading">La transaction n'a pas réussi</h3>
          <h4 class="pwe-about-subheading">Votre panier de
            {{(detailsStripe.amount / 100) | currency:'EUR':'symbol':'1.2-2':'fr'}} toujours en attente </h4>
          <p style="font-size: 18px">
            Vous pouvez recommencer l'opération en cliquant sur votre panier en haut à droite. Si votre paiement est de
            nouveau refusé
            nous vous invitons à prendre contact avec votre banque.<br />Vous pouvez également nous contacter sur
            <strong><u><a href="mailto: contact@zalona.fr">contact@zalona.fr</a></u></strong> pour toutes question
          </p>
        </div>
        <div class="col-md-6 text-center"> <img src="../../assets/images/paymentvalidated.jpg"
            class="img-fluid mb-30 animate-box" data-animate-effect="fadeInLeft" alt="">
          <h4 class="pwe-about-subheading animate-box" data-animate-effect="fadeInUp">Merci pour votre confiance !!
          </h4>
        </div>
      </div>
    </div>
  </div>



</div>
