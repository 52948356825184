<div class="banner-container">
  <div class="banner-img"> <img class="banner-img-width" src="../../assets/images/banner-prestation.jpg"
      alt="Nos produits cosmétiques"> </div>
  <div class="banner-head">
    <div class="banner-head-padding banner-head-margin">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12"> <span class="heading-meta">Zalona</span>
            <h2 class="pwe-heading animate-box" data-animate-effect="fadeInLeft">Nos produits</h2>

            <ol *ngIf="selectedParent.length > 0" class="breadcrumb">
              <li class="breadcrumb-item"><a [routerLink]="" href="null" (click)="getFamillies()"
                  style="position: relative; width: 25px">
                  <mat-icon aria-hidden="false" class="icon" style="position: absolute; top: -18px; left: 0px">home
                  </mat-icon>
                </a></li>
              <li class="breadcrumb-item" [ngClass]="{'active': list.id === selectedParent[selectedParent.length - 1]}"
                *ngFor="let list of breadcrumbFamillies()"><a [routerLink]=""
                  (click)="getFamillies(list.id)">{{list.name}}</a></li>
            </ol>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>




<div class="portfolio-section portfolio pt-0 pb-60" *ngIf="templateFamillies.length > 0">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4" *ngFor="let familly of templateFamillies">
        <div class="item animate-box" data-animate-effect="fadeInLeft">


          <div class="portfolio-img" (click)="getFamillies(familly.id)">
            <img *ngIf="familly.image" src="{{familly.image.url['250x250']}}" alt=""
              style="height: 200px; object-fit: cover;">
          </div>

          <div class="content" (click)="getFamillies(familly.id)">
            <h5>{{familly.name}} <i class="ti-arrow-right"></i></h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="price-section pb-60 price bg-pink" *ngIf="templateProducts.length > 0">
  <div class="container-fluid">

    <div class="row">

      <div class="col-md-12 zalona-flex-center">
        <div style="display: flex; justify-content: center; flex-wrap: wrap;">
          <div class="item animate-box" data-animate-effect="fadeInLeft" *ngFor="let product of templateProducts">

            <app-product [product]="product"></app-product>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>
