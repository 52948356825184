<div class="initLoading zalona-flex-center" id="initLoaderContainer">
  <div style="text-align:center; z-index: 9; position: relative;">
    <img src="../assets/images/logo_init_loader.png" alt="chargement initial de la plateforme" style="width: 300px" />
    <img src="assets/images/spa_france.png" alt="Spa de France"
      style="position: absolute; bottom: 50px; right: 30px; width: 50px; height: 50px;">
    <p>Chargement de votre application</p>
    <p style="position: relative">
      <ngx-spinner bdColor="rgba(0,0,0,0)" size="default" color="#000000" type="ball-beat" [fullScreen]="false"
        [name]="initialLoader" [zIndex]="99999"></ngx-spinner>
    </p>
  </div>
</div>



<ngx-spinner size="default" bdColor="rgba(255,255,255,0.1)" color="#000000" type="ball-beat" [fullScreen]="true"
  [zIndex]="99998">
  <!--  <p style="color: white; font-family: 'Nunito Sans', sans-serif; font-weight: 400; list-style: none;
  font-size: 12px;
  line-height: 1.5em;
  text-transform: uppercase;
  letter-spacing: 1px;"> Chargement en cours </p>-->
</ngx-spinner>


<app-basket-booking></app-basket-booking>
<app-basket-buying></app-basket-buying>



<div id="pwe-page">
  <span role="button" class="js-pwe-nav-toggle pwe-nav-toggle" id="burgerMenuAngular"><i></i></span>

  <app-zalona-menu></app-zalona-menu>
  <div id="pwe-main">
    <div [@routerTransition]="getState(o)">
      <router-outlet #o="outlet"></router-outlet>
    </div>


  </div>

</div>
